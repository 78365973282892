@include media-breakpoint-up($nav-hamburger-breakpoint) {
.p-section-bg-light.p-icontext {
	&:first-child{
		margin-left: -1em;
		margin-right: 1em;
	}
	&:nth-child(3){
		margin-left: 1em;
		margin-right: -1em;
	}
}
}