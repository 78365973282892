.p-imagetext {
    &.mb-0 {
        :last-child {
            margin-bottom: 0;
        }
    }
    .p-img-container {
        .col-pos-center {
            margin-left: auto;
            margin-right: auto;
        }
        .col-pos-right {
            margin-left: auto;
        }
        .p-img[src$="svg"] {
            width: 100%;
        }
    }
}
