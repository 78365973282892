.p-section {
    position: relative;
    border: 0 solid $border-color;
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    @for $size from 1 through 5 {
        &.pt-#{$size} {
            padding-top: ($spacer * $size) !important;
        }
        &.pb-#{$size} {
            padding-bottom: ($spacer * $size) !important;
        }
    }
    &.pl-0 {
        .container-fluid {
            padding-left: 0;
            > .row {
                margin-left: 0;
            }
        }
        .col-12.col-md-12.col-lg-12 {
            padding-left: 0;
            >.row {
                margin-left: 0;
                > .p:first-child {
                    padding-left: 0;
                }
            }
        }
    }
    &.pr-0 {
        .container-fluid {
            padding-right: 0;
            > .row {
                margin-right: 0;
            }
        }
        .col-12.col-md-12.col-lg-12 {
            padding-right: 0;
            >.row {
                margin-right: 0;
                > .p:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

// Height
.p-section-small {
    height: $section-height-small;
    max-height: 90vh;
    @include media-breakpoint-down(xs) {
        height: auto;
        min-height: $section-height-small * 2;
    }
    @media (min-width: $canvas-max-width + 1px) {
        height: ( $canvas-max-width / 100) * e-strip-unit( $section-height-small);
    }
}

.p-section-medium {
    height: $section-height-medium;
    max-height: 90vh;
    @include media-breakpoint-down(xs) {
        height: auto;
        min-height: $section-height-medium * 2;
    }
    @media (min-width: $canvas-max-width + 1px) {
        height: ( $canvas-max-width / 100) * e-strip-unit( $section-height-medium);
    }
}

.p-section-large {
    height: $section-height-large;
    max-height: 90vh;
    @include media-breakpoint-down(xs) {
        height: auto;
        min-height: $section-height-large * 2;
    }
    @media (min-width: $canvas-max-width + 1px) {
        height: ( $canvas-max-width / 100) * e-strip-unit( $section-height-large);
    }
}

.p-section-fullscreen {
    height: 100vh;
}

// Columns
@include media-breakpoint-up(sm) {
    .p-section-columns {
        > .container-fluid {
            > .row {
                > .col-12 {
                    > .row {
                        display: block;
                        margin: 0;
                        // flex-wrap: initial;
                        column-gap: 0;
                    }
                }
            }
        }
    }
    .p-section-columns-2 {
        > .container-fluid {
            > .row {
                > .col-12 {
                    > .row {
                        column-count: 2;
                    }
                }
            }
        }
    }
    .p-section-columns-3 {
        > .container-fluid {
            > .row {
                > .col-12 {
                    > .row {
                        column-count: 3;
                    }
                }
            }
        }
    }
    .p-section-columns-4 {
        > .container-fluid {
            > .row {
                > .col-12 {
                    > .row {
                        column-count: 4;
                    }
                }
            }
        }
    }
    .p-section-columns-5 {
        > .container-fluid {
            > .row {
                > .col-12 {
                    > .row {
                        column-count: 5;
                    }
                }
            }
        }
    }
}

// Colors
.p-section-bg-white {
    @include e-txt-dark;
    background-color: #fff;
}

.p-section-bg-light {
    @include e-txt-dark;
    @include e-bg-light;
}

.p-section-bg-dark {
    @include e-txt-light;
    @include e-bg-dark;
}

.p-section-bg-black {
    @include e-txt-light;
    background-color: #000;
}

.p-section-bg-brand-primary {
    @include e-section-primary;
}

.p-section-bg-brand-secondary {
    @include e-section-secondary;
}

.p-section-bg-brand-variation-one {
    @include e-section-variation-one;
}

.p-section-bg-brand-variation-two {
    @include e-section-variation-two;
}

// Text
.p-section-txt-dark {
    @include e-txt-dark;
}

.p-section-txt-light {
    @include e-txt-light;
}

//Background size and position
.p-section-bg-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.p-section-bg-contain {
    background-size: contain;
}

.p-section-bg-left-top {
    background-position: 0 0;
}

.p-section-bg-left-middle {
    background-position: 0 50%;
}

.p-section-bg-left-bottom {
    background-position: 0 100%;
}

.p-section-bg-center-top {
    background-position: 50% 0;
}

// Default value
// .p-section-bg-center-middle {
//    background-position: 50% 50%;
// }
.p-section-bg-center-bottom {
    background-position: 50% 100%;
}

.p-section-bg-right-top {
    background-position: 100% 0;
}

.p-section-bg-right-middle {
    background-position: 100% 50%;
}

.p-section-bg-right-bottom {
    background-position: 100% 100%;
}

// Spacing
@for $size from 1 through 5 {
    .p-section-bg-mt-#{$size} {
        top: 2.5 * $size * 1%;
    }
    .p-section-bg-mr-#{$size} {
        right: 2.5 * $size * 1%;
    }
    .p-section-bg-mb-#{$size} {
        bottom: 2.5 * $size * 1%;
    }
    .p-section-bg-ml-#{$size} {
        left: 2.5 * $size * 1%;
    }
}

// Borders
.p-section-border-top {
    border-top-width: $border-width;
}

.p-section-border-bottom {
    border-bottom-width: $border-width;
}

// Border colors
.p-section-border-white {
    border-color: #fff;
}

.p-section-border-black {
    border-color: #000;
}

.p-section-border-light {
    border-color: $section-dark-border;
}

.p-section-border-dark {
    border-color: $section-light-border;
}

.p-section-border-brand-primary {
    border-color: theme-color("primary");
}

.p-section-border-brand-secondary {
    border-color: theme-color("secondary");;
}

.p-section-border-brand-variation-one {
    border-color: theme-color("variation-one");;
}

.p-section-border-brand-variation-two {
    border-color: theme-color("variation-two");
}

.p-section-toggle-collapse {
    cursor: pointer;
    position: relative;
    padding-left: 1.25em;
    line-height: $headings-line-height;
    .material-icons {
        position: absolute;
        left: 0;
        top: 0.125em;
        transform: rotate(135deg);
        transition: transform $transition-collapse-speed $transition-collapse-easing;
    }
    &.collapsed {
        .material-icons {
            transform: rotate(0deg);
        }
    }
}
