// scss-lint:disable QualifyingElement
//
// Base styles
//
.btn {
    font-family: $btn-font-family;
    // text-transform: uppercase;
    letter-spacing: 0.125em;
    cursor: pointer;
    &:hover {
        transition: none;
    }
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {}

//
// Button Sizes
//
.btn {
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
}

.btn-lg {
    // font-size: 0.875em;
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height, $btn-border-radius-lg);
}

.btn-sm {
    // font-size: 0.675em;
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height, $btn-border-radius-sm);
}

//
// Block button
//
.btn-block {
    // padding-right: 0;
    // padding-left: 0;
}
