.p-section-bg-brand-primary {
	.btn-primary {
		background: $white;
		color: theme-color("primary");
		&:hover {
			background: lighten(theme-color("primary"),40%);
		}
		&:active {
			background: lighten(theme-color("primary"),50%);
		}
	}
}