.e-products-loading-overlay {
    background: rgba($loading-bg, .95);
    z-index: $zindex-fixed - 2;
    .e-loading-spinner {
        top: 20vh;
    }
}

.e-productlist-filters-range-group {
    .e-productlist-filters-clear-range {
        color: theme-color("danger");
    }
    &.is-empty {
        .e-productlist-filters-apply-range {
            opacity: $text-muted;
        }
        .e-productlist-filters-clear-range {
            pointer-events: none;
            opacity: 0;
        }
    }
}

.e-productlist-filters-group:not(:first-child) {
    border-top: $border-width solid $border-color;
}

.e-productlist-filters-group-header {
    color: $body-color;
    font-weight: $font-weight-bold;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    .e-productlist-filters-group-collapse-toggle {
        cursor: pointer;
        flex-grow: 1;
        display: flex;
        align-items: center;
        &,
        &:hover {
            text-decoration: none;
        }
        .material-icons {
            font-size: 1.5em;
            margin-left: -0.1875em;
            transition: transform $transition-collapse-speed $transition-collapse-easing;
        }
        &.collapsed {
            .material-icons {
                transform: rotate(-90deg);
            }
        }
    }
    .e-productlist-filters-group-name {
        flex-grow: 1;
    }
}

.e-productlist-filters-group-options {
    .e-nav-local {
        position: relative;
        .e-nav-local-container {
            font-size: 1em;
            .nav {
                > .nav-item {
                    border: 0;
                    > .nav-link {
                        color: $link-color;
                        font-weight: inherit;
                        padding: ($spacer * 0.375) 0;
                    }
                }
            }
        }
    }
}

.e-productlist-filters-options-collapse-peek {
    overflow: hidden;
    position: relative;
    max-height: 100vh;
    padding-bottom: $spacer * 1.5;
    transition: all $transition-collapse-speed $transition-collapse-easing;
    .e-productlist-filters-options-collapse-peek-toggle {
        text-decoration: none;
        position: absolute;
        bottom: 0;
        left: -0.25em;
        right: 0;
        background: #fff;
        transition: all $transition-collapse-speed $transition-collapse-easing;
        &::after {
            content: attr(data-open-text);
        }
        .material-icons {
            font-size: 1.5em;
            vertical-align: -26%;
            transform: rotate(180deg);
            transition: transform $transition-collapse-speed $transition-collapse-easing;
        }
    }
    &.is-peeking {
        max-height: 33vh;
        .e-productlist-filters-options-collapse-peek-toggle {
            &::before {
                content: "";
                height: $spacer * 2;
                position: absolute;
                top: $spacer * -2;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #fff 0%, transparent 95%);
            }
            &::after {
                content: attr(data-closed-text);
            }
            .material-icons {
                transform: rotate(0);
            }
        }
    }
}

.e-productlist-filters-option {
    &.is-selected {
        label {
            // font-weight: $font-weight-bold;
        }
    }
    &.is-disabled {
        label {
            cursor: not-allowed;
        }
    }
}

.e-productlist-filters-search-input {
    // TODO: Fix this
    // font-size: 1em!important;
    // &:hover,
    // &:focus {
    //     font-size: 1em!important;
    // }
}

.e-productlist-filters-modal {
    .modal-footer {
        position: sticky;
        bottom: -1px;
        background: $gray-600;
        display: flex;
        justify-content: space-between;
        .btn + .btn {
            margin-left: auto;
        }
    }
    .e-productlist-sort-label {
        display: block;
    }
    input[type="radio"],
    input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        + label {
            @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $input-btn-line-height, $btn-border-radius);
            @include button-variant(theme-color("secondary"), theme-color("secondary"));
            cursor: pointer;
            display: block;
        }
        &:checked {
            + label {
                background: theme-color("primary");
                box-shadow: $btn-active-box-shadow;
            }
        }
        &:disabled {
            + label {
                pointer-events: none;
                opacity: $text-muted;
                background: $gray-100;
            }
        }
    }
}
