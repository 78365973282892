.p-width-auto {
    .e-section > .container-fluid > .row > div > .row > & {
        @include media-breakpoint-up(xs) {
            @include make-col-ready();
            @include make-col(12);
        }
    }
    .e-section > .container-fluid > .row > .col-lg-12 > .row > & {
        @include media-breakpoint-up(md) {
            @include make-col(10);
            margin-right: percentage( ( $grid-columns - 10) / 2 / $grid-columns);
            margin-left: percentage( ( $grid-columns - 10) / 2 / $grid-columns);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(8);
            margin-right: percentage( ( $grid-columns - 8) / 2 / $grid-columns);
            margin-left: percentage( ( $grid-columns - 8) / 2 / $grid-columns);
        }
    }
}

.p-txt-animate {
    h1,
    h2,
    h3,
    h4,
    ul,
    p {
        opacity: 0;
        transform: translateY(1.5em);
    }
    .animate-in-on-load &,
    .animate-in-on-scroll & {
        h1,
        h2,
        h3,
        h4,
        ul,
        p {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 1s $transition-fade-easing, transform 1s $transition-slide-easing;
            $item-entry-delay: 0.5s;
            @for $item from 1 through 10 {
                &:nth-child(#{$item}) {
                    transition-delay: $item-entry-delay * $item;
                }
            }
        }
    }
}

.p-img-container-parallax {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transform: translateZ(0);
    .p-img-parallax {
        will-change: transform;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        opacity: 0;
        display: block;
        @include media-breakpoint-down(xs) {
            max-width: none;
            width: 200%;
            left: -50%;
        }
        &.is-loaded {
            transition: opacity $transition-fade-speed * 2 $transition-fade-easing;
            opacity: 1;
        }
    }
}

.p-img {
    max-width: 100%;
    height: auto;
}

.p-img-circle {
    @extend .rounded-circle;
}

.p-img-corner-rounded {
    @extend .rounded;
}

.p-img-corner-sharp {
    @extend .rounded-0;
}

.p-img-shadow-none {
    box-shadow: none;
}

.p-img-shadow-small {
    box-shadow: $shadow-sm;
}

.p-img-shadow-medium {
    box-shadow: $shadow-md;
}

.p-img-shadow-large {
    box-shadow: $shadow-lg;
}

.p-img-border-none {
    border: 0;
}

.p-img-border-thin {
    border: 1px solid $border-color;
}

.p-img-border-thick {
    border: 0.375em solid $border-color;
}

.p-img-border-double {
    @extend .p-img-border-thin;
    padding: 0.25em;
}

.p-img-link {
    text-decoration: none;
}
