.e-section {
    .e-scroll-reveal & {
        opacity: 0;
        transform: translateY(10vh);
        will-change: transform, opacity;
        &.animate-in-on-load {
            opacity: 1;
            transform: none;
            transition: opacity $transition-fade-speed * 2 $transition-fade-easing;
        }
        &.animate-in-on-scroll {
            opacity: 1;
            transform: none;
            transition: transform $transition-slide-speed * 2 $transition-slide-easing, opacity $transition-fade-speed * 3 $transition-fade-easing;
        }
        &.reset-animate {
            opacity: 1;
            transform: none;
            transition: none;
            will-change: initial;
        }
    }
}
