.e-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
    color: $header-color;
    background: $header-bg;
    transform: translateY(0);
    transition: transform $transition-slide-speed $transition-slide-easing;
    will-change: transform;
    &.is-sticky {
        position: fixed;
        &.is-stuck {
            box-shadow: $shadow-md;
        }
    }
    &.is-hidden {
        transform: translateY(-110%);
    }
    .e-canvas-fullscreen & {
        background: none;
        box-shadow: none;
        .e-header-btn {
            border: 0;
        }
    }
}

.e-header-container {
    @include e-canvas-padding;
    max-width: $canvas-max-width;
    margin: 0 auto;
}

@include media-breakpoint-down($nav-hamburger-breakpoint) {
    // .e-header-container {
    //     padding-right: 0;
    //     padding-left: 0;
    //     >.container-fluid {
    //         padding-right: 0;
    //         padding-left: 0;
    //         >.row {
    //             margin-right: 0;
    //             margin-left: 0;
    //             >.col-12 {
    //                 // position: static;
    //                 // padding-right: 1em;
    //                 // padding-left: 1em;
    //             }
    //         }
    //     }
    // }
    .e-header-wrapper {
        display: flex;
        justify-content: stretch;
        align-items: stretch;
    }
    .e-logo {
        margin-right: auto;
        .e-logo-img {
            display: block;
            margin: $header-logo-spacing-small auto;
            height: $header-logo-height-small;
            width: auto;
        }
        .e-logo-tagline {
            display: none;
        }
    }
    .e-header-btn {
        display: flex;
        align-items: center;
        padding: 0.25em;
        border-left: 1px solid $header-border-color;
        .btn {
            padding: 1em $canvas-padding-sm;
            background: none;
        }
        // .material-icons {
        //     font-size: 1.75em;
        //     vertical-align: -27%;
        //     margin-right: 0.25em;
        // }
    }
    .e-search-toggle {
        .material-icons {
            //margin: 0;
        }
    }
    .e-cart-mini {
        .material-icons {
            //margin: 0;
        }
    }
    .e-nav-toggle {
        order: 1;
        margin-right: e-strip-unit($grid-gutter-width / 2) * 1vw * -1;
        .btn {
            margin-right: $canvas-padding-sm * -1;
        }
    }
    .e-nav-container {
        height: 100vh;
        color: $header-color;
        background: $header-bg;
        transform: translate3d(-101%, 0, 0);
        will-change: transform;
        transition: transform $transition-slide-speed $transition-slide-easing;
        &.show {
            transform: translate3d(0, 0, 0);
            transition: transform ( $transition-slide-speed / 1.5) $transition-slide-easing;
        }
    }
    .e-nav {
        width: 100%;
        height: 100%;
        color: $header-color;
        .e-nav-masthead {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            padding-left: $canvas-padding-sm;
            margin: 0 ( $grid-gutter-width / 2);
            opacity: 0;
            transition: opacity .35s;
            .show & {
                opacity: 1;
            }
            .e-logo {
                // opacity: 0;
                // transition: opacity .1s;
                // .show & {
                //     opacity: 1;
                // }
            }
            .e-header-btn {
                display: flex;
                border: 0;
            }
            .e-search-toggle {}
            .e-nav-toggle {
                margin-right: 0;
                .show & {
                    animation: rotate 0.25s 0.125s linear;
                }
                .btn {
                    margin-right: 0;
                }
            }
        }
        >:last-child {
            margin-bottom: $spacer * 4;
        }
        .e-search-toggle {
            display: none;
        }
        .e-cart-mini {
            display: none;
        }
    }
    //Size and flexbox props for all nav containers.
    .e-nav-global {}
    //TODO:rename to e-nav-cta use samme markup for all breakpoints
    .e-nav-cta {}
    .e-nav-utilities-primary {}
    .e-nav-utilities-secondary {}
    .e-nav-context {
        display: none;
    }
}

//Header styling for larger breakpoints.
@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    .e-header {}
    .e-header-container {
        // padding-right: 0;
        // padding-left: 0;
        >.container-fluid {
            // padding-right: 0;
            // padding-left: 0;
            >.row {
                // margin-right: 0;
                // margin-left: 0;
                >.col-12 {
                    position: static;
                    // padding-right: 1em;
                    // padding-left: 1em;
                }
            }
        }
    }
    .e-header-wrapper {
        display: flex;
    }
    .e-logo {
        display: flex;
        margin-right: auto;
        align-items: center;
        justify-content: center;
        .e-logo-link {
            margin: auto;
            display: block;
        }
        .e-logo-img {
            display: block;
            margin: $header-logo-spacing-large auto;
            height: $header-logo-height-large;
            width: auto;
        }
        .e-logo-tagline {
            display: none;
        }
    }
    .e-search-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
            color: $header-color;
            padding: 1em;
            background: none;
            &:focus {
                outline: 0;
            }
        }
        // .material-icons {
        //     font-size: 1.75em;
        //     vertical-align: -27%;
        // }
    }
    .e-cart-mini {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
            color: $header-color;
            padding: 1em;
            background: none;
            &:focus {
                outline: 0;
            }
        }
        // .material-icons {
        //     font-size: 1.75em;
        //     vertical-align: -27%;
        // }
    }
    .e-header-btn {
        display: none;
    }
    .e-nav-container {
        display: flex;
        position: static;
        overflow: visible;
        opacity: 1;
    }
    .e-nav-header {
        display: none;
    }
    .e-nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        .e-nav-masthead {
            display: none;
        }
        //Size and flexbox props for all nav containers.
        .e-nav-global {
            flex: 0 1 auto;
        }
        .e-nav-cta {
            //TODO:rename to e-nav-cta use samme markup for all breakpoints
            flex: 0 1 auto;
        }
        .e-nav-utilities-primary {
            //display: none;
            width: 100%;
            order: -1;
        }
        .e-nav-utilities-secondary {
            .e-header & {
                display: none;
            }
        }
        .e-nav-context {
            display: none;
        }
    }
}
