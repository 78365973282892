@charset "UTF-8";
/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.8em;
}

p {
  margin-top: 0;
  margin-bottom: 1.6em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #2196f3;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #2196f3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: 0.65;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

html {
  font-size: 0.875em;
}

@media (min-width: 576px) {
  html {
    font-size: 0.9375em;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 1em;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 1.0625em;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 1.125em;
  }
}

body {
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.25;
  margin-top: 1.6em;
  margin-bottom: 0.8em;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
.h1:first-child,
.h2:first-child,
.h3:first-child,
.h4:first-child {
  margin-top: 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a {
  color: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
.h1 a:hover,
.h2 a:hover,
.h3 a:hover,
.h4 a:hover {
  color: inherit;
  text-decoration: underline;
}

h1,
.h1 {
  margin-left: -0.05em;
  font-family: inherit;
  font-size: 2.75em;
  font-weight: 700;
}

h1.small,
.h1.small {
  font-size: 2.40625em;
  font-weight: 700;
}

@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2.40625em;
  }
  h1.small,
  .h1.small {
    font-size: 2.10547em;
  }
}

@media (max-width: 575px) {
  h1,
  .h1 {
    font-size: 2.0625em;
  }
  h1.small,
  .h1.small {
    font-size: 1.80469em;
  }
}

h2,
.h2 {
  margin-left: -0.05em;
  font-family: inherit;
  font-size: 2em;
  font-weight: 700;
}

h2.small,
.h2.small {
  font-size: 1.75em;
  font-weight: 700;
}

@media (max-width: 767px) {
  h2,
  .h2 {
    font-size: 1.875em;
  }
  h2.small,
  .h2.small {
    font-size: 1.64062em;
  }
}

@media (max-width: 575px) {
  h2,
  .h2 {
    font-size: 1.75em;
  }
  h2.small,
  .h2.small {
    font-size: 1.53125em;
  }
}

h3,
.h3 {
  font-family: inherit;
  font-size: 1.0625em;
  font-weight: 700;
  line-height: 1.40625;
  margin-bottom: 0.4em;
}

h3.small,
.h3.small {
  font-size: 0.92969em;
  font-weight: 700;
}

@media (max-width: 767px) {
  h3,
  .h3 {
    font-size: 1.12891em;
  }
  h3.small,
  .h3.small {
    font-size: 0.98779em;
  }
}

@media (max-width: 575px) {
  h3,
  .h3 {
    font-size: 1.19531em;
  }
  h3.small,
  .h3.small {
    font-size: 1.0459em;
  }
}

h4,
.h4 {
  font-family: inherit;
  font-size: 0.8125em;
  font-weight: 700;
  color: inherit;
  margin-bottom: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.125em;
}

h4.small,
.h4.small {
  font-size: 0.71094em;
  font-weight: 700;
}

h4 + h1,
h4 + h2,
h4 + h3,
h4 + h4,
.h4 + h1,
.h4 + h2,
.h4 + h3,
.h4 + h4 {
  margin-top: 0;
}

.lead {
  font-family: inherit;
  font-size: 1.375em;
  font-weight: 300;
}

.lead.small {
  font-size: 1.20312em;
  font-weight: 300;
}

@media (max-width: 575px) {
  .lead {
    font-size: 1.28906em;
  }
  .lead.small {
    font-size: 1.12793em;
  }
}

p {
  margin-bottom: 1.6em;
}

@media (max-width: 991px) {
  p {
    font-size: 1.0625em;
  }
  p.small {
    font-size: 0.92969em;
  }
}

@media (max-width: 767px) {
  p {
    font-size: 1.125em;
  }
  p.small {
    font-size: 0.98438em;
  }
}

hr {
  margin: 1.6em 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

small,
.small {
  font-size: 0.875em;
  font-weight: inherit;
}

@media (min-width: 768px) {
  a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
    pointer-events: none;
  }
}

a.arrow-left::before,
a.arrow-right::after,
a.arrow-up::before,
a.arrow-down::after {
  content: "";
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  vertical-align: -10%;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  font-smoothing: antialiased;
  color: inherit;
  text-decoration: none;
  transition: transform 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

a.arrow-right::after,
a.arrow-down::after {
  margin: 0 0 0 0.5em;
}

a.arrow-left::before,
a.arrow-up::before {
  margin: 0 0.5em 0 0;
}

a.arrow-right::after {
  content: "arrow_forward";
}

a.arrow-right:hover::after {
  transform: translateX(0.5em);
}

a.arrow-left::before {
  content: "arrow_back";
}

a.arrow-left:hover::before {
  transform: translateX(-0.5em);
}

a.arrow-up::before {
  content: "arrow_upward";
}

a.arrow-up:hover::before {
  transform: translateY(-0.5em);
}

a.arrow-down::after {
  content: "arrow_downwards";
}

a.arrow-down:hover::after {
  transform: translateY(0.5em);
}

ol,
ul {
  margin-top: 1.6em;
  margin-bottom: 2.4em;
  padding-left: 2.4em;
}

ol:first-child,
ul:first-child {
  margin-top: 0;
}

h1 + ol,
h2 + ol,
h3 + ol,
h4 + ol, h1 +
ul,
h2 +
ul,
h3 +
ul,
h4 +
ul {
  margin-top: 1.6em;
}

ol li,
ul li {
  margin-bottom: 0.8em;
  padding-left: 0.8em;
}

.list-unstyled {
  padding: 0;
  list-style: none;
  margin: 0;
}

.list-unstyled > li {
  padding: 0;
  margin: 0;
}

.list-inline {
  padding: 0;
  list-style: none;
  margin: 0;
}

.list-inline > li {
  padding: 0;
  margin: 0;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.8em;
}

pre.dw-error {
  white-space: normal;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25em;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.125em;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.8em;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: 0.65;
}

.container {
  width: 100%;
  padding-right: 2.5%;
  padding-left: 2.5%;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  width: 100%;
  padding-right: 2.5%;
  padding-left: 2.5%;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.5%;
  margin-left: -2.5%;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-2point4, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2point4, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-2point4, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2point4, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2point4, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 0;
  padding-right: 2.5%;
  padding-left: 2.5%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-2point4 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-last {
  order: 13;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-2point4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .order-sm-last {
    order: 13;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-2point4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .order-md-last {
    order: 13;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-2point4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .order-lg-last {
    order: 13;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-2point4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .order-xl-last {
    order: 13;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.6em;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.5em;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
}

.table tbody + tbody {
  border-top: 2px solid rgba(0, 0, 0, 0.15);
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.25em 0.125em;
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e2eccb;
}

.table-hover .table-primary:hover {
  background-color: #d8e5b8;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #d8e5b8;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f9fbf4;
}

.table-hover .table-secondary:hover {
  background-color: #eff4e1;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #eff4e1;
}

.table-variation-one,
.table-variation-one > th,
.table-variation-one > td {
  background-color: #e2eccb;
}

.table-hover .table-variation-one:hover {
  background-color: #d8e5b8;
}

.table-hover .table-variation-one:hover > td,
.table-hover .table-variation-one:hover > th {
  background-color: #d8e5b8;
}

.table-variation-two,
.table-variation-two > th,
.table-variation-two > td {
  background-color: #f9f9f8;
}

.table-hover .table-variation-two:hover {
  background-color: #ededea;
}

.table-hover .table-variation-two:hover > td,
.table-hover .table-variation-two:hover > th {
  background-color: #ededea;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c1e2fc;
}

.table-hover .table-info:hover {
  background-color: #a9d7fb;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a9d7fb;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff9c8;
}

.table-hover .table-warning:hover {
  background-color: #fff6af;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff6af;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde9ce;
}

.table-hover .table-success:hover {
  background-color: #bbe1bd;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bbe1bd;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7;
}

.table-hover .table-danger:hover {
  background-color: #fbb3af;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fbb3af;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212121;
  border-color: #343434;
}

.table .thead-light th {
  color: #616161;
  background-color: #eee;
  border-color: rgba(0, 0, 0, 0.15);
}

.table-dark {
  color: #fff;
  background-color: #212121;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #343434;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm.table-bordered {
    border: 0;
  }
}

@media (max-width: 767px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md.table-bordered {
    border: 0;
  }
}

@media (max-width: 991px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive.table-bordered {
  border: 0;
}

.table {
  font-size: 0.875em;
  border-collapse: separate;
}

.table h1:only-child,
.table h2:only-child,
.table h3:only-child,
.table h4:only-child,
.table p:only-child,
.table ul:only-child {
  line-height: inherit;
}

.table h1:last-child,
.table h2:last-child,
.table h3:last-child,
.table h4:last-child,
.table p:last-child,
.table ul:last-child {
  margin-bottom: 0;
}

.table th {
  font-weight: 700;
}

.table thead:last-child tr:last-child th,
.table thead:last-child tr:last-child td,
.table tbody:last-child tr:last-child th,
.table tbody:last-child tr:last-child td,
.table tfoot:last-child tr:last-child th,
.table tfoot:last-child tr:last-child td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.0625em;
  box-shadow: 0 0 0 transparent;
  transition: border-color 0.375s cubic-bezier(0.33, 0, 0.2, 1), box-shadow 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.15);
  outline: none;
  box-shadow: 0 0 0 transparent, 0 0 0 0.125em rgba(153, 186, 68, 0.25);
}

.form-control::placeholder {
  color: #757575;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #eee;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2em + 2px);
}

select.form-control:focus::-ms-value {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.375em + 1px);
  padding-bottom: calc(0.375em + 1px);
  margin-bottom: 0;
  line-height: 1.25;
}

.col-form-label-lg {
  padding-top: calc(0.375em + 1px);
  padding-bottom: calc(0.375em + 1px);
  font-size: 1.25em;
  line-height: 1.25;
}

.col-form-label-sm {
  padding-top: calc(0.375em + 1px);
  padding-bottom: calc(0.375em + 1px);
  font-size: 0.875em;
  line-height: 1.25;
}

.col-form-legend {
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  margin-bottom: 0;
  font-size: 1em;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  margin-bottom: 0;
  line-height: 1.25;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  padding: 0.375em 0.5em;
  font-size: 0.875em;
  line-height: 1.25;
  border-radius: 0.0625em;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.84375em + 2px);
}

.form-control-lg {
  padding: 0.375em 0.75em;
  font-size: 1.25em;
  line-height: 1.25;
  border-radius: 0.125em;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.3125em + 2px);
}

.form-group {
  margin-bottom: 1.6em;
}

.form-text {
  display: block;
  margin-top: 0.32em;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.8em;
}

.form-check.disabled .form-check-label {
  color: 0.65;
}

.form-check-label {
  padding-left: 1.6em;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.4em;
  margin-left: -1.6em;
}

.form-check-inline {
  display: inline-block;
  margin-right: 0.32em;
}

.form-check-inline .form-check-label {
  vertical-align: middle;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.4em;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.4em;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

input[type=text]::-ms-clear {
  display: none;
}

@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  button[type="submit"],
  select,
  textarea,
  input,
  input:focus,
  input:hover {
    font-size: 16px !important;
  }
}

.form-legend {
  font-size: 1em;
}

.e-form-dw-labels-bold .form-legend {
  font-weight: 700;
}

.form-control-label {
  margin-bottom: 0.32em;
}

.e-form-dw-labels-bold .form-control-label {
  font-weight: 700;
}

.e-form-labels-hide .form-control-label {
  display: none;
}

.form-control.is-invalid {
  border-color: #f44336;
}

.is-invalid .form-check-label {
  color: #f44336;
}

.form-text.is-invalid {
  color: #f44336;
}

.invalid-feedback {
  display: none;
}

.is-invalid .invalid-feedback {
  display: block;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.25;
  border-radius: 0;
  transition: all 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.125em rgba(153, 186, 68, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: .65;
  box-shadow: none;
}

.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  background-image: none;
  box-shadow: 0 0 0 0.125em rgba(153, 186, 68, 0.25), 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #111;
  background: #99BA44 linear-gradient(180deg, #a8c460, #99BA44) repeat-x;
  border-color: #99BA44;
  box-shadow: 0;
}

.btn-primary:hover {
  color: #fff;
  background: #829e3a linear-gradient(180deg, #95ad57, #829e3a) repeat-x;
  border-color: #7a9536;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0, 0 0 0 0.125em rgba(153, 186, 68, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #99BA44;
  border-color: #99BA44;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7a9536;
  background-image: none;
  border-color: #738b33;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.125em rgba(153, 186, 68, 0.5);
}

.btn-secondary {
  color: #111;
  background: #eaf1d9 linear-gradient(180deg, #edf3df, #eaf1d9) repeat-x;
  border-color: #eaf1d9;
  box-shadow: 0;
}

.btn-secondary:hover {
  color: #111;
  background: #dbe7bd linear-gradient(180deg, #e0eac7, #dbe7bd) repeat-x;
  border-color: #d6e3b4;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0, 0 0 0 0.125em rgba(234, 241, 217, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #eaf1d9;
  border-color: #eaf1d9;
}

.btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #111;
  background-color: #d6e3b4;
  background-image: none;
  border-color: #d1e0aa;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.125em rgba(234, 241, 217, 0.5);
}

.btn-variation-one {
  color: #111;
  background: #99BA44 linear-gradient(180deg, #a8c460, #99BA44) repeat-x;
  border-color: #99BA44;
  box-shadow: 0;
}

.btn-variation-one:hover {
  color: #fff;
  background: #829e3a linear-gradient(180deg, #95ad57, #829e3a) repeat-x;
  border-color: #7a9536;
}

.btn-variation-one:focus, .btn-variation-one.focus {
  box-shadow: 0, 0 0 0 0.125em rgba(153, 186, 68, 0.5);
}

.btn-variation-one.disabled, .btn-variation-one:disabled {
  background-color: #99BA44;
  border-color: #99BA44;
}

.btn-variation-one:not([disabled]):not(.disabled):active, .btn-variation-one:not([disabled]):not(.disabled).active,
.show > .btn-variation-one.dropdown-toggle {
  color: #fff;
  background-color: #7a9536;
  background-image: none;
  border-color: #738b33;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.125em rgba(153, 186, 68, 0.5);
}

.btn-variation-two {
  color: #111;
  background: #e9e9e7 linear-gradient(180deg, #ececeb, #e9e9e7) repeat-x;
  border-color: #e9e9e7;
  box-shadow: 0;
}

.btn-variation-two:hover {
  color: #111;
  background: #d7d7d3 linear-gradient(180deg, #ddddda, #d7d7d3) repeat-x;
  border-color: #d1d1cc;
}

.btn-variation-two:focus, .btn-variation-two.focus {
  box-shadow: 0, 0 0 0 0.125em rgba(233, 233, 231, 0.5);
}

.btn-variation-two.disabled, .btn-variation-two:disabled {
  background-color: #e9e9e7;
  border-color: #e9e9e7;
}

.btn-variation-two:not([disabled]):not(.disabled):active, .btn-variation-two:not([disabled]):not(.disabled).active,
.show > .btn-variation-two.dropdown-toggle {
  color: #111;
  background-color: #d1d1cc;
  background-image: none;
  border-color: #cbcbc6;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.125em rgba(233, 233, 231, 0.5);
}

.btn-info {
  color: #fff;
  background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
  border-color: #2196f3;
  box-shadow: 0;
}

.btn-info:hover {
  color: #fff;
  background: #0c83e2 linear-gradient(180deg, #3196e6, #0c83e2) repeat-x;
  border-color: #0c7cd5;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0, 0 0 0 0.125em rgba(33, 150, 243, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0c7cd5;
  background-image: none;
  border-color: #0b75c9;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.125em rgba(33, 150, 243, 0.5);
}

.btn-warning {
  color: #111;
  background: #ffeb3b linear-gradient(180deg, #ffee58, #ffeb3b) repeat-x;
  border-color: #ffeb3b;
  box-shadow: 0;
}

.btn-warning:hover {
  color: #111;
  background: #ffe715 linear-gradient(180deg, #ffeb38, #ffe715) repeat-x;
  border-color: #ffe608;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0, 0 0 0 0.125em rgba(255, 235, 59, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffeb3b;
  border-color: #ffeb3b;
}

.btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #111;
  background-color: #ffe608;
  background-image: none;
  border-color: #fae100;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.125em rgba(255, 235, 59, 0.5);
}

.btn-success {
  color: #fff;
  background: #4caf50 linear-gradient(180deg, #67bb6a, #4caf50) repeat-x;
  border-color: #4caf50;
  box-shadow: 0;
}

.btn-success:hover {
  color: #fff;
  background: #409444 linear-gradient(180deg, #5da460, #409444) repeat-x;
  border-color: #3d8b40;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0, 0 0 0 0.125em rgba(76, 175, 80, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3d8b40;
  background-image: none;
  border-color: #39833c;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.125em rgba(76, 175, 80, 0.5);
}

.btn-danger {
  color: #fff;
  background: #f44336 linear-gradient(180deg, #f65f54, #f44336) repeat-x;
  border-color: #f44336;
  box-shadow: 0;
}

.btn-danger:hover {
  color: #fff;
  background: #f22112 linear-gradient(180deg, #f44235, #f22112) repeat-x;
  border-color: #ea1c0d;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0, 0 0 0 0.125em rgba(244, 67, 54, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #f44336;
  border-color: #f44336;
}

.btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1c0d;
  background-image: none;
  border-color: #de1b0c;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.125em rgba(244, 67, 54, 0.5);
}

.btn-outline-primary {
  color: #99BA44;
  background-color: transparent;
  background-image: none;
  border-color: #99BA44;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #99BA44;
  border-color: #99BA44;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.125em rgba(153, 186, 68, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #99BA44;
  background-color: transparent;
}

.btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #99BA44;
  border-color: #99BA44;
  box-shadow: 0 0 0 0.125em rgba(153, 186, 68, 0.5);
}

.btn-outline-secondary {
  color: #eaf1d9;
  background-color: transparent;
  background-image: none;
  border-color: #eaf1d9;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #eaf1d9;
  border-color: #eaf1d9;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.125em rgba(234, 241, 217, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #eaf1d9;
  background-color: transparent;
}

.btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #eaf1d9;
  border-color: #eaf1d9;
  box-shadow: 0 0 0 0.125em rgba(234, 241, 217, 0.5);
}

.btn-outline-variation-one {
  color: #99BA44;
  background-color: transparent;
  background-image: none;
  border-color: #99BA44;
}

.btn-outline-variation-one:hover {
  color: #fff;
  background-color: #99BA44;
  border-color: #99BA44;
}

.btn-outline-variation-one:focus, .btn-outline-variation-one.focus {
  box-shadow: 0 0 0 0.125em rgba(153, 186, 68, 0.5);
}

.btn-outline-variation-one.disabled, .btn-outline-variation-one:disabled {
  color: #99BA44;
  background-color: transparent;
}

.btn-outline-variation-one:not([disabled]):not(.disabled):active, .btn-outline-variation-one:not([disabled]):not(.disabled).active,
.show > .btn-outline-variation-one.dropdown-toggle {
  color: #fff;
  background-color: #99BA44;
  border-color: #99BA44;
  box-shadow: 0 0 0 0.125em rgba(153, 186, 68, 0.5);
}

.btn-outline-variation-two {
  color: #e9e9e7;
  background-color: transparent;
  background-image: none;
  border-color: #e9e9e7;
}

.btn-outline-variation-two:hover {
  color: #fff;
  background-color: #e9e9e7;
  border-color: #e9e9e7;
}

.btn-outline-variation-two:focus, .btn-outline-variation-two.focus {
  box-shadow: 0 0 0 0.125em rgba(233, 233, 231, 0.5);
}

.btn-outline-variation-two.disabled, .btn-outline-variation-two:disabled {
  color: #e9e9e7;
  background-color: transparent;
}

.btn-outline-variation-two:not([disabled]):not(.disabled):active, .btn-outline-variation-two:not([disabled]):not(.disabled).active,
.show > .btn-outline-variation-two.dropdown-toggle {
  color: #fff;
  background-color: #e9e9e7;
  border-color: #e9e9e7;
  box-shadow: 0 0 0 0.125em rgba(233, 233, 231, 0.5);
}

.btn-outline-info {
  color: #2196f3;
  background-color: transparent;
  background-image: none;
  border-color: #2196f3;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.125em rgba(33, 150, 243, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #2196f3;
  background-color: transparent;
}

.btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
  box-shadow: 0 0 0 0.125em rgba(33, 150, 243, 0.5);
}

.btn-outline-warning {
  color: #ffeb3b;
  background-color: transparent;
  background-image: none;
  border-color: #ffeb3b;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffeb3b;
  border-color: #ffeb3b;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.125em rgba(255, 235, 59, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffeb3b;
  background-color: transparent;
}

.btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffeb3b;
  border-color: #ffeb3b;
  box-shadow: 0 0 0 0.125em rgba(255, 235, 59, 0.5);
}

.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.125em rgba(76, 175, 80, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #4caf50;
  background-color: transparent;
}

.btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 0 0 0.125em rgba(76, 175, 80, 0.5);
}

.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.125em rgba(244, 67, 54, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f44336;
  background-color: transparent;
}

.btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 0 0 0.125em rgba(244, 67, 54, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #2196f3;
  background-color: transparent;
}

.btn-link:hover {
  color: #2196f3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #757575;
}

.btn-lg {
  padding: 0.375em 0.75em;
  font-size: 1.25em;
  line-height: 1.25;
  border-radius: 0;
}

.btn-sm {
  padding: 0.375em 0.5em;
  font-size: 0.875em;
  line-height: 1.25;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.8em;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn {
  font-family: inherit;
  letter-spacing: 0.125em;
  cursor: pointer;
}

.btn:hover {
  transition: none;
}

.btn {
  padding: 1.125em 3.75em;
  font-size: 0.875em;
  line-height: 1.25;
  border-radius: 0;
}

.btn-lg {
  padding: 0.875em 3.75em;
  font-size: 1.125em;
  line-height: 1.25;
  border-radius: 0;
}

.btn-sm {
  padding: 0.875em 2.25em;
  font-size: 0.75em;
  line-height: 1.25;
  border-radius: 0;
}

.fade {
  opacity: 0;
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.25, 1.25);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  5% {
    transform: translateY(20%);
  }
  10% {
    transform: translateY(0%);
  }
  15% {
    transform: translateY(20%);
  }
  20% {
    transform: translateY(0%);
  }
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.31875em;
  vertical-align: 0.31875em;
  content: "";
  border-top: 0.375em solid;
  border-right: 0.375em solid transparent;
  border-bottom: 0;
  border-left: 0.375em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10em;
  padding: 0.5em 0;
  margin: 0.125em 0 0;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.125em;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125em;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.31875em;
  vertical-align: 0.31875em;
  content: "";
  border-top: 0;
  border-right: 0.375em solid transparent;
  border-bottom: 0.375em solid;
  border-left: 0.375em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.8em 0;
  overflow: hidden;
  border-top: 1px solid #eee;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25em 1.5em;
  clear: both;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  background: #eee linear-gradient(180deg, #f1f1f1, #eee) repeat-x;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background: #99BA44 linear-gradient(180deg, #a8c460, #99BA44) repeat-x;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #9e9e9e;
  background-color: transparent;
  background-image: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5em 1.5em;
  margin-bottom: 0;
  font-size: 0.875em;
  color: #757575;
  white-space: nowrap;
}

.dropdown-toggle::after {
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.dropdown-menu {
  font-size: 0.875em;
  min-width: 16em;
  padding: 0.75em 0;
  margin: 0;
  color: inherit;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dropdown-item {
  padding: 0.5em 1.25em;
}

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.6rem;
  padding-left: 2em;
  margin-right: 0;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #99BA44;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.125em rgba(153, 186, 68, 0.25);
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #e0eac6;
  box-shadow: none;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #f5f5f5;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #757575;
}

.custom-control-indicator {
  position: absolute;
  top: 0.26667em;
  left: 0;
  display: block;
  width: 1.06667em;
  height: 1.06667em;
  pointer-events: none;
  user-select: none;
  background-color: #e0e0e0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  box-shadow: inset 0 0.0625em 0.125em rgba(0, 0, 0, 0.15);
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0.0625em;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23fff' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #e0e0e0;
  background-image: none;
  box-shadow: none;
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23fff' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z'/%3E%3C/svg%3E");
}

.custom-controls-stacked {
  display: flex;
  flex-direction: column;
}

.custom-controls-stacked .custom-control {
  margin-bottom: 0.4em;
}

.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2em + 2px);
  padding: 0.375em 1.75em 0.375em 0.75em;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23424242' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75em center;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.0625em;
  appearance: none;
}

.custom-select:focus {
  border-color: #ccdda1;
  outline: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(204, 221, 161, 0.5);
}

.custom-select:focus::-ms-value {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.custom-select[multiple] {
  height: auto;
  background-image: none;
}

.custom-select:disabled {
  color: #757575;
  background-color: #eee;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.84375em + 2px);
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  font-size: 0.875em;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: calc(2em + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  min-width: 14em;
  max-width: 100%;
  height: calc(2em + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-control {
  box-shadow: 0 0 0 0.075em #fff, 0 0 0 0.2em #99BA44;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2em + 2px);
  padding: 0.375em 0.75em;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.65);
  pointer-events: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.0625em;
  box-shadow: 0 0 0 transparent;
}

.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}

.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: calc(2em + 2px);
  padding: 0.375em 0.75em;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.65);
  background: #eee linear-gradient(180deg, #f1f1f1, #eee) repeat-x;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0.0625em 0.0625em 0;
}

.custom-file-control:lang(en)::before {
  content: "Browse";
}

.custom-control {
  min-height: 1.25em;
  line-height: 1.25;
  display: flex;
  align-items: center;
}

.custom-control-indicator {
  top: 0.09167em;
}

.custom-checkbox {
  cursor: pointer;
}

.custom-checkbox:hover .custom-control-indicator {
  background-color: #99BA44;
}

.custom-checkbox .custom-control-indicator {
  background-size: 90% 90%;
}

.custom-radio {
  cursor: pointer;
}

.custom-radio:hover .custom-control-indicator {
  background-color: #99BA44;
}

.custom-radio .custom-control-indicator {
  background-size: 45% 45%;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5em 1em;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #757575;
}

.nav-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.125em;
  border-top-right-radius: 0.125em;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #eee #eee rgba(0, 0, 0, 0.15);
}

.nav-tabs .nav-link.disabled {
  color: #757575;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #616161;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.125em;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #99BA44;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav .nav-item {
  margin: 0;
  padding: 0;
}

.nav-pills {
  margin-bottom: 1.6em;
}

.nav-pills .nav-item {
  margin: 0 0.53333em 0.53333em 0;
}

.nav-pills .nav-item + .nav-item {
  margin-left: 0;
}

.nav-pills .nav-link {
  color: #99BA44;
  border: 1px solid #99BA44;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.125em;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.125em;
  border-top-right-radius: 0.125em;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.125em;
  border-bottom-left-radius: 0.125em;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.2em;
}

.card-title {
  margin-bottom: 0.7em;
}

.card-subtitle {
  margin-top: -0.35em;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.2em;
}

.card-header {
  padding: 0.7em 1.2em;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.card-header:first-child {
  border-radius: calc(0.125em - 1px) calc(0.125em - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.7em 1.2em;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.125em - 1px) calc(0.125em - 1px);
}

.card-header-tabs {
  margin-right: -0.6em;
  margin-bottom: -0.7em;
  margin-left: -0.6em;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.6em;
  margin-left: -0.6em;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25em;
}

.card-img {
  width: 100%;
  border-radius: calc(0.125em - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.125em - 1px);
  border-top-right-radius: calc(0.125em - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.125em - 1px);
  border-bottom-left-radius: calc(0.125em - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 2.5%;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -2.5%;
    margin-left: -2.5%;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 2.5%;
    margin-bottom: 0;
    margin-left: 2.5%;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group .card {
  margin-bottom: 2.5%;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:first-child .card-img-top,
  .card-group .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group .card:first-child .card-img-bottom,
  .card-group .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group .card:last-child .card-img-top,
  .card-group .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group .card:last-child .card-img-bottom,
  .card-group .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group .card:only-child {
    border-radius: 0.125em;
  }
  .card-group .card:only-child .card-img-top,
  .card-group .card:only-child .card-header {
    border-top-left-radius: 0.125em;
    border-top-right-radius: 0.125em;
  }
  .card-group .card:only-child .card-img-bottom,
  .card-group .card:only-child .card-footer {
    border-bottom-right-radius: 0.125em;
    border-bottom-left-radius: 0.125em;
  }
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.7em;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25em;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.card {
  margin-bottom: 0;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.e-breadcrumb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.e-breadcrumb-light-shadow {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, transparent 95%);
}

.e-breadcrumb-light-shadow::after {
  content: "";
  display: block;
  height: 2.4em;
  pointer-events: none;
}

.breadcrumb {
  font-size: 0.75em;
  padding: 0.4em 0;
}

.breadcrumb-item {
  color: rgba(0, 0, 0, 0.65);
}

.breadcrumb-item + .breadcrumb-item::before {
  color: inherit;
  content: "/";
}

.breadcrumb-item a {
  padding: 0.4em 0.8em;
  color: inherit;
  text-decoration: underline;
}

.breadcrumb-item.is-active {
  color: rgba(0, 0, 0, 0.85);
}

.breadcrumb-item.is-active a {
  text-decoration: none;
}

.e-breadcrumb-light .breadcrumb-item,
.e-breadcrumb-light-shadow .breadcrumb-item {
  color: rgba(255, 255, 255, 0.75);
}

.e-breadcrumb-light .breadcrumb-item.is-active,
.e-breadcrumb-light-shadow .breadcrumb-item.is-active {
  color: white;
}

.e-breadcrumb-dark .breadcrumb-item {
  color: rgba(0, 0, 0, 0.65);
}

.e-breadcrumb-dark .breadcrumb-item.is-active {
  color: rgba(0, 0, 0, 0.85);
}

.pagination {
  display: flex;
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 0.125em;
}

.pagination > li {
  padding: 0;
  margin: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5em 0.75em;
  margin-left: -1px;
  line-height: 1.25;
  color: #2196f3;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.page-link:focus, .page-link:hover {
  color: #2196f3;
  text-decoration: none;
  background-color: #eee;
  border-color: rgba(0, 0, 0, 0.15);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.125em;
  border-bottom-left-radius: 0.125em;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.125em;
  border-bottom-right-radius: 0.125em;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.page-item.disabled .page-link {
  color: #757575;
  pointer-events: none;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.15);
}

.pagination-lg .page-link {
  padding: 0.75em 1.5em;
  font-size: 1.25em;
  line-height: 1.6;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.125em;
  border-bottom-left-radius: 0.125em;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.125em;
  border-bottom-right-radius: 0.125em;
}

.pagination-sm .page-link {
  padding: 0.25em 0.5em;
  font-size: 0.875em;
  line-height: 1.25;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.0625em;
  border-bottom-left-radius: 0.0625em;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.0625em;
  border-bottom-right-radius: 0.0625em;
}

.page-link:hover {
  text-decoration: none;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 0.875em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.125em;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10em;
}

.badge-primary {
  color: #111;
  background-color: #99BA44;
}

.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #7a9536;
}

.badge-secondary {
  color: #111;
  background-color: #eaf1d9;
}

.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #d6e3b4;
}

.badge-variation-one {
  color: #111;
  background-color: #99BA44;
}

.badge-variation-one[href]:focus, .badge-variation-one[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #7a9536;
}

.badge-variation-two {
  color: #111;
  background-color: #e9e9e7;
}

.badge-variation-two[href]:focus, .badge-variation-two[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #d1d1cc;
}

.badge-info {
  color: #fff;
  background-color: #2196f3;
}

.badge-info[href]:focus, .badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0c7cd5;
}

.badge-warning {
  color: #111;
  background-color: #ffeb3b;
}

.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #ffe608;
}

.badge-success {
  color: #fff;
  background-color: #4caf50;
}

.badge-success[href]:focus, .badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #3d8b40;
}

.badge-danger {
  color: #fff;
  background-color: #f44336;
}

.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #ea1c0d;
}

.alert {
  position: relative;
  padding: 0.75em 1.25em;
  margin-bottom: 1em;
  border: 1px solid transparent;
  border-radius: 0.125em;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4em;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75em 1.25em;
  color: inherit;
}

.alert-primary {
  color: #506123;
  background: #ebf1da linear-gradient(180deg, #eef3e0, #ebf1da) repeat-x;
  border-color: #e2eccb;
}

.alert-primary hr {
  border-top-color: #d8e5b8;
}

.alert-primary .alert-link {
  color: #313c15;
}

.alert-secondary {
  color: #7a7d71;
  background: #fbfcf7 linear-gradient(180deg, #fcfcf8, #fbfcf7) repeat-x;
  border-color: #f9fbf4;
}

.alert-secondary hr {
  border-top-color: #eff4e1;
}

.alert-secondary .alert-link {
  color: #606259;
}

.alert-variation-one {
  color: #506123;
  background: #ebf1da linear-gradient(180deg, #eef3e0, #ebf1da) repeat-x;
  border-color: #e2eccb;
}

.alert-variation-one hr {
  border-top-color: #d8e5b8;
}

.alert-variation-one .alert-link {
  color: #313c15;
}

.alert-variation-two {
  color: #797978;
  background: #fbfbfa linear-gradient(180deg, #fcfcfb, #fbfbfa) repeat-x;
  border-color: #f9f9f8;
}

.alert-variation-two hr {
  border-top-color: #ededea;
}

.alert-variation-two .alert-link {
  color: #5f5f5f;
}

.alert-info {
  color: #114e7e;
  background: #d3eafd linear-gradient(180deg, #daedfd, #d3eafd) repeat-x;
  border-color: #c1e2fc;
}

.alert-info hr {
  border-top-color: #a9d7fb;
}

.alert-info .alert-link {
  color: #0b3251;
}

.alert-warning {
  color: #857a1f;
  background: #fffbd8 linear-gradient(180deg, #fffcde, #fffbd8) repeat-x;
  border-color: #fff9c8;
}

.alert-warning hr {
  border-top-color: #fff6af;
}

.alert-warning .alert-link {
  color: #5c5415;
}

.alert-success {
  color: #285b2a;
  background: #dbefdc linear-gradient(180deg, #e0f1e1, #dbefdc) repeat-x;
  border-color: #cde9ce;
}

.alert-success hr {
  border-top-color: #bbe1bd;
}

.alert-success .alert-link {
  color: #18381a;
}

.alert-danger {
  color: #7f231c;
  background: #fdd9d7 linear-gradient(180deg, #fddfdd, #fdd9d7) repeat-x;
  border-color: #fccac7;
}

.alert-danger hr {
  border-top-color: #fbb3af;
}

.alert-danger .alert-link {
  color: #551713;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #616161;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #616161;
  text-decoration: none;
  background-color: #f5f5f5;
}

.list-group-item-action:active {
  color: rgba(0, 0, 0, 0.65);
  background-color: #eee;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75em 1.25em;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.list-group-item:first-child {
  border-top-left-radius: 0.125em;
  border-top-right-radius: 0.125em;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.125em;
  border-bottom-left-radius: 0.125em;
}

.list-group-item:focus, .list-group-item:hover {
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #757575;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 1;
  color: #fff;
  background-color: #99BA44;
  border-color: #99BA44;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #506123;
  background-color: #e2eccb;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #506123;
}

a.list-group-item-primary:focus, a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
  color: #506123;
  background-color: #d8e5b8;
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
  color: #fff;
  background-color: #506123;
  border-color: #506123;
}

.list-group-item-secondary {
  color: #7a7d71;
  background-color: #f9fbf4;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #7a7d71;
}

a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #7a7d71;
  background-color: #eff4e1;
}

a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  color: #fff;
  background-color: #7a7d71;
  border-color: #7a7d71;
}

.list-group-item-variation-one {
  color: #506123;
  background-color: #e2eccb;
}

a.list-group-item-variation-one,
button.list-group-item-variation-one {
  color: #506123;
}

a.list-group-item-variation-one:focus, a.list-group-item-variation-one:hover,
button.list-group-item-variation-one:focus,
button.list-group-item-variation-one:hover {
  color: #506123;
  background-color: #d8e5b8;
}

a.list-group-item-variation-one.active,
button.list-group-item-variation-one.active {
  color: #fff;
  background-color: #506123;
  border-color: #506123;
}

.list-group-item-variation-two {
  color: #797978;
  background-color: #f9f9f8;
}

a.list-group-item-variation-two,
button.list-group-item-variation-two {
  color: #797978;
}

a.list-group-item-variation-two:focus, a.list-group-item-variation-two:hover,
button.list-group-item-variation-two:focus,
button.list-group-item-variation-two:hover {
  color: #797978;
  background-color: #ededea;
}

a.list-group-item-variation-two.active,
button.list-group-item-variation-two.active {
  color: #fff;
  background-color: #797978;
  border-color: #797978;
}

.list-group-item-info {
  color: #114e7e;
  background-color: #c1e2fc;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #114e7e;
}

a.list-group-item-info:focus, a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #114e7e;
  background-color: #a9d7fb;
}

a.list-group-item-info.active,
button.list-group-item-info.active {
  color: #fff;
  background-color: #114e7e;
  border-color: #114e7e;
}

.list-group-item-warning {
  color: #857a1f;
  background-color: #fff9c8;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #857a1f;
}

a.list-group-item-warning:focus, a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #857a1f;
  background-color: #fff6af;
}

a.list-group-item-warning.active,
button.list-group-item-warning.active {
  color: #fff;
  background-color: #857a1f;
  border-color: #857a1f;
}

.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #285b2a;
}

a.list-group-item-success:focus, a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #285b2a;
  background-color: #bbe1bd;
}

a.list-group-item-success.active,
button.list-group-item-success.active {
  color: #fff;
  background-color: #285b2a;
  border-color: #285b2a;
}

.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #7f231c;
}

a.list-group-item-danger:focus, a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #7f231c;
  background-color: #fbb3af;
}

a.list-group-item-danger.active,
button.list-group-item-danger.active {
  color: #fff;
  background-color: #7f231c;
  border-color: #7f231c;
}

.close {
  float: right;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: .5;
}

.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

button.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 1em;
  opacity: 1;
  cursor: pointer;
}

button.close:hover {
  opacity: inherit;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 2.5%;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.125em;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.65;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-top-left-radius: 0.125em;
  border-top-right-radius: 0.125em;
}

.modal-header .close {
  padding: 5%;
  margin: -5% -5% -5% auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 5%;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 50%;
    margin: 5vmax auto;
  }
  .modal-content {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  .modal-sm {
    max-width: 25%;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 75%;
  }
}

.modal-open .modal-fullscreen {
  overflow-y: scroll;
  padding-right: 0 !important;
}

.modal-fullscreen .close {
  position: sticky;
  margin: -5%;
}

.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  transition: none;
}

.modal-fullscreen .modal-content {
  min-height: 100vh;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 50%;
    margin: 5vmax auto;
  }
  .modal-content {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  .modal-sm {
    max-width: 25%;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 75%;
  }
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  opacity: 0.65;
}

.carousel-control-prev:focus, .carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001));
}

.carousel-control-next {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001));
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: none;
}

.carousel-control-next-icon {
  background-image: none;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 5%;
  margin-left: 5%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 1em;
  height: 1em;
  margin-right: 0.25em;
  margin-left: 0.25em;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: rgba(255, 255, 255, 0.65);
}

.carousel-caption {
  position: absolute;
  right: 5%;
  bottom: 20px;
  left: 5%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.carousel {
  height: 50vw;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  height: 100%;
}

.carousel-control-prev,
.carousel-control-next {
  font-size: 2em;
  cursor: pointer;
  background: none;
  overflow: hidden;
}

.carousel:hover .carousel-control-prev, .carousel:hover
.carousel-control-next {
  opacity: 1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: rgba(0, 0, 0, 0.65);
  height: auto;
}

.carousel-indicators {
  bottom: 0.8em;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
}

.carousel-indicators li {
  font-size: 0.75em;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.65);
  border: 1px solid transparent;
  margin-bottom: 0;
  padding: 0;
  background-clip: content-box;
  transition: border-color 0.25s, background-color 0.25s;
}

.carousel-indicators .active {
  background: none;
  border-color: rgba(255, 255, 255, 0.65);
}

.carousel-caption {
  padding-right: 1.6vw;
  padding-left: 1.6vw;
  right: 0;
  bottom: 1.6em;
  left: 0;
  padding-top: 1.6em;
  padding-bottom: 1.6em;
  text-align: left;
}

@media (min-width: 1200px) {
  .carousel-caption {
    padding-right: 3.2vw;
    padding-left: 3.2vw;
  }
}

@media (min-width: 2560px) {
  .carousel-caption {
    padding-right: 512px;
    padding-left: 512px;
  }
}

.active .carousel-caption {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.active.carousel-item-left .carousel-caption {
  opacity: 0.5;
  transition: opacity 0.375s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.active.carousel-item-right .carousel-caption {
  opacity: 0.5;
  transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.carousel-item-next.carousel-item-left .carousel-caption {
  transform: translateY(3.2em);
  opacity: 0;
}

.carousel-item-prev.carousel-item-right .carousel-caption {
  opacity: 0;
  transform: translateY(3.2em);
}

.carousel-caption h1,
.carousel-caption h2,
.carousel-caption h3,
.carousel-caption h4,
.carousel-caption p {
  color: white;
  line-height: 1.25;
  margin-bottom: 0.5rem;
}

.carousel-caption h4 {
  color: rgba(255, 255, 255, 0.75);
  margin-bottom: 0.25rem;
}

.carousel-caption p {
  color: rgba(255, 255, 255, 0.75);
}

.carousel-caption .btn {
  margin-top: 1rem;
  pointer-events: auto;
}

.collapse-peek-disabled .collapse-peek-toggle {
  display: none;
}

.collapse-peek-content {
  position: relative;
}

.collapse-peek-content:not(.show) {
  display: block;
  overflow: hidden;
  height: 33vh;
  min-height: 33vh;
}

@media (max-width: 575px) {
  .collapse-peek-content:not(.show) {
    height: 22vh;
    min-height: 22vh;
  }
}

.collapse-peek-sm .collapse-peek-content:not(.show) {
  height: 25vh;
  min-height: 25vh;
}

@media (max-width: 575px) {
  .collapse-peek-sm .collapse-peek-content:not(.show) {
    height: 16.66667vh;
    min-height: 16.66667vh;
  }
}

.collapse-peek-lg .collapse-peek-content:not(.show) {
  height: 50vh;
  min-height: 50vh;
}

@media (max-width: 575px) {
  .collapse-peek-lg .collapse-peek-content:not(.show) {
    height: 33.33333vh;
    min-height: 33.33333vh;
  }
}

.collapse-peek-content::after, .collapse-peek-content.is-collapsing::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, white 0%, white 2.5%, rgba(255, 255, 255, 0) 85%);
  opacity: 1;
}

.collapse-peek-content.show::after {
  top: 100%;
  opacity: 0;
}

.collapse-peek-content.is-collapsing::after {
  top: 0;
  opacity: 1;
  transition: all 0.33333s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.collapse-peek-content.is-expanding::after {
  top: 100%;
  opacity: 0;
  transition: all 0.33333s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.collapse-peek-content + .collapse-peek-toggle .collapse-peek-btn-show {
  display: inline;
}

.collapse-peek-content + .collapse-peek-toggle .collapse-peek-btn-hide {
  display: none;
}

.collapse-peek-content.show + .collapse-peek-toggle .collapse-peek-btn-show {
  display: none;
}

.collapse-peek-content.show + .collapse-peek-toggle .collapse-peek-btn-hide {
  display: inline;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #99BA44 !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #7a9536 !important;
}

.bg-secondary {
  background-color: #eaf1d9 !important;
}

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #d6e3b4 !important;
}

.bg-variation-one {
  background-color: #99BA44 !important;
}

a.bg-variation-one:focus, a.bg-variation-one:hover {
  background-color: #7a9536 !important;
}

.bg-variation-two {
  background-color: #e9e9e7 !important;
}

a.bg-variation-two:focus, a.bg-variation-two:hover {
  background-color: #d1d1cc !important;
}

.bg-info {
  background-color: #2196f3 !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #0c7cd5 !important;
}

.bg-warning {
  background-color: #ffeb3b !important;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #ffe608 !important;
}

.bg-success {
  background-color: #4caf50 !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #3d8b40 !important;
}

.bg-danger {
  background-color: #f44336 !important;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #ea1c0d !important;
}

.bg-gradient-primary {
  background: #99BA44 linear-gradient(180deg, #a8c460, #99BA44) repeat-x !important;
}

.bg-gradient-secondary {
  background: #eaf1d9 linear-gradient(180deg, #edf3df, #eaf1d9) repeat-x !important;
}

.bg-gradient-variation-one {
  background: #99BA44 linear-gradient(180deg, #a8c460, #99BA44) repeat-x !important;
}

.bg-gradient-variation-two {
  background: #e9e9e7 linear-gradient(180deg, #ececeb, #e9e9e7) repeat-x !important;
}

.bg-gradient-info {
  background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x !important;
}

.bg-gradient-warning {
  background: #ffeb3b linear-gradient(180deg, #ffee58, #ffeb3b) repeat-x !important;
}

.bg-gradient-success {
  background: #4caf50 linear-gradient(180deg, #67bb6a, #4caf50) repeat-x !important;
}

.bg-gradient-danger {
  background: #f44336 linear-gradient(180deg, #f65f54, #f44336) repeat-x !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #99BA44 !important;
}

.border-secondary {
  border-color: #eaf1d9 !important;
}

.border-variation-one {
  border-color: #99BA44 !important;
}

.border-variation-two {
  border-color: #e9e9e7 !important;
}

.border-info {
  border-color: #2196f3 !important;
}

.border-warning {
  border-color: #ffeb3b !important;
}

.border-success {
  border-color: #4caf50 !important;
}

.border-danger {
  border-color: #f44336 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded, .p-img-corner-rounded {
  border-radius: 0.125em !important;
}

.rounded-top {
  border-top-left-radius: 0.125em !important;
  border-top-right-radius: 0.125em !important;
}

.rounded-right {
  border-top-right-radius: 0.125em !important;
  border-bottom-right-radius: 0.125em !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.125em !important;
  border-bottom-left-radius: 0.125em !important;
}

.rounded-left {
  border-top-left-radius: 0.125em !important;
  border-bottom-left-radius: 0.125em !important;
}

.rounded-circle, .p-img-circle {
  border-radius: 50% !important;
}

.rounded-0, .p-img-corner-sharp {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

.d-print-block {
  display: none !important;
}

@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}

@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.8em !important;
}

.mt-1,
.my-1 {
  margin-top: 0.8em !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.8em !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.8em !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.8em !important;
}

.m-2 {
  margin: 1.6em !important;
}

.mt-2,
.my-2 {
  margin-top: 1.6em !important;
}

.mr-2,
.mx-2 {
  margin-right: 1.6em !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1.6em !important;
}

.ml-2,
.mx-2 {
  margin-left: 1.6em !important;
}

.m-3 {
  margin: 2.4em !important;
}

.mt-3,
.my-3 {
  margin-top: 2.4em !important;
}

.mr-3,
.mx-3 {
  margin-right: 2.4em !important;
}

.mb-3,
.my-3 {
  margin-bottom: 2.4em !important;
}

.ml-3,
.mx-3 {
  margin-left: 2.4em !important;
}

.m-4 {
  margin: 3.2em !important;
}

.mt-4,
.my-4 {
  margin-top: 3.2em !important;
}

.mr-4,
.mx-4 {
  margin-right: 3.2em !important;
}

.mb-4,
.my-4 {
  margin-bottom: 3.2em !important;
}

.ml-4,
.mx-4 {
  margin-left: 3.2em !important;
}

.m-5 {
  margin: 4em !important;
}

.mt-5,
.my-5 {
  margin-top: 4em !important;
}

.mr-5,
.mx-5 {
  margin-right: 4em !important;
}

.mb-5,
.my-5 {
  margin-bottom: 4em !important;
}

.ml-5,
.mx-5 {
  margin-left: 4em !important;
}

.m-6 {
  margin: 4.8em !important;
}

.mt-6,
.my-6 {
  margin-top: 4.8em !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.8em !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.8em !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.8em !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.8em !important;
}

.pt-1,
.py-1 {
  padding-top: 0.8em !important;
}

.pr-1,
.px-1 {
  padding-right: 0.8em !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.8em !important;
}

.pl-1,
.px-1 {
  padding-left: 0.8em !important;
}

.p-2 {
  padding: 1.6em !important;
}

.pt-2,
.py-2 {
  padding-top: 1.6em !important;
}

.pr-2,
.px-2 {
  padding-right: 1.6em !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1.6em !important;
}

.pl-2,
.px-2 {
  padding-left: 1.6em !important;
}

.p-3 {
  padding: 2.4em !important;
}

.pt-3,
.py-3 {
  padding-top: 2.4em !important;
}

.pr-3,
.px-3 {
  padding-right: 2.4em !important;
}

.pb-3,
.py-3 {
  padding-bottom: 2.4em !important;
}

.pl-3,
.px-3 {
  padding-left: 2.4em !important;
}

.p-4 {
  padding: 3.2em !important;
}

.pt-4,
.py-4 {
  padding-top: 3.2em !important;
}

.pr-4,
.px-4 {
  padding-right: 3.2em !important;
}

.pb-4,
.py-4 {
  padding-bottom: 3.2em !important;
}

.pl-4,
.px-4 {
  padding-left: 3.2em !important;
}

.p-5 {
  padding: 4em !important;
}

.pt-5,
.py-5 {
  padding-top: 4em !important;
}

.pr-5,
.px-5 {
  padding-right: 4em !important;
}

.pb-5,
.py-5 {
  padding-bottom: 4em !important;
}

.pl-5,
.px-5 {
  padding-left: 4em !important;
}

.p-6 {
  padding: 4.8em !important;
}

.pt-6,
.py-6 {
  padding-top: 4.8em !important;
}

.pr-6,
.px-6 {
  padding-right: 4.8em !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.8em !important;
}

.pl-6,
.px-6 {
  padding-left: 4.8em !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.8em !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.8em !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.8em !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.8em !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.8em !important;
  }
  .m-sm-2 {
    margin: 1.6em !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1.6em !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1.6em !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1.6em !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1.6em !important;
  }
  .m-sm-3 {
    margin: 2.4em !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2.4em !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2.4em !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2.4em !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2.4em !important;
  }
  .m-sm-4 {
    margin: 3.2em !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3.2em !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3.2em !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3.2em !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3.2em !important;
  }
  .m-sm-5 {
    margin: 4em !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4em !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4em !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4em !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4em !important;
  }
  .m-sm-6 {
    margin: 4.8em !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.8em !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.8em !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.8em !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.8em !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.8em !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.8em !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.8em !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.8em !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.8em !important;
  }
  .p-sm-2 {
    padding: 1.6em !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1.6em !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1.6em !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1.6em !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1.6em !important;
  }
  .p-sm-3 {
    padding: 2.4em !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2.4em !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2.4em !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2.4em !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2.4em !important;
  }
  .p-sm-4 {
    padding: 3.2em !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3.2em !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3.2em !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3.2em !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3.2em !important;
  }
  .p-sm-5 {
    padding: 4em !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4em !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4em !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4em !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4em !important;
  }
  .p-sm-6 {
    padding: 4.8em !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.8em !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.8em !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.8em !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.8em !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.8em !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.8em !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.8em !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.8em !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.8em !important;
  }
  .m-md-2 {
    margin: 1.6em !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1.6em !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1.6em !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1.6em !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1.6em !important;
  }
  .m-md-3 {
    margin: 2.4em !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2.4em !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2.4em !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2.4em !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2.4em !important;
  }
  .m-md-4 {
    margin: 3.2em !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3.2em !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3.2em !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3.2em !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3.2em !important;
  }
  .m-md-5 {
    margin: 4em !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4em !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4em !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4em !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4em !important;
  }
  .m-md-6 {
    margin: 4.8em !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.8em !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.8em !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.8em !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.8em !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.8em !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.8em !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.8em !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.8em !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.8em !important;
  }
  .p-md-2 {
    padding: 1.6em !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1.6em !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1.6em !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1.6em !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1.6em !important;
  }
  .p-md-3 {
    padding: 2.4em !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2.4em !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2.4em !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2.4em !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2.4em !important;
  }
  .p-md-4 {
    padding: 3.2em !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3.2em !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3.2em !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3.2em !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3.2em !important;
  }
  .p-md-5 {
    padding: 4em !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4em !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4em !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4em !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4em !important;
  }
  .p-md-6 {
    padding: 4.8em !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.8em !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.8em !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.8em !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.8em !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.8em !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.8em !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.8em !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.8em !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.8em !important;
  }
  .m-lg-2 {
    margin: 1.6em !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1.6em !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1.6em !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1.6em !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1.6em !important;
  }
  .m-lg-3 {
    margin: 2.4em !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2.4em !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2.4em !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2.4em !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2.4em !important;
  }
  .m-lg-4 {
    margin: 3.2em !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3.2em !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3.2em !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3.2em !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3.2em !important;
  }
  .m-lg-5 {
    margin: 4em !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4em !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4em !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4em !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4em !important;
  }
  .m-lg-6 {
    margin: 4.8em !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.8em !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.8em !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.8em !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.8em !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.8em !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.8em !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.8em !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.8em !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.8em !important;
  }
  .p-lg-2 {
    padding: 1.6em !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1.6em !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1.6em !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1.6em !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1.6em !important;
  }
  .p-lg-3 {
    padding: 2.4em !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2.4em !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2.4em !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2.4em !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2.4em !important;
  }
  .p-lg-4 {
    padding: 3.2em !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3.2em !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3.2em !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3.2em !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3.2em !important;
  }
  .p-lg-5 {
    padding: 4em !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4em !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4em !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4em !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4em !important;
  }
  .p-lg-6 {
    padding: 4.8em !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.8em !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.8em !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.8em !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.8em !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.8em !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.8em !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.8em !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.8em !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.8em !important;
  }
  .m-xl-2 {
    margin: 1.6em !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1.6em !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1.6em !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1.6em !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1.6em !important;
  }
  .m-xl-3 {
    margin: 2.4em !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2.4em !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2.4em !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2.4em !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2.4em !important;
  }
  .m-xl-4 {
    margin: 3.2em !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3.2em !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3.2em !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3.2em !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3.2em !important;
  }
  .m-xl-5 {
    margin: 4em !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4em !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4em !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4em !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4em !important;
  }
  .m-xl-6 {
    margin: 4.8em !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.8em !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.8em !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.8em !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.8em !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.8em !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.8em !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.8em !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.8em !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.8em !important;
  }
  .p-xl-2 {
    padding: 1.6em !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1.6em !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1.6em !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1.6em !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1.6em !important;
  }
  .p-xl-3 {
    padding: 2.4em !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2.4em !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2.4em !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2.4em !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2.4em !important;
  }
  .p-xl-4 {
    padding: 3.2em !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3.2em !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3.2em !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3.2em !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3.2em !important;
  }
  .p-xl-5 {
    padding: 4em !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4em !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4em !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4em !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4em !important;
  }
  .p-xl-6 {
    padding: 4.8em !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.8em !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.8em !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.8em !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.8em !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #99BA44 !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #7a9536 !important;
}

.text-secondary {
  color: #eaf1d9 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #d6e3b4 !important;
}

.text-variation-one {
  color: #99BA44 !important;
}

a.text-variation-one:focus, a.text-variation-one:hover {
  color: #7a9536 !important;
}

.text-variation-two {
  color: #e9e9e7 !important;
}

a.text-variation-two:focus, a.text-variation-two:hover {
  color: #d1d1cc !important;
}

.text-info {
  color: #2196f3 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #0c7cd5 !important;
}

.text-warning {
  color: #ffeb3b !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #ffe608 !important;
}

.text-success {
  color: #4caf50 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #3d8b40 !important;
}

.text-danger {
  color: #f44336 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #ea1c0d !important;
}

.text-muted {
  color: 0.65 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.embed-responsive-21by9::before {
  padding-top: 31.25%;
}

.text-black {
  color: #000 !important;
}

.text-light {
  color: rgba(255, 255, 255, 0.75) !important;
}

.text-dark {
  color: rgba(0, 0, 0, 0.65) !important;
}

.text-link {
  color: #2196f3 !important;
}

.text-auto {
  color: inherit !important;
}

.text-muted {
  opacity: 0.65;
}

.text-monospace {
  font-family: "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;
}

.text-decoration-none, .text-decoration-none:hover {
  text-decoration: none;
}

.line-height-sm {
  line-height: 1.25;
}

.material-icons {
  font-size: 1em;
  vertical-align: -10%;
}

.material-icons-large {
  font-size: 1.75em;
  vertical-align: -27%;
}

.material-icons-2x {
  font-size: 2em;
  vertical-align: -25%;
}

.material-icons-fixed {
  width: 1.28571429em;
  text-align: center;
}

.material-icons.ml-1 {
  margin-left: 0.2em !important;
}

.material-icons.mr-1 {
  margin-right: 0.2em !important;
}

.material-icons-ul {
  padding-left: 0;
  margin-left: 0;
  list-style-type: none;
}

.material-icons-ul > li {
  position: relative;
  padding-left: 2.4em;
}

.material-icons-ul > li > .material-icons {
  font-size: 1.5em;
  position: absolute;
  left: 0;
  text-align: left;
}

.e-canvas {
  position: relative;
  max-width: 2560px;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
}

@media (max-width: 991px) {
  .e-canvas {
    padding-top: 3.9375rem;
  }
}

@media (min-width: 992px) {
  .e-canvas {
    padding-top: 3.96875rem;
  }
}

.e-canvas-fullscreen {
  max-width: 100%;
  padding-top: 0;
}

.e-canvas-fullscreen::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: 5em;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) -2em, transparent 5em);
}

.e-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  color: #212121;
  background: #fff;
  transform: translateY(0);
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
}

.e-header.is-sticky {
  position: fixed;
}

.e-header.is-sticky.is-stuck {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.e-header.is-hidden {
  transform: translateY(-110%);
}

.e-canvas-fullscreen .e-header {
  background: none;
  box-shadow: none;
}

.e-canvas-fullscreen .e-header .e-header-btn {
  border: 0;
}

.e-header-container {
  padding-right: 1.6vw;
  padding-left: 1.6vw;
  max-width: 2560px;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .e-header-container {
    padding-right: 3.2vw;
    padding-left: 3.2vw;
  }
}

@media (min-width: 2560px) {
  .e-header-container {
    padding-right: 512px;
    padding-left: 512px;
  }
}

@media (max-width: 991px) {
  .e-header-wrapper {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
  }
  .e-logo {
    margin-right: auto;
  }
  .e-logo .e-logo-img {
    display: block;
    margin: 1rem auto;
    height: 2rem;
    width: auto;
  }
  .e-logo .e-logo-tagline {
    display: none;
  }
  .e-header-btn {
    display: flex;
    align-items: center;
    padding: 0.25em;
    border-left: 1px solid rgba(33, 33, 33, 0.15);
  }
  .e-header-btn .btn {
    padding: 1em 1.6vw;
    background: none;
  }
  .e-nav-toggle {
    order: 1;
    margin-right: -2.5vw;
  }
  .e-nav-toggle .btn {
    margin-right: -1.6vw;
  }
  .e-nav-container {
    height: 100vh;
    color: #212121;
    background: #fff;
    transform: translate3d(-101%, 0, 0);
    will-change: transform;
    transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .e-nav-container.show {
    transform: translate3d(0, 0, 0);
    transition: transform 0.16667s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .e-nav {
    width: 100%;
    height: 100%;
    color: #212121;
  }
  .e-nav .e-nav-masthead {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-left: 1.6vw;
    margin: 0 2.5%;
    opacity: 0;
    transition: opacity .35s;
  }
  .show .e-nav .e-nav-masthead {
    opacity: 1;
  }
  .e-nav .e-nav-masthead .e-header-btn {
    display: flex;
    border: 0;
  }
  .e-nav .e-nav-masthead .e-nav-toggle {
    margin-right: 0;
  }
  .show .e-nav .e-nav-masthead .e-nav-toggle {
    animation: rotate 0.25s 0.125s linear;
  }
  .e-nav .e-nav-masthead .e-nav-toggle .btn {
    margin-right: 0;
  }
  .e-nav > :last-child {
    margin-bottom: 6.4em;
  }
  .e-nav .e-search-toggle {
    display: none;
  }
  .e-nav .e-cart-mini {
    display: none;
  }
  .e-nav-context {
    display: none;
  }
}

@media (min-width: 992px) {
  .e-header-container > .container-fluid > .row > .col-12 {
    position: static;
  }
  .e-header-wrapper {
    display: flex;
  }
  .e-logo {
    display: flex;
    margin-right: auto;
    align-items: center;
    justify-content: center;
  }
  .e-logo .e-logo-link {
    margin: auto;
    display: block;
  }
  .e-logo .e-logo-img {
    display: block;
    margin: 0.5rem auto;
    height: 3rem;
    width: auto;
  }
  .e-logo .e-logo-tagline {
    display: none;
  }
  .e-search-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .e-search-toggle .btn {
    color: #212121;
    padding: 1em;
    background: none;
  }
  .e-search-toggle .btn:focus {
    outline: 0;
  }
  .e-cart-mini {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .e-cart-mini .btn {
    color: #212121;
    padding: 1em;
    background: none;
  }
  .e-cart-mini .btn:focus {
    outline: 0;
  }
  .e-header-btn {
    display: none;
  }
  .e-nav-container {
    display: flex;
    position: static;
    overflow: visible;
    opacity: 1;
  }
  .e-nav-header {
    display: none;
  }
  .e-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .e-nav .e-nav-masthead {
    display: none;
  }
  .e-nav .e-nav-global {
    flex: 0 1 auto;
  }
  .e-nav .e-nav-cta {
    flex: 0 1 auto;
  }
  .e-nav .e-nav-utilities-primary {
    width: 100%;
    order: -1;
  }
  .e-header .e-nav .e-nav-utilities-secondary {
    display: none;
  }
  .e-nav .e-nav-context {
    display: none;
  }
}

.e-content {
  position: relative;
  background: #fff;
}

.e-section {
  padding-right: 1.6vw;
  padding-left: 1.6vw;
}

@media (min-width: 1200px) {
  .e-section {
    padding-right: 3.2vw;
    padding-left: 3.2vw;
  }
}

@media (min-width: 2560px) {
  .e-section {
    padding-right: 512px;
    padding-left: 512px;
  }
}

.e-content-main {
  padding-top: 4.8em;
  padding-bottom: 4.8em;
}

.e-content-main.p-t-1 {
  padding-top: 3.2em !important;
}

.e-content-main.p-b-1 {
  padding-bottom: 3.2em !important;
}

.e-content-main.p-t-2 {
  padding-top: 6.4em !important;
}

.e-content-main.p-b-2 {
  padding-bottom: 6.4em !important;
}

.e-content-main.p-t-3 {
  padding-top: 9.6em !important;
}

.e-content-main.p-b-3 {
  padding-bottom: 9.6em !important;
}

.e-content-main.p-t-4 {
  padding-top: 12.8em !important;
}

.e-content-main.p-b-4 {
  padding-bottom: 12.8em !important;
}

.e-content-main.p-t-5 {
  padding-top: 16em !important;
}

.e-content-main.p-b-5 {
  padding-bottom: 16em !important;
}

.e-footer {
  font-size: 0.875em;
}

.e-footer-content {
  padding-top: 2.4em;
  padding-bottom: 2.4em;
  color: rgba(255, 255, 255, 0.65);
  background: #212121;
}

.e-footer-content h1,
.e-footer-content h2,
.e-footer-content h3 {
  color: rgba(255, 255, 255, 0.85);
}

.e-footer-content h4,
.e-footer-content p,
.e-footer-content ul {
  color: rgba(255, 255, 255, 0.65);
}

.e-footer-content a {
  color: inherit;
}

.e-footer-content svg [id*="Filled_Icons"] {
  fill: rgba(255, 255, 255, 0.65);
  opacity: 0.85;
}

.e-footer-content svg [id*="Outline_Icons"] path,
.e-footer-content svg [id*="Outline_Icons"] rect,
.e-footer-content svg [id*="Outline_Icons"] circle,
.e-footer-content svg [id*="Outline_Icons"] ellipse,
.e-footer-content svg [id*="Outline_Icons"] line,
.e-footer-content svg [id*="Outline_Icons"] polyline,
.e-footer-content svg [id*="Outline_Icons"] polygon {
  stroke: rgba(255, 255, 255, 0.65);
  opacity: 0.85;
}

.e-footer-content .form-control {
  background-color: rgba(255, 255, 255, 0.85);
}

.e-footer-copyright {
  font-size: 0.875em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  color: white;
  background: #99BA44;
}

.e-footer-copyright p {
  margin: 0;
  color: white;
}

.e-footer-copyright a {
  color: #fff;
}

.e-footer-copyright a:hover {
  color: white;
}

@media (max-width: 991px) {
  .e-nav-global .collapse {
    display: none;
  }
  .e-nav-global .collapse.show {
    display: block;
  }
  .e-nav-global .dropdown-toggle::after {
    display: none;
  }
  .e-nav-global .dropdown-menu {
    position: static;
    float: none;
    min-width: 0;
    border: 0;
    box-shadow: none;
    background: none;
    padding: 0;
    font-size: inherit;
  }
  .e-nav-global {
    font-size: 0.875em;
    margin-bottom: 3.2em;
    border-top: 1px solid rgba(33, 33, 33, 0.15);
    border-bottom: 1px solid rgba(33, 33, 33, 0.15);
    background: rgba(33, 33, 33, 0.1);
  }
  .e-nav-global .collapsing {
    display: block;
  }
  .e-nav-global .nav {
    flex-direction: column;
    margin: 0 0 0.8em 2em;
  }
  .e-nav-global .nav-link {
    padding: 1.2em 0.1em;
    color: rgba(33, 33, 33, 0.85);
    background: none;
    white-space: normal;
  }
  .e-nav-global .nav-link.is-active {
    color: #99BA44;
  }
  .e-nav-global .nav-link .e-nav-pageicon-dropdown {
    display: none;
  }
  .e-nav-global > .nav {
    margin: 0 2.5%;
    padding: 0 1.6vw;
  }
  .e-nav-global > .nav > .nav-item {
    opacity: 0;
    transform: translateX(-3.2em);
    transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .e-nav-global > .nav > .nav-item:nth-child(1) {
    transition-delay: 0.05s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(2) {
    transition-delay: 0.1s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(3) {
    transition-delay: 0.15s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(4) {
    transition-delay: 0.2s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(5) {
    transition-delay: 0.25s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(6) {
    transition-delay: 0.3s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(7) {
    transition-delay: 0.35s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(8) {
    transition-delay: 0.4s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(9) {
    transition-delay: 0.45s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(10) {
    transition-delay: 0.5s;
  }
  .e-nav-global > .nav > .nav-item + .nav-item {
    border-top: 1px solid rgba(33, 33, 33, 0.15);
  }
  .e-nav-global > .nav > .nav-item > .nav-link {
    text-transform: uppercase;
  }
  .show .e-nav-global > .nav > .nav-item {
    opacity: 1;
    transform: translateX(0);
  }
  .e-nav-global .dropdown-menu .dropdown-item {
    background: none;
  }
  .e-nav-global .e-megamenu-container .close {
    display: none;
  }
  .e-nav-global .e-megamenu .container-fluid {
    padding: 0;
  }
  .e-nav-global .e-megamenu .row {
    margin: 0;
    display: block;
  }
  .e-nav-global .e-megamenu .e-megamenu-content {
    display: none;
  }
}

@media (min-width: 992px) {
  .e-nav-global .collapse {
    display: none;
  }
  .e-nav-global .dropdown.show > .collapse,
  .e-nav-global .e-dropdown-mega.show .collapse {
    display: block;
  }
  .e-nav-global {
    display: flex;
  }
  .e-nav-global > .nav {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  .e-nav-global > .nav > .nav-item {
    display: flex;
    align-items: stretch;
    padding: 0 0.1em;
  }
  .e-nav-global > .nav > .nav-item > .nav-link {
    display: flex;
    align-items: center;
    padding: 1.2em 1.4em;
    color: #212121;
    font-size: 0.75em;
    line-height: 1.25;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
  }
  .e-nav-global > .nav > .nav-item > .nav-link.is-inpath, .e-nav-global > .nav > .nav-item > .nav-link.is-active {
    color: #99ba44;
  }
  .e-nav-global > .nav > .nav-item > .nav-link::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.25em;
    background: currentColor;
    transform: scaleX(0);
  }
  .e-nav-global > .nav > .nav-item > .nav-link:hover::before {
    transform: scaleX(1);
    transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .e-nav-global > .nav > .nav-item > .nav-link.is-inpath::before, .e-nav-global > .nav > .nav-item > .nav-link.is-active::before {
    transform: scaleX(1);
    background: currentColor;
  }
  .e-nav-global > .nav > .nav-item > .nav {
    display: none;
  }
  .e-nav-global .dropdown .dropdown-toggle::before {
    display: block;
  }
  .e-nav-global .dropdown .dropdown-toggle::after {
    display: none;
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    z-index: 1001;
    transform: translate(-50%, 0);
    border-top: 0;
    border-right: 0.75em solid transparent;
    border-bottom: 0.75em solid #fff;
    border-left: 0.75em solid transparent;
    margin: 0 0 0 -0.5em;
  }
  .e-nav-global .dropdown.show .dropdown-toggle::after {
    display: block;
  }
  .e-nav-global .dropdown-menu {
    font-family: "Source Sans Pro", Arial, sans-serif;
    border: 0;
    border-radius: 0 0 0.125em 0.125em;
    margin-left: -1.25em;
  }
  .e-nav-global .dropdown-menu .dropdown-item {
    padding: 0;
  }
  .e-nav-global .dropdown-menu .dropdown-item .nav-link {
    padding: 0.5em 2.5em;
    display: block;
    color: inherit;
  }
  .e-nav-global .e-dropdown-mega {
    position: static;
  }
  .e-nav-global .e-megamenu-container {
    left: 0;
    right: 0;
    max-height: 100vh;
    overflow: auto;
    padding: 0;
  }
  .e-nav-global .e-megamenu {
    padding-right: 1.6vw;
    padding-left: 1.6vw;
    max-width: 2560px;
    margin: 0 auto;
    padding: 2.4em 2.5% 3.2em;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .e-nav-global .e-megamenu {
    padding-right: 3.2vw;
    padding-left: 3.2vw;
  }
}

@media (min-width: 992px) and (min-width: 2560px) {
  .e-nav-global .e-megamenu {
    padding-right: 512px;
    padding-left: 512px;
  }
}

@media (min-width: 992px) {
  .e-nav-global .e-megamenu .e-megamenu-content {
    font-size: 0.875em;
    padding-bottom: 2.4em;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 2.5%;
    padding-left: 2.5%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .e-nav-global .e-megamenu .e-megamenu-content {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-right: 8.33333%;
    margin-left: 8.33333%;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .e-nav-global .e-megamenu .e-megamenu-content {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    margin-right: 16.66667%;
    margin-left: 16.66667%;
  }
}

@media (min-width: 992px) {
  .e-nav-global .e-megamenu .e-megamenu-content h1,
  .e-nav-global .e-megamenu .e-megamenu-content h2,
  .e-nav-global .e-megamenu .e-megamenu-content h3,
  .e-nav-global .e-megamenu .e-megamenu-content h4,
  .e-nav-global .e-megamenu .e-megamenu-content p {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .e-nav-global .e-megamenu .e-megamenu-content a {
    color: inherit;
    text-decoration: underline;
  }
  .e-nav-global .e-megamenu .e-megamenu-content a:hover {
    text-decoration: none;
  }
  .e-nav-global .e-megamenu .e-megamenu-nav {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 2.5%;
    padding-left: 2.5%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .e-nav-global .e-megamenu .e-megamenu-nav > .nav {
    display: flex;
    flex-wrap: wrap;
    margin-right: -2.5%;
    margin-left: -2.5%;
  }
  .e-nav-global .e-megamenu .e-megamenu-item {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 2.5%;
    padding-left: 2.5%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .e-megamenu-header {
    font-size: 1.0625em;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.65);
    margin-top: 1.6em;
    margin-bottom: 0.4em;
    pointer-events: none;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-item {
    line-height: 1.375;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link {
    display: block;
    color: rgba(0, 0, 0, 0.65);
    padding-left: 1.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    position: relative;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link::before {
    content: '–';
    color: rgba(0, 0, 0, 0.65);
    opacity: 0.65;
    position: absolute;
    left: 0;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link::after {
    content: "arrow_forward";
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1em;
    vertical-align: -10%;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    font-smoothing: antialiased;
    color: #2196f3;
    width: 0;
    opacity: 0;
    transform: translateX(-1em);
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link:hover {
    color: #2196f3;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link:hover::after {
    opacity: 1;
    transform: translateX(0.5em);
    transition: opacity 0.1875s cubic-bezier(0.33, 0, 0.2, 1), transform 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .e-nav-global .e-megamenu.e-megamenu-item-count-2 .e-megamenu-item {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .e-nav-global .e-megamenu.e-megamenu-item-count-3 .e-megamenu-item, .e-nav-global .e-megamenu.e-megamenu-item-count-6 .e-megamenu-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .e-nav-global .e-megamenu.e-megamenu-item-count-5 .e-megamenu-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .e-nav-global .e-megamenu.e-megamenu-item-count-7 .e-megamenu-item, .e-nav-global .e-megamenu.e-megamenu-item-count-8 .e-megamenu-item {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}

@media (max-width: 991px) {
  .e-nav-cta {
    font-size: 1.125em;
    margin-bottom: 3em;
  }
  .e-nav-cta .nav-item {
    text-align: center;
    margin: 0 auto 1em;
    opacity: 0;
    transition: all 0.375s cubic-bezier(0.33, 0, 0.2, 1);
  }
  .e-nav-cta .nav-item:nth-child(1) {
    transition-delay: 0.1s;
  }
  .e-nav-cta .nav-item:nth-child(2) {
    transition-delay: 0.2s;
  }
  .e-nav-cta .nav-item:nth-child(3) {
    transition-delay: 0.3s;
  }
  .e-nav-cta .nav-item:nth-child(4) {
    transition-delay: 0.4s;
  }
  .e-nav-cta .nav-item:nth-child(5) {
    transition-delay: 0.5s;
  }
  .e-nav-cta .nav-item:nth-child(6) {
    transition-delay: 0.6s;
  }
  .e-nav-cta .nav-item:nth-child(7) {
    transition-delay: 0.7s;
  }
  .e-nav-cta .nav-item:nth-child(8) {
    transition-delay: 0.8s;
  }
  .e-nav-cta .nav-item:nth-child(9) {
    transition-delay: 0.9s;
  }
  .e-nav-cta .nav-item:nth-child(10) {
    transition-delay: 1s;
  }
  .show .e-nav-cta > .nav > .nav-item {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .e-nav-cta {
    margin-left: 1.25em;
    display: flex;
    align-items: center;
  }
  .e-nav-cta .nav {
    display: flex;
  }
  .e-nav-cta .nav-item + .nav-item {
    margin-left: 1em;
  }
}

@media (max-width: 991px) {
  .e-nav-utilities-primary {
    font-size: 0.875em;
    margin-bottom: 3em;
    border-top: 1px solid rgba(33, 33, 33, 0.15);
    border-bottom: 1px solid rgba(33, 33, 33, 0.15);
    background: rgba(33, 33, 33, 0.05);
    opacity: 0;
  }
  .show .e-nav-utilities-primary {
    transition: all 0.375s cubic-bezier(0.33, 0, 0.2, 1) 0.125s;
    opacity: 1;
  }
  .e-nav-utilities-primary .nav {
    margin: 0 0 0.8em 2em;
  }
  .e-nav-utilities-primary .nav-link {
    display: block;
    padding: 0.8em 0.1em;
    color: rgba(33, 33, 33, 0.85);
    background: none;
    white-space: normal;
  }
  .e-nav-utilities-primary .e-nav-pageicon + .e-nav-pagename {
    margin-left: 0.5em;
  }
  .e-nav-utilities-primary .e-nav-pageicon-dropdown {
    display: none;
  }
  .e-nav-utilities-primary > .nav {
    margin: 0 2.5%;
    padding: 0 1.6vw;
  }
  .e-nav-utilities-primary > .nav > .nav-item + .nav-item {
    border-top: 1px solid rgba(33, 33, 33, 0.15);
  }
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link {
    text-transform: uppercase;
  }
  .e-nav-utilities-primary .e-nav-pageicon-dropdown {
    display: none;
  }
  .e-nav-utilities-primary .dropdown-toggle::after {
    display: none;
  }
}

@media (min-width: 992px) {
  .e-nav-utilities-primary {
    font-size: 0.675em;
  }
  .e-nav-utilities-primary .nav-link {
    color: inherit;
  }
  .e-nav-utilities-primary .e-nav-pageicon-language {
    display: inline-block;
    height: 1.25em;
    width: 1.25em;
    vertical-align: middle;
    border-radius: 50%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transform: translateX(-0.5em) scale(1.25);
  }
  .e-nav-utilities-primary .e-nav-pageicon + .e-nav-pagename {
    margin-left: 0.25em;
  }
  .e-nav-utilities-primary .e-nav-pagename.is-hidden {
    display: none;
  }
  .e-nav-utilities-primary .e-nav-pagename.is-hidden + .e-nav-pageicon-dropdown {
    display: none;
  }
  .e-nav-utilities-primary .e-nav-pageicon-dropdown {
    font-size: 1.25em;
    margin-left: 0.125em;
    vertical-align: -27%;
  }
  .e-nav-utilities-primary > .nav {
    text-align: right;
  }
  .e-nav-utilities-primary > .nav > .nav-item {
    display: inline-block;
  }
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link {
    padding: 0.4em 0 0.6em 1.6em;
    opacity: 0.65;
    transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
  }
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link:hover {
    opacity: 1;
  }
  .e-nav-utilities-primary .dropdown-toggle::after {
    display: none;
  }
  .e-nav-utilities-primary .dropdown-menu {
    font-size: 1em;
    margin-top: -1px;
    min-width: 100%;
  }
  .e-nav-utilities-primary .dropdown-menu .nav-link {
    padding: 0.4em 1.6em;
  }
  .e-nav-utilities-primary .nav-item:last-child .dropdown-menu {
    left: auto;
    right: 0;
  }
}

@media (max-width: 991px) {
  .e-nav .e-nav-utilities-secondary {
    font-size: 0.875em;
    margin-bottom: 3em;
    border-top: 1px solid rgba(33, 33, 33, 0.15);
    border-bottom: 1px solid rgba(33, 33, 33, 0.15);
    background: rgba(33, 33, 33, 0.05);
    opacity: 0;
  }
  .show .e-nav .e-nav-utilities-secondary {
    transition: all 0.375s cubic-bezier(0.33, 0, 0.2, 1) 0.125s;
    opacity: 1;
  }
  .e-nav .e-nav-utilities-secondary > .nav {
    margin: 0 2.5%;
    padding: 0 1.6vw;
  }
  .e-nav .e-nav-utilities-secondary > .nav > .nav-item + .nav-item {
    border-top: 1px solid rgba(33, 33, 33, 0.15);
  }
  .e-nav .e-nav-utilities-secondary > .nav > .nav-item > .nav-link {
    display: block;
    padding: 0.75em 0.125em;
    color: rgba(33, 33, 33, 0.85);
    background: none;
    white-space: normal;
    text-transform: uppercase;
  }
}

@media (max-width: 991px) {
  .e-nav-local {
    display: none !important;
  }
}

.e-nav-local .e-nav-local-container {
  font-size: 0.875em;
}

.e-nav-local .nav-link:not(.btn) {
  color: inherit;
}

.e-nav-local-horizontal {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.e-nav-local-horizontal:first-child {
  display: inline-block;
  width: 100%;
}

.e-nav-local-horizontal.is-sticky {
  position: sticky;
  top: -1px;
  background: #fff;
}

.e-nav-local-horizontal.is-sticky.is-stuck {
  border: 0;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.e-nav-local-horizontal.is-absolute {
  color: white;
  position: absolute;
  top: auto;
  width: 100%;
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.65);
}

.e-nav-local-horizontal.is-absolute::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: -50%;
  left: 0;
  opacity: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0, transparent 100%);
  transition: opacity, bottom 0.375s cubic-bezier(0.33, 0, 0.2, 1) 0.375s;
}

.e-nav-local-horizontal.is-absolute.is-sticky {
  background: none;
}

.e-nav-local-horizontal.is-absolute.is-sticky::after {
  content: "";
  background: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transform: scaleX(0);
  transition: opacity, transform 0.375s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: opacity, transform;
}

.e-nav-local-horizontal.is-absolute.is-sticky.is-stuck {
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.e-nav-local-horizontal.is-absolute.is-sticky.is-stuck::before {
  bottom: 0;
  opacity: 0;
}

.e-nav-local-horizontal.is-absolute.is-sticky.is-stuck::after {
  opacity: 1;
  transform: scaleX(1);
}

.e-nav-local-horizontal .e-nav-local-container {
  position: relative;
  z-index: 1;
}

.e-nav-local-horizontal .e-nav-local-heading {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 auto 0 0;
}

.e-nav-local-horizontal .e-nav-local-heading h2 {
  color: inherit;
  font-size: 1.375em;
  font-weight: 700;
  margin: 0;
}

.e-nav-local-horizontal .nav-link.is-active, .e-nav-local-horizontal .nav-link.is-inpath {
  font-weight: 700;
}

.e-nav-local-horizontal .e-nav-local-container > .nav > .nav-item {
  display: flex;
  align-items: center;
}

.e-nav-local-horizontal .e-nav-local-container > .nav > .nav-item:first-child {
  margin-left: -1.6em;
}

.e-nav-local-horizontal .e-nav-local-container > .nav > .nav-item:last-child:not(.is-cta) {
  margin-right: -1.6em;
}

.e-nav-local-horizontal .e-nav-local-container > .nav > .nav-item > .nav-link {
  display: flex;
  align-items: center;
  line-height: 1.09375;
  padding: 1.4em 1.6em 1.2em;
}

.e-nav-local-horizontal .e-nav-local-container > .nav > .nav-item > .nav-link.btn {
  padding: 0.8em 1.6em;
}

.e-nav-local-horizontal .dropdown-toggle::after {
  display: none;
}

.e-nav-local-horizontal .dropdown-menu {
  font-size: 1em;
}

.e-nav-local-horizontal .dropdown-menu .nav-link {
  padding: 0.8em 1.8em;
}

.e-nav-local-vertical {
  position: absolute;
  top: auto;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}

.e-nav-local-vertical.is-sticky.is-stuck {
  position: fixed;
  top: 1.6em;
}

.e-nav-local-vertical.is-sticky.is-stuck .e-nav-local-container, .e-nav-local-vertical.is-absolute .e-nav-local-container {
  margin: 0 -1.6em;
  padding: 2.4em 1.6em;
  background: rgba(255, 255, 255, 0.85);
}

.e-nav-local-vertical .e-nav-local-container {
  pointer-events: auto;
}

.e-nav-local-vertical .e-nav-local-heading {
  padding: 0;
  margin: 0;
}

.e-nav-local-vertical .nav-link {
  color: inherit;
  padding: 0.5em 0;
  line-height: 1.25;
}

.e-nav-local-vertical .nav-link:hover {
  color: #99BA44;
}

.e-nav-local-vertical .nav-link.is-active {
  font-weight: 700;
  color: #99BA44;
}

.e-nav-local-vertical .nav .nav {
  margin: 0.4em 0 1.6em 1.6em;
}

.e-nav-local-vertical .nav .nav .nav-link {
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.e-nav-local-vertical .nav .nav .nav-link:hover:not(.is-active) {
  transition: transform 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateX(0.8em);
}

.e-nav-local-vertical .nav .nav .nav-link.is-active {
  position: relative;
  padding-left: 0.8em;
}

.e-nav-local-vertical .nav .nav .nav-link.is-active::before {
  content: "";
  position: absolute;
  top: 0.2em;
  left: 0;
  bottom: 0.2em;
  width: 0.3em;
  background: #99BA44;
}

.e-nav-local-vertical .e-nav-local-container > .nav {
  font-size: 0.875em;
}

.e-nav-local-vertical .e-nav-local-container > .nav > .nav-item {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.e-nav-local-vertical .e-nav-local-container > .nav > .nav-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.e-nav-local-vertical .e-nav-local-container > .nav > .nav-item > .nav-link {
  font-weight: 700;
  padding: 1.08em 0;
}

.e-nav-local-vertical .e-nav-local-container > .nav > .nav-item > .nav {
  margin-top: 0;
}

.e-section > .container-fluid > .row > div > .row > .p-width-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.5%;
  padding-left: 2.5%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  .e-section > .container-fluid > .row > .col-lg-12 > .row > .p-width-auto {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-right: 8.33333%;
    margin-left: 8.33333%;
  }
}

@media (min-width: 992px) {
  .e-section > .container-fluid > .row > .col-lg-12 > .row > .p-width-auto {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    margin-right: 16.66667%;
    margin-left: 16.66667%;
  }
}

.p-txt-animate h1,
.p-txt-animate h2,
.p-txt-animate h3,
.p-txt-animate h4,
.p-txt-animate ul,
.p-txt-animate p {
  opacity: 0;
  transform: translateY(1.5em);
}

.animate-in-on-load .p-txt-animate h1,
.animate-in-on-load .p-txt-animate h2,
.animate-in-on-load .p-txt-animate h3,
.animate-in-on-load .p-txt-animate h4,
.animate-in-on-load .p-txt-animate ul,
.animate-in-on-load .p-txt-animate p,
.animate-in-on-scroll .p-txt-animate h1,
.animate-in-on-scroll .p-txt-animate h2,
.animate-in-on-scroll .p-txt-animate h3,
.animate-in-on-scroll .p-txt-animate h4,
.animate-in-on-scroll .p-txt-animate ul,
.animate-in-on-scroll .p-txt-animate p {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s cubic-bezier(0.33, 0, 0.2, 1), transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.animate-in-on-load .p-txt-animate h1:nth-child(1),
.animate-in-on-load .p-txt-animate h2:nth-child(1),
.animate-in-on-load .p-txt-animate h3:nth-child(1),
.animate-in-on-load .p-txt-animate h4:nth-child(1),
.animate-in-on-load .p-txt-animate ul:nth-child(1),
.animate-in-on-load .p-txt-animate p:nth-child(1),
.animate-in-on-scroll .p-txt-animate h1:nth-child(1),
.animate-in-on-scroll .p-txt-animate h2:nth-child(1),
.animate-in-on-scroll .p-txt-animate h3:nth-child(1),
.animate-in-on-scroll .p-txt-animate h4:nth-child(1),
.animate-in-on-scroll .p-txt-animate ul:nth-child(1),
.animate-in-on-scroll .p-txt-animate p:nth-child(1) {
  transition-delay: 0.5s;
}

.animate-in-on-load .p-txt-animate h1:nth-child(2),
.animate-in-on-load .p-txt-animate h2:nth-child(2),
.animate-in-on-load .p-txt-animate h3:nth-child(2),
.animate-in-on-load .p-txt-animate h4:nth-child(2),
.animate-in-on-load .p-txt-animate ul:nth-child(2),
.animate-in-on-load .p-txt-animate p:nth-child(2),
.animate-in-on-scroll .p-txt-animate h1:nth-child(2),
.animate-in-on-scroll .p-txt-animate h2:nth-child(2),
.animate-in-on-scroll .p-txt-animate h3:nth-child(2),
.animate-in-on-scroll .p-txt-animate h4:nth-child(2),
.animate-in-on-scroll .p-txt-animate ul:nth-child(2),
.animate-in-on-scroll .p-txt-animate p:nth-child(2) {
  transition-delay: 1s;
}

.animate-in-on-load .p-txt-animate h1:nth-child(3),
.animate-in-on-load .p-txt-animate h2:nth-child(3),
.animate-in-on-load .p-txt-animate h3:nth-child(3),
.animate-in-on-load .p-txt-animate h4:nth-child(3),
.animate-in-on-load .p-txt-animate ul:nth-child(3),
.animate-in-on-load .p-txt-animate p:nth-child(3),
.animate-in-on-scroll .p-txt-animate h1:nth-child(3),
.animate-in-on-scroll .p-txt-animate h2:nth-child(3),
.animate-in-on-scroll .p-txt-animate h3:nth-child(3),
.animate-in-on-scroll .p-txt-animate h4:nth-child(3),
.animate-in-on-scroll .p-txt-animate ul:nth-child(3),
.animate-in-on-scroll .p-txt-animate p:nth-child(3) {
  transition-delay: 1.5s;
}

.animate-in-on-load .p-txt-animate h1:nth-child(4),
.animate-in-on-load .p-txt-animate h2:nth-child(4),
.animate-in-on-load .p-txt-animate h3:nth-child(4),
.animate-in-on-load .p-txt-animate h4:nth-child(4),
.animate-in-on-load .p-txt-animate ul:nth-child(4),
.animate-in-on-load .p-txt-animate p:nth-child(4),
.animate-in-on-scroll .p-txt-animate h1:nth-child(4),
.animate-in-on-scroll .p-txt-animate h2:nth-child(4),
.animate-in-on-scroll .p-txt-animate h3:nth-child(4),
.animate-in-on-scroll .p-txt-animate h4:nth-child(4),
.animate-in-on-scroll .p-txt-animate ul:nth-child(4),
.animate-in-on-scroll .p-txt-animate p:nth-child(4) {
  transition-delay: 2s;
}

.animate-in-on-load .p-txt-animate h1:nth-child(5),
.animate-in-on-load .p-txt-animate h2:nth-child(5),
.animate-in-on-load .p-txt-animate h3:nth-child(5),
.animate-in-on-load .p-txt-animate h4:nth-child(5),
.animate-in-on-load .p-txt-animate ul:nth-child(5),
.animate-in-on-load .p-txt-animate p:nth-child(5),
.animate-in-on-scroll .p-txt-animate h1:nth-child(5),
.animate-in-on-scroll .p-txt-animate h2:nth-child(5),
.animate-in-on-scroll .p-txt-animate h3:nth-child(5),
.animate-in-on-scroll .p-txt-animate h4:nth-child(5),
.animate-in-on-scroll .p-txt-animate ul:nth-child(5),
.animate-in-on-scroll .p-txt-animate p:nth-child(5) {
  transition-delay: 2.5s;
}

.animate-in-on-load .p-txt-animate h1:nth-child(6),
.animate-in-on-load .p-txt-animate h2:nth-child(6),
.animate-in-on-load .p-txt-animate h3:nth-child(6),
.animate-in-on-load .p-txt-animate h4:nth-child(6),
.animate-in-on-load .p-txt-animate ul:nth-child(6),
.animate-in-on-load .p-txt-animate p:nth-child(6),
.animate-in-on-scroll .p-txt-animate h1:nth-child(6),
.animate-in-on-scroll .p-txt-animate h2:nth-child(6),
.animate-in-on-scroll .p-txt-animate h3:nth-child(6),
.animate-in-on-scroll .p-txt-animate h4:nth-child(6),
.animate-in-on-scroll .p-txt-animate ul:nth-child(6),
.animate-in-on-scroll .p-txt-animate p:nth-child(6) {
  transition-delay: 3s;
}

.animate-in-on-load .p-txt-animate h1:nth-child(7),
.animate-in-on-load .p-txt-animate h2:nth-child(7),
.animate-in-on-load .p-txt-animate h3:nth-child(7),
.animate-in-on-load .p-txt-animate h4:nth-child(7),
.animate-in-on-load .p-txt-animate ul:nth-child(7),
.animate-in-on-load .p-txt-animate p:nth-child(7),
.animate-in-on-scroll .p-txt-animate h1:nth-child(7),
.animate-in-on-scroll .p-txt-animate h2:nth-child(7),
.animate-in-on-scroll .p-txt-animate h3:nth-child(7),
.animate-in-on-scroll .p-txt-animate h4:nth-child(7),
.animate-in-on-scroll .p-txt-animate ul:nth-child(7),
.animate-in-on-scroll .p-txt-animate p:nth-child(7) {
  transition-delay: 3.5s;
}

.animate-in-on-load .p-txt-animate h1:nth-child(8),
.animate-in-on-load .p-txt-animate h2:nth-child(8),
.animate-in-on-load .p-txt-animate h3:nth-child(8),
.animate-in-on-load .p-txt-animate h4:nth-child(8),
.animate-in-on-load .p-txt-animate ul:nth-child(8),
.animate-in-on-load .p-txt-animate p:nth-child(8),
.animate-in-on-scroll .p-txt-animate h1:nth-child(8),
.animate-in-on-scroll .p-txt-animate h2:nth-child(8),
.animate-in-on-scroll .p-txt-animate h3:nth-child(8),
.animate-in-on-scroll .p-txt-animate h4:nth-child(8),
.animate-in-on-scroll .p-txt-animate ul:nth-child(8),
.animate-in-on-scroll .p-txt-animate p:nth-child(8) {
  transition-delay: 4s;
}

.animate-in-on-load .p-txt-animate h1:nth-child(9),
.animate-in-on-load .p-txt-animate h2:nth-child(9),
.animate-in-on-load .p-txt-animate h3:nth-child(9),
.animate-in-on-load .p-txt-animate h4:nth-child(9),
.animate-in-on-load .p-txt-animate ul:nth-child(9),
.animate-in-on-load .p-txt-animate p:nth-child(9),
.animate-in-on-scroll .p-txt-animate h1:nth-child(9),
.animate-in-on-scroll .p-txt-animate h2:nth-child(9),
.animate-in-on-scroll .p-txt-animate h3:nth-child(9),
.animate-in-on-scroll .p-txt-animate h4:nth-child(9),
.animate-in-on-scroll .p-txt-animate ul:nth-child(9),
.animate-in-on-scroll .p-txt-animate p:nth-child(9) {
  transition-delay: 4.5s;
}

.animate-in-on-load .p-txt-animate h1:nth-child(10),
.animate-in-on-load .p-txt-animate h2:nth-child(10),
.animate-in-on-load .p-txt-animate h3:nth-child(10),
.animate-in-on-load .p-txt-animate h4:nth-child(10),
.animate-in-on-load .p-txt-animate ul:nth-child(10),
.animate-in-on-load .p-txt-animate p:nth-child(10),
.animate-in-on-scroll .p-txt-animate h1:nth-child(10),
.animate-in-on-scroll .p-txt-animate h2:nth-child(10),
.animate-in-on-scroll .p-txt-animate h3:nth-child(10),
.animate-in-on-scroll .p-txt-animate h4:nth-child(10),
.animate-in-on-scroll .p-txt-animate ul:nth-child(10),
.animate-in-on-scroll .p-txt-animate p:nth-child(10) {
  transition-delay: 5s;
}

.p-img-container-parallax {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: translateZ(0);
}

.p-img-container-parallax .p-img-parallax {
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  display: block;
}

@media (max-width: 575px) {
  .p-img-container-parallax .p-img-parallax {
    max-width: none;
    width: 200%;
    left: -50%;
  }
}

.p-img-container-parallax .p-img-parallax.is-loaded {
  transition: opacity 0.75s cubic-bezier(0.33, 0, 0.2, 1);
  opacity: 1;
}

.p-img {
  max-width: 100%;
  height: auto;
}

.p-img-shadow-none {
  box-shadow: none;
}

.p-img-shadow-small {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-img-shadow-medium {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.p-img-shadow-large {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.p-img-border-none {
  border: 0;
}

.p-img-border-thin, .p-img-border-double {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.p-img-border-thick {
  border: 0.375em solid rgba(0, 0, 0, 0.15);
}

.p-img-border-double {
  padding: 0.25em;
}

.p-img-link {
  text-decoration: none;
}

.p-imagetext.mb-0 :last-child {
  margin-bottom: 0;
}

.p-imagetext .p-img-container .col-pos-center {
  margin-left: auto;
  margin-right: auto;
}

.p-imagetext .p-img-container .col-pos-right {
  margin-left: auto;
}

.p-imagetext .p-img-container .p-img[src$="svg"] {
  width: 100%;
}

.p-icontext {
  margin-bottom: 1.6em;
}

.p-icontext :last-child {
  margin-bottom: 0;
}

.p-icontext .p-img {
  margin-bottom: 0.8em;
  position: relative;
  padding-top: 100%;
}

.p-icontext .p-img svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 100%;
}

.p-icontext-white svg [id*="Filled_Icon"] {
  fill: #fff;
}

.p-icontext-white svg [id*="Outline_Icon"] path,
.p-icontext-white svg [id*="Outline_Icon"] rect,
.p-icontext-white svg [id*="Outline_Icon"] circle,
.p-icontext-white svg [id*="Outline_Icon"] ellipse,
.p-icontext-white svg [id*="Outline_Icon"] line,
.p-icontext-white svg [id*="Outline_Icon"] polyline,
.p-icontext-white svg [id*="Outline_Icon"] polygon {
  stroke: #fff;
}

.p-icontext-black svg [id*="Filled_Icon"] {
  fill: #000;
}

.p-icontext-black svg [id*="Outline_Icon"] path,
.p-icontext-black svg [id*="Outline_Icon"] rect,
.p-icontext-black svg [id*="Outline_Icon"] circle,
.p-icontext-black svg [id*="Outline_Icon"] ellipse,
.p-icontext-black svg [id*="Outline_Icon"] line,
.p-icontext-black svg [id*="Outline_Icon"] polyline,
.p-icontext-black svg [id*="Outline_Icon"] polygon {
  stroke: #000;
}

.p-icontext-light svg [id*="Filled_Icon"] {
  fill: #bdbdbd;
}

.p-icontext-light svg [id*="Outline_Icon"] path,
.p-icontext-light svg [id*="Outline_Icon"] rect,
.p-icontext-light svg [id*="Outline_Icon"] circle,
.p-icontext-light svg [id*="Outline_Icon"] ellipse,
.p-icontext-light svg [id*="Outline_Icon"] line,
.p-icontext-light svg [id*="Outline_Icon"] polyline,
.p-icontext-light svg [id*="Outline_Icon"] polygon {
  stroke: #bdbdbd;
}

.p-icontext-dark svg [id*="Filled_Icon"] {
  fill: #757575;
}

.p-icontext-dark svg [id*="Outline_Icon"] path,
.p-icontext-dark svg [id*="Outline_Icon"] rect,
.p-icontext-dark svg [id*="Outline_Icon"] circle,
.p-icontext-dark svg [id*="Outline_Icon"] ellipse,
.p-icontext-dark svg [id*="Outline_Icon"] line,
.p-icontext-dark svg [id*="Outline_Icon"] polyline,
.p-icontext-dark svg [id*="Outline_Icon"] polygon {
  stroke: #757575;
}

.p-icontext-brand-primary svg [id*="Filled_Icon"] {
  fill: #99BA44;
}

.p-icontext-brand-primary svg [id*="Outline_Icon"] path,
.p-icontext-brand-primary svg [id*="Outline_Icon"] rect,
.p-icontext-brand-primary svg [id*="Outline_Icon"] circle,
.p-icontext-brand-primary svg [id*="Outline_Icon"] ellipse,
.p-icontext-brand-primary svg [id*="Outline_Icon"] line,
.p-icontext-brand-primary svg [id*="Outline_Icon"] polyline,
.p-icontext-brand-primary svg [id*="Outline_Icon"] polygon {
  stroke: #99BA44;
}

.p-icontext-brand-secondary svg [id*="Filled_Icon"] {
  fill: #eaf1d9;
}

.p-icontext-brand-secondary svg [id*="Outline_Icon"] path,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] rect,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] circle,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] ellipse,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] line,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] polyline,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] polygon {
  stroke: #eaf1d9;
}

.p-icontext-brand-variation-one svg [id*="Filled_Icon"] {
  fill: #99BA44;
}

.p-icontext-brand-variation-one svg [id*="Outline_Icon"] path,
.p-icontext-brand-variation-one svg [id*="Outline_Icon"] rect,
.p-icontext-brand-variation-one svg [id*="Outline_Icon"] circle,
.p-icontext-brand-variation-one svg [id*="Outline_Icon"] ellipse,
.p-icontext-brand-variation-one svg [id*="Outline_Icon"] line,
.p-icontext-brand-variation-one svg [id*="Outline_Icon"] polyline,
.p-icontext-brand-variation-one svg [id*="Outline_Icon"] polygon {
  stroke: #99BA44;
}

.p-icontext-brand-variation-two svg [id*="Filled_Icon"] {
  fill: #e9e9e7;
}

.p-icontext-brand-variation-two svg [id*="Outline_Icon"] path,
.p-icontext-brand-variation-two svg [id*="Outline_Icon"] rect,
.p-icontext-brand-variation-two svg [id*="Outline_Icon"] circle,
.p-icontext-brand-variation-two svg [id*="Outline_Icon"] ellipse,
.p-icontext-brand-variation-two svg [id*="Outline_Icon"] line,
.p-icontext-brand-variation-two svg [id*="Outline_Icon"] polyline,
.p-icontext-brand-variation-two svg [id*="Outline_Icon"] polygon {
  stroke: #e9e9e7;
}

.p-icontext-auto svg [id*="Filled_Icon"],
.p-icontext .p-img[style] svg [id*="Filled_Icon"] {
  fill: currentColor;
}

.p-icontext-auto svg [id*="Outline_Icon"] path,
.p-icontext-auto svg [id*="Outline_Icon"] rect,
.p-icontext-auto svg [id*="Outline_Icon"] circle,
.p-icontext-auto svg [id*="Outline_Icon"] ellipse,
.p-icontext-auto svg [id*="Outline_Icon"] line,
.p-icontext-auto svg [id*="Outline_Icon"] polyline,
.p-icontext-auto svg [id*="Outline_Icon"] polygon,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] path,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] rect,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] circle,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] ellipse,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] line,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] polyline,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] polygon {
  stroke: currentColor;
}

.p-banner {
  padding-right: 1.6vw;
  padding-left: 1.6vw;
  position: relative;
  margin-bottom: 1.6em;
  display: flex;
}

@media (min-width: 1200px) {
  .p-banner {
    padding-right: 3.2vw;
    padding-left: 3.2vw;
  }
}

@media (min-width: 2560px) {
  .p-banner {
    padding-right: 512px;
    padding-left: 512px;
  }
}

.p-banner.pt-1 {
  padding-top: 1.6em !important;
}

.p-banner.pb-1 {
  padding-bottom: 1.6em !important;
}

.p-banner.pt-2 {
  padding-top: 3.2em !important;
}

.p-banner.pb-2 {
  padding-bottom: 3.2em !important;
}

.p-banner.pt-3 {
  padding-top: 4.8em !important;
}

.p-banner.pb-3 {
  padding-bottom: 4.8em !important;
}

.p-banner.pt-4 {
  padding-top: 6.4em !important;
}

.p-banner.pb-4 {
  padding-bottom: 6.4em !important;
}

.p-banner.pt-5 {
  padding-top: 8em !important;
}

.p-banner.pb-5 {
  padding-bottom: 8em !important;
}

.p-banner .p-txt-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 767px) {
  .p-banner .p-txt-container::before {
    background: rgba(0, 0, 0, 0.45) !important;
  }
}

.p-banner .p-txt-wrapper {
  padding-top: 1.6em;
  padding-bottom: 1.6em;
}

@media (min-width: 768px) {
  .p-banner .p-txt-wrapper {
    padding-top: 1.6em;
    padding-bottom: 1.6em;
  }
}

@media (min-width: 992px) {
  .p-banner .p-txt-wrapper {
    padding-top: 2.4em;
    padding-bottom: 2.4em;
  }
}

.p-banner h1,
.p-banner h2,
.p-banner h3,
.p-banner h4,
.p-banner p {
  color: white;
  margin-bottom: 0.5rem;
}

.p-banner h1 a:not(.btn),
.p-banner h2 a:not(.btn),
.p-banner h3 a:not(.btn),
.p-banner h4 a:not(.btn),
.p-banner p a:not(.btn) {
  color: inherit;
}

.p-banner h1:last-child,
.p-banner h2:last-child,
.p-banner h3:last-child,
.p-banner h4:last-child,
.p-banner p:last-child {
  margin-bottom: 0;
}

.p-banner h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-banner p {
  color: rgba(255, 255, 255, 0.75);
}

.p-banner p a:not(.btn) {
  text-decoration: underline;
}

.p-banner .p-img-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}

.p-banner-auto .p-txt-wrapper {
  padding-top: 3.2em;
  padding-bottom: 3.2em;
}

.p-banner-txt-left-top {
  padding-bottom: 3.2em;
}

.p-banner-txt-left-top::before {
  background: linear-gradient(157deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-txt-left-middle {
  padding-top: 3.2em;
  padding-bottom: 3.2em;
}

.p-banner-txt-left-middle::before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-txt-left-bottom {
  padding-top: 3.2em;
}

.p-banner-txt-left-bottom::before {
  background: linear-gradient(22deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-txt-center-top {
  padding-bottom: 3.2em;
}

.p-banner-txt-center-top::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, transparent 65%);
}

.p-banner-txt-center-middle {
  padding-top: 3.2em;
  padding-bottom: 3.2em;
}

.p-banner-txt-center-middle::before {
  background: rgba(0, 0, 0, 0.55);
}

.p-banner-txt-center-bottom {
  padding-top: 3.2em;
}

.p-banner-txt-center-bottom::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, transparent 65%);
}

.p-banner-txt-right-top {
  padding-bottom: 3.2em;
}

.p-banner-txt-right-top::before {
  background: linear-gradient(202deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-txt-right-middle {
  padding-top: 3.2em;
  padding-bottom: 3.2em;
}

.p-banner-txt-right-middle::before {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-txt-right-bottom {
  padding-top: 3.2em;
}

.p-banner-txt-right-bottom::before {
  background: linear-gradient(338deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-gradient-disabled .p-txt-container::after {
  background: none;
}

.p-banner-animation-image-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: translateZ(0);
}

.p-banner-animation-image-container .p-banner-animation-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 0;
  will-change: transform;
}

.p-banner-animation-image-container .p-banner-animation-image.animate-in {
  opacity: 1;
  transition: opacity 0.9375s cubic-bezier(0.33, 0, 0.2, 1);
  animation: zoom 9.375s linear forwards;
}

.p-banner-animation-image-container .p-banner-animation-image.animate-out {
  opacity: 0;
  transition: opacity 1.875s cubic-bezier(0.33, 0, 0.2, 1);
  transform: scale(1.25, 1.25);
}

.p-banner-next-section-link {
  color: white;
  font-size: 3em;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.p-banner-next-section-link .p-banner-next-section-link-icon {
  animation: bounce 5s infinite;
  animation-fill-mode: both;
  transform-origin: center bottom;
}

.p-banner-next-section-link:hover, .p-banner-next-section-link:active {
  color: white;
}

.p-banner-img-left-top {
  background-position: 0 0;
}

.p-banner-img-left-middle {
  background-position: 0 50%;
}

.p-banner-img-left-bottom {
  background-position: 0 100%;
}

.p-banner-img-center-top {
  background-position: 50% 0;
}

.p-banner-img-center-middle {
  background-position: 50% 50%;
}

.p-banner-img-center-bottom {
  background-position: 50% 100%;
}

.p-banner-img-right-top {
  background-position: 100% 0;
}

.p-banner-img-right-middle {
  background-position: 100% 50%;
}

.p-banner-img-right-bottom {
  background-position: 100% 100%;
}

.p-mosaic .p-img-container {
  height: 50vw;
  max-height: 90vh;
}

@media (max-width: 767px) {
  .p-mosaic .p-img-container {
    height: 75vw;
  }
}

@media (min-width: 2561px) {
  .p-mosaic .p-img-container {
    height: 1280px;
  }
}

@media (max-width: 767px) {
  .p-mosaic .p-img-container .p-img:not(.p-img-contain) {
    right: 10%;
  }
  .p-mosaic .p-img-container.order-md-last .p-img:not(.p-img-contain) {
    left: 10%;
    right: 0;
  }
}

.p-mosaic .p-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.p-mosaic .p-img-left-top {
  background-position: 0 0;
}

.p-mosaic .p-img-left-middle {
  background-position: 0 50%;
}

.p-mosaic .p-img-left-bottom {
  background-position: 0 100%;
}

.p-mosaic .p-img-center-top {
  background-position: 50% 0;
}

.p-mosaic .p-img-center-middle {
  background-position: 50% 50%;
}

.p-mosaic .p-img-center-bottom {
  background-position: 50% 100%;
}

.p-mosaic .p-img-right-top {
  background-position: 100% 0;
}

.p-mosaic .p-img-right-middle {
  background-position: 100% 50%;
}

.p-mosaic .p-img-right-bottom {
  background-position: 100% 100%;
}

.p-mosaic .p-img-pos-t-1 {
  top: 2.5%;
}

.p-mosaic .p-img-pos-r-1 {
  right: 2.5%;
}

.p-mosaic .p-img-pos-b-1 {
  bottom: 2.5%;
}

.p-mosaic .p-img-pos-l-1 {
  left: 2.5%;
}

.p-mosaic .p-img-pos-t-2 {
  top: 5%;
}

.p-mosaic .p-img-pos-r-2 {
  right: 5%;
}

.p-mosaic .p-img-pos-b-2 {
  bottom: 5%;
}

.p-mosaic .p-img-pos-l-2 {
  left: 5%;
}

.p-mosaic .p-img-pos-t-3 {
  top: 7.5%;
}

.p-mosaic .p-img-pos-r-3 {
  right: 7.5%;
}

.p-mosaic .p-img-pos-b-3 {
  bottom: 7.5%;
}

.p-mosaic .p-img-pos-l-3 {
  left: 7.5%;
}

.p-mosaic .p-img-pos-t-4 {
  top: 10%;
}

.p-mosaic .p-img-pos-r-4 {
  right: 10%;
}

.p-mosaic .p-img-pos-b-4 {
  bottom: 10%;
}

.p-mosaic .p-img-pos-l-4 {
  left: 10%;
}

.p-mosaic .p-img-pos-t-5 {
  top: 12.5%;
}

.p-mosaic .p-img-pos-r-5 {
  right: 12.5%;
}

.p-mosaic .p-img-pos-b-5 {
  bottom: 12.5%;
}

.p-mosaic .p-img-pos-l-5 {
  left: 12.5%;
}

.p-mosaic .p-txt-container {
  padding-top: 2.4em;
  padding-bottom: 2.4em;
  padding-right: 10%;
  padding-left: 10%;
}

.p-mosaic .p-txt-container :last-child {
  margin-bottom: 0;
}

.p-mosaic-img-contain .p-img {
  background-size: contain;
}

@media (min-width: 768px) {
  .p-mosaic-img-contain.p-mosaic-img-left .p-txt-container {
    padding-left: 5%;
  }
  .p-mosaic-img-contain.p-mosaic-img-right .p-txt-container {
    padding-right: 5%;
  }
}

.p-mosaic-bg-white {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.p-mosaic-bg-white h1,
.p-mosaic-bg-white .h1,
.p-mosaic-bg-white h2,
.p-mosaic-bg-white .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-mosaic-bg-white h3,
.p-mosaic-bg-white .h3,
.p-mosaic-bg-white h4,
.p-mosaic-bg-white .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-mosaic-bg-white p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-mosaic-bg-white p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-mosaic-bg-black {
  color: rgba(255, 255, 255, 0.75);
  background-color: #000;
}

.p-mosaic-bg-black h1,
.p-mosaic-bg-black .h1,
.p-mosaic-bg-black h2,
.p-mosaic-bg-black .h2 {
  color: white;
}

.p-mosaic-bg-black h3,
.p-mosaic-bg-black .h3,
.p-mosaic-bg-black h4,
.p-mosaic-bg-black .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-mosaic-bg-black p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-mosaic-bg-black p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-mosaic-bg-black p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-mosaic-bg-light {
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.15);
  background: #f5f5f5;
}

.p-mosaic-bg-light h1,
.p-mosaic-bg-light .h1,
.p-mosaic-bg-light h2,
.p-mosaic-bg-light .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-mosaic-bg-light h3,
.p-mosaic-bg-light .h3,
.p-mosaic-bg-light h4,
.p-mosaic-bg-light .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-mosaic-bg-light p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-mosaic-bg-light p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-mosaic-bg-dark {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #424242;
}

.p-mosaic-bg-dark h1,
.p-mosaic-bg-dark .h1,
.p-mosaic-bg-dark h2,
.p-mosaic-bg-dark .h2 {
  color: white;
}

.p-mosaic-bg-dark h3,
.p-mosaic-bg-dark .h3,
.p-mosaic-bg-dark h4,
.p-mosaic-bg-dark .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-mosaic-bg-dark p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-mosaic-bg-dark p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-mosaic-bg-dark p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-mosaic-bg-brand-primary {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #99BA44;
}

.p-mosaic-bg-brand-primary h1,
.p-mosaic-bg-brand-primary .h1,
.p-mosaic-bg-brand-primary h2,
.p-mosaic-bg-brand-primary .h2 {
  color: white;
}

.p-mosaic-bg-brand-primary h3,
.p-mosaic-bg-brand-primary .h3,
.p-mosaic-bg-brand-primary h4,
.p-mosaic-bg-brand-primary .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-mosaic-bg-brand-primary p a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-mosaic-bg-brand-primary p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-mosaic-bg-brand-primary p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-mosaic-bg-brand-primary .p .p-txt-container .table tbody th,
.p-mosaic-bg-brand-primary .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-mosaic-bg-brand-primary hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-mosaic-bg-brand-secondary {
  color: rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #eaf1d9;
}

.p-mosaic-bg-brand-secondary h1,
.p-mosaic-bg-brand-secondary .h1,
.p-mosaic-bg-brand-secondary h2,
.p-mosaic-bg-brand-secondary .h2 {
  color: black;
}

.p-mosaic-bg-brand-secondary h3,
.p-mosaic-bg-brand-secondary .h3 h4,
.p-mosaic-bg-brand-secondary .h4 {
  color: rgba(0, 0, 0, 0.75);
}

.p-mosaic-bg-brand-secondary p a:not(.btn) {
  color: black;
  text-decoration: underline;
}

.p-mosaic-bg-brand-secondary p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-mosaic-bg-brand-secondary p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-mosaic-bg-brand-secondary .p .p-txt-container .table tbody th,
.p-mosaic-bg-brand-secondary .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-mosaic-bg-brand-secondary hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-mosaic-bg-brand-variation-one {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #99BA44;
}

.p-mosaic-bg-brand-variation-one h1,
.p-mosaic-bg-brand-variation-one .h1,
.p-mosaic-bg-brand-variation-one h2,
.p-mosaic-bg-brand-variation-one .h2 {
  color: white;
}

.p-mosaic-bg-brand-variation-one h3,
.p-mosaic-bg-brand-variation-one .h3,
.p-mosaic-bg-brand-variation-one h4,
.p-mosaic-bg-brand-variation-one .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-mosaic-bg-brand-variation-one p a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-mosaic-bg-brand-variation-one p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-mosaic-bg-brand-variation-one p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-mosaic-bg-brand-variation-one .p .p-txt-container .table tbody th,
.p-mosaic-bg-brand-variation-one .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-mosaic-bg-brand-variation-one hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-mosaic-bg-brand-variation-two {
  color: rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #e9e9e7;
}

.p-mosaic-bg-brand-variation-two h1,
.p-mosaic-bg-brand-variation-two .h1,
.p-mosaic-bg-brand-variation-two h2,
.p-mosaic-bg-brand-variation-two .h2 {
  color: black;
}

.p-mosaic-bg-brand-variation-two h3,
.p-mosaic-bg-brand-variation-two .h3,
.p-mosaic-bg-brand-variation-two h4,
.p-mosaic-bg-brand-variation-two .h4 {
  color: rgba(0, 0, 0, 0.75);
}

.p-mosaic-bg-brand-variation-two p a:not(.btn) {
  color: black;
  text-decoration: underline;
}

.p-mosaic-bg-brand-variation-two p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-mosaic-bg-brand-variation-two p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-mosaic-bg-brand-variation-two .p .p-txt-container .table tbody th,
.p-mosaic-bg-brand-variation-two .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-mosaic-bg-brand-variation-two hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-mosaic-txt-dark {
  color: rgba(0, 0, 0, 0.65);
}

.p-mosaic-txt-dark h1,
.p-mosaic-txt-dark .h1,
.p-mosaic-txt-dark h2,
.p-mosaic-txt-dark .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-mosaic-txt-dark h3,
.p-mosaic-txt-dark .h3,
.p-mosaic-txt-dark h4,
.p-mosaic-txt-dark .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-mosaic-txt-dark p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-mosaic-txt-dark p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-mosaic-txt-light {
  color: rgba(255, 255, 255, 0.75);
}

.p-mosaic-txt-light h1,
.p-mosaic-txt-light .h1,
.p-mosaic-txt-light h2,
.p-mosaic-txt-light .h2 {
  color: white;
}

.p-mosaic-txt-light h3,
.p-mosaic-txt-light .h3,
.p-mosaic-txt-light h4,
.p-mosaic-txt-light .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-mosaic-txt-light p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-mosaic-txt-light p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-mosaic-txt-light p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section {
  position: relative;
  border: 0 solid rgba(0, 0, 0, 0.15);
  padding-top: 3.2em;
  padding-bottom: 3.2em;
}

.p-section.pt-1 {
  padding-top: 1.6em !important;
}

.p-section.pb-1 {
  padding-bottom: 1.6em !important;
}

.p-section.pt-2 {
  padding-top: 3.2em !important;
}

.p-section.pb-2 {
  padding-bottom: 3.2em !important;
}

.p-section.pt-3 {
  padding-top: 4.8em !important;
}

.p-section.pb-3 {
  padding-bottom: 4.8em !important;
}

.p-section.pt-4 {
  padding-top: 6.4em !important;
}

.p-section.pb-4 {
  padding-bottom: 6.4em !important;
}

.p-section.pt-5 {
  padding-top: 8em !important;
}

.p-section.pb-5 {
  padding-bottom: 8em !important;
}

.p-section.pl-0 .container-fluid {
  padding-left: 0;
}

.p-section.pl-0 .container-fluid > .row {
  margin-left: 0;
}

.p-section.pl-0 .col-12.col-md-12.col-lg-12 {
  padding-left: 0;
}

.p-section.pl-0 .col-12.col-md-12.col-lg-12 > .row {
  margin-left: 0;
}

.p-section.pl-0 .col-12.col-md-12.col-lg-12 > .row > .p:first-child {
  padding-left: 0;
}

.p-section.pr-0 .container-fluid {
  padding-right: 0;
}

.p-section.pr-0 .container-fluid > .row {
  margin-right: 0;
}

.p-section.pr-0 .col-12.col-md-12.col-lg-12 {
  padding-right: 0;
}

.p-section.pr-0 .col-12.col-md-12.col-lg-12 > .row {
  margin-right: 0;
}

.p-section.pr-0 .col-12.col-md-12.col-lg-12 > .row > .p:last-child {
  padding-right: 0;
}

.p-section-small, .p-banner-small, .p-carousel-small .p-carousel-item {
  height: 25vw;
  max-height: 90vh;
}

@media (max-width: 575px) {
  .p-section-small, .p-banner-small, .p-carousel-small .p-carousel-item {
    height: auto;
    min-height: 50vw;
  }
}

@media (min-width: 2561px) {
  .p-section-small, .p-banner-small, .p-carousel-small .p-carousel-item {
    height: 640px;
  }
}

.p-section-medium, .p-banner-medium, .p-carousel-medium .p-carousel-item {
  height: 37.5vw;
  max-height: 90vh;
}

@media (max-width: 575px) {
  .p-section-medium, .p-banner-medium, .p-carousel-medium .p-carousel-item {
    height: auto;
    min-height: 75vw;
  }
}

@media (min-width: 2561px) {
  .p-section-medium, .p-banner-medium, .p-carousel-medium .p-carousel-item {
    height: 960px;
  }
}

.p-section-large, .p-banner-large, .p-carousel-large .p-carousel-item {
  height: 50vw;
  max-height: 90vh;
}

@media (max-width: 575px) {
  .p-section-large, .p-banner-large, .p-carousel-large .p-carousel-item {
    height: auto;
    min-height: 100vw;
  }
}

@media (min-width: 2561px) {
  .p-section-large, .p-banner-large, .p-carousel-large .p-carousel-item {
    height: 1280px;
  }
}

.p-section-fullscreen, .p-banner-fullscreen, .p-carousel-fullscreen .p-carousel-item {
  height: 100vh;
}

@media (min-width: 576px) {
  .p-section-columns > .container-fluid > .row > .col-12 > .row {
    display: block;
    margin: 0;
    column-gap: 0;
  }
  .p-section-columns-2 > .container-fluid > .row > .col-12 > .row {
    column-count: 2;
  }
  .p-section-columns-3 > .container-fluid > .row > .col-12 > .row {
    column-count: 3;
  }
  .p-section-columns-4 > .container-fluid > .row > .col-12 > .row {
    column-count: 4;
  }
  .p-section-columns-5 > .container-fluid > .row > .col-12 > .row {
    column-count: 5;
  }
}

.p-section-bg-white {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.p-section-bg-white h1,
.p-section-bg-white .h1,
.p-section-bg-white h2,
.p-section-bg-white .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-section-bg-white h3,
.p-section-bg-white .h3,
.p-section-bg-white h4,
.p-section-bg-white .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-section-bg-white p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-section-bg-white p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section-bg-light {
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.15);
  background: #f5f5f5;
}

.p-section-bg-light h1,
.p-section-bg-light .h1,
.p-section-bg-light h2,
.p-section-bg-light .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-section-bg-light h3,
.p-section-bg-light .h3,
.p-section-bg-light h4,
.p-section-bg-light .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-section-bg-light p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-section-bg-light p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section-bg-dark {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #424242;
}

.p-section-bg-dark h1,
.p-section-bg-dark .h1,
.p-section-bg-dark h2,
.p-section-bg-dark .h2 {
  color: white;
}

.p-section-bg-dark h3,
.p-section-bg-dark .h3,
.p-section-bg-dark h4,
.p-section-bg-dark .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-section-bg-dark p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-section-bg-dark p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-section-bg-dark p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section-bg-black {
  color: rgba(255, 255, 255, 0.75);
  background-color: #000;
}

.p-section-bg-black h1,
.p-section-bg-black .h1,
.p-section-bg-black h2,
.p-section-bg-black .h2 {
  color: white;
}

.p-section-bg-black h3,
.p-section-bg-black .h3,
.p-section-bg-black h4,
.p-section-bg-black .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-section-bg-black p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-section-bg-black p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-section-bg-black p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section-bg-brand-primary {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #99BA44;
}

.p-section-bg-brand-primary h1,
.p-section-bg-brand-primary .h1,
.p-section-bg-brand-primary h2,
.p-section-bg-brand-primary .h2 {
  color: white;
}

.p-section-bg-brand-primary h3,
.p-section-bg-brand-primary .h3,
.p-section-bg-brand-primary h4,
.p-section-bg-brand-primary .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-section-bg-brand-primary p a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-section-bg-brand-primary p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-section-bg-brand-primary p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section-bg-brand-primary .p .p-txt-container .table tbody th,
.p-section-bg-brand-primary .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-section-bg-brand-primary hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-section-bg-brand-secondary {
  color: rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #eaf1d9;
}

.p-section-bg-brand-secondary h1,
.p-section-bg-brand-secondary .h1,
.p-section-bg-brand-secondary h2,
.p-section-bg-brand-secondary .h2 {
  color: black;
}

.p-section-bg-brand-secondary h3,
.p-section-bg-brand-secondary .h3 h4,
.p-section-bg-brand-secondary .h4 {
  color: rgba(0, 0, 0, 0.75);
}

.p-section-bg-brand-secondary p a:not(.btn) {
  color: black;
  text-decoration: underline;
}

.p-section-bg-brand-secondary p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-section-bg-brand-secondary p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section-bg-brand-secondary .p .p-txt-container .table tbody th,
.p-section-bg-brand-secondary .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-section-bg-brand-secondary hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-section-bg-brand-variation-one {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #99BA44;
}

.p-section-bg-brand-variation-one h1,
.p-section-bg-brand-variation-one .h1,
.p-section-bg-brand-variation-one h2,
.p-section-bg-brand-variation-one .h2 {
  color: white;
}

.p-section-bg-brand-variation-one h3,
.p-section-bg-brand-variation-one .h3,
.p-section-bg-brand-variation-one h4,
.p-section-bg-brand-variation-one .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-section-bg-brand-variation-one p a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-section-bg-brand-variation-one p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-section-bg-brand-variation-one p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section-bg-brand-variation-one .p .p-txt-container .table tbody th,
.p-section-bg-brand-variation-one .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-section-bg-brand-variation-one hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-section-bg-brand-variation-two {
  color: rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #e9e9e7;
}

.p-section-bg-brand-variation-two h1,
.p-section-bg-brand-variation-two .h1,
.p-section-bg-brand-variation-two h2,
.p-section-bg-brand-variation-two .h2 {
  color: black;
}

.p-section-bg-brand-variation-two h3,
.p-section-bg-brand-variation-two .h3,
.p-section-bg-brand-variation-two h4,
.p-section-bg-brand-variation-two .h4 {
  color: rgba(0, 0, 0, 0.75);
}

.p-section-bg-brand-variation-two p a:not(.btn) {
  color: black;
  text-decoration: underline;
}

.p-section-bg-brand-variation-two p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-section-bg-brand-variation-two p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section-bg-brand-variation-two .p .p-txt-container .table tbody th,
.p-section-bg-brand-variation-two .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-section-bg-brand-variation-two hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-section-txt-dark {
  color: rgba(0, 0, 0, 0.65);
}

.p-section-txt-dark h1,
.p-section-txt-dark .h1,
.p-section-txt-dark h2,
.p-section-txt-dark .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-section-txt-dark h3,
.p-section-txt-dark .h3,
.p-section-txt-dark h4,
.p-section-txt-dark .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-section-txt-dark p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-section-txt-dark p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section-txt-light {
  color: rgba(255, 255, 255, 0.75);
}

.p-section-txt-light h1,
.p-section-txt-light .h1,
.p-section-txt-light h2,
.p-section-txt-light .h2 {
  color: white;
}

.p-section-txt-light h3,
.p-section-txt-light .h3,
.p-section-txt-light h4,
.p-section-txt-light .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-section-txt-light p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-section-txt-light p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-section-txt-light p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-section-bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.p-section-bg-contain {
  background-size: contain;
}

.p-section-bg-left-top {
  background-position: 0 0;
}

.p-section-bg-left-middle {
  background-position: 0 50%;
}

.p-section-bg-left-bottom {
  background-position: 0 100%;
}

.p-section-bg-center-top {
  background-position: 50% 0;
}

.p-section-bg-center-bottom {
  background-position: 50% 100%;
}

.p-section-bg-right-top {
  background-position: 100% 0;
}

.p-section-bg-right-middle {
  background-position: 100% 50%;
}

.p-section-bg-right-bottom {
  background-position: 100% 100%;
}

.p-section-bg-mt-1 {
  top: 2.5%;
}

.p-section-bg-mr-1 {
  right: 2.5%;
}

.p-section-bg-mb-1 {
  bottom: 2.5%;
}

.p-section-bg-ml-1 {
  left: 2.5%;
}

.p-section-bg-mt-2 {
  top: 5%;
}

.p-section-bg-mr-2 {
  right: 5%;
}

.p-section-bg-mb-2 {
  bottom: 5%;
}

.p-section-bg-ml-2 {
  left: 5%;
}

.p-section-bg-mt-3 {
  top: 7.5%;
}

.p-section-bg-mr-3 {
  right: 7.5%;
}

.p-section-bg-mb-3 {
  bottom: 7.5%;
}

.p-section-bg-ml-3 {
  left: 7.5%;
}

.p-section-bg-mt-4 {
  top: 10%;
}

.p-section-bg-mr-4 {
  right: 10%;
}

.p-section-bg-mb-4 {
  bottom: 10%;
}

.p-section-bg-ml-4 {
  left: 10%;
}

.p-section-bg-mt-5 {
  top: 12.5%;
}

.p-section-bg-mr-5 {
  right: 12.5%;
}

.p-section-bg-mb-5 {
  bottom: 12.5%;
}

.p-section-bg-ml-5 {
  left: 12.5%;
}

.p-section-border-top {
  border-top-width: 1px;
}

.p-section-border-bottom {
  border-bottom-width: 1px;
}

.p-section-border-white {
  border-color: #fff;
}

.p-section-border-black {
  border-color: #000;
}

.p-section-border-light {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-section-border-dark {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-section-border-brand-primary {
  border-color: #99BA44;
}

.p-section-border-brand-secondary {
  border-color: #eaf1d9;
}

.p-section-border-brand-variation-one {
  border-color: #99BA44;
}

.p-section-border-brand-variation-two {
  border-color: #e9e9e7;
}

.p-section-toggle-collapse {
  cursor: pointer;
  position: relative;
  padding-left: 1.25em;
  line-height: 1.25;
}

.p-section-toggle-collapse .material-icons {
  position: absolute;
  left: 0;
  top: 0.125em;
  transform: rotate(135deg);
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.p-section-toggle-collapse.collapsed .material-icons {
  transform: rotate(0deg);
}

:-webkit-full-screen-ancestor:not(iframe) {
  will-change: initial !important;
}

.p-video-container {
  margin-bottom: 1.6em;
}

.p-divider {
  margin-bottom: 1.6em;
}

.p-divider hr {
  margin: 0;
}

.p-divider-thin hr {
  border-top-width: 1px;
}

.p-divider-thick hr {
  border-top-width: 0.375em;
}

.p-divider-transparent hr {
  border-color: transparent;
}

.p-divider-white hr {
  border-color: #fff;
}

.p-divider-black hr {
  border-color: #000;
}

.p-divider-light hr {
  border-color: #f5f5f5;
}

.p-divider-dark hr {
  border-color: #424242;
}

.p-divider-brand-primary hr {
  border-color: #99BA44;
}

.p-divider-brand-secondary hr {
  border-color: #eaf1d9;
}

.p-divider-brand-variation-one hr {
  border-color: #99BA44;
}

.p-divider-brand-variation-two hr {
  border-color: #e9e9e7;
}

.p-carousel {
  height: auto;
  margin-bottom: 1.6em;
}

@media (max-width: 991px) {
  .p-carousel .carousel-inner {
    box-sizing: content-box;
    padding-bottom: 16em;
    background: #212121;
  }
  .p-carousel .p-carousel-item::before {
    background: none;
  }
  .p-carousel .carousel-caption {
    box-sizing: border-box;
    height: 16em;
    bottom: auto;
    top: 100%;
  }
  .p-carousel .carousel-indicators {
    bottom: 14.4em;
  }
  .p-carousel .carousel-control-prev,
  .p-carousel .carousel-control-next {
    padding-bottom: 8em;
  }
}

.p-carousel.p-carousel-fullscreen .carousel-inner {
  box-sizing: inherit;
  padding-bottom: 0;
  background: none;
}

.p-carousel.p-carousel-fullscreen .p-carousel-item::before {
  background: linear-gradient(11deg, rgba(0, 0, 0, 0.65) 0%, transparent 45%);
}

.p-carousel.p-carousel-fullscreen .carousel-caption {
  height: auto;
  bottom: 0;
  top: auto;
}

.p-carousel.p-carousel-fullscreen .carousel-control-prev,
.p-carousel.p-carousel-fullscreen .carousel-control-next {
  margin-top: 0;
}

.p-carousel.p-carousel-fullscreen .carousel-indicators {
  bottom: 0.8em;
}

.p-carousel-item {
  background-size: cover;
  background-position: 50% 50%;
}

.p-carousel-item:not(.p-carousel-item-gradient-disabled)::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(22deg, rgba(0, 0, 0, 0.95) 0%, transparent 60%);
}

@media (max-width: 991px) {
  .p-carousel-item:not(.p-carousel-item-gradient-disabled)::before {
    background: none;
  }
}

.p-carousel-fullscreen .p-carousel-item .carousel-caption {
  bottom: 1.6em;
  margin-bottom: 1vmin;
}

.p-carousel-item-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: transparent;
}

@media (max-width: 991px) {
  .p-carousel-item-link {
    bottom: -16em;
  }
}

.p-carousel-item-img-left-top {
  background-position: 0 0;
}

.p-carousel-item-img-left-middle {
  background-position: 0 50%;
}

.p-carousel-item-img-left-bottom {
  background-position: 0 100%;
}

.p-carousel-item-img-center-top {
  background-position: 50% 0;
}

.p-carousel-item-img-center-middle {
  background-position: 50% 50%;
}

.p-carousel-item-img-center-bottom {
  background-position: 50% 100%;
}

.p-carousel-item-img-right-top {
  background-position: 100% 0;
}

.p-carousel-item-img-right-middle {
  background-position: 100% 50%;
}

.p-carousel-item-img-right-bottom {
  background-position: 100% 100%;
}

.p-contact {
  margin-bottom: 3.2em;
}

.p-contact .p-txt-container .p-txt-wrapper {
  font-size: 0.875em;
}

.p-contact .p-contact-name {
  margin-bottom: 0;
}

.p-contact .p-contact-title {
  margin-bottom: 1.2em;
}

.p-contact .p-contact-phone {
  white-space: nowrap;
  margin-bottom: 0.8em;
}

.p-contact .p-contact-name + .p-contact-phone {
  margin-top: 1.2em;
}

.p-contact .p-contact-email {
  margin: 0;
  white-space: nowrap;
}

.p-contact .p-contact-email .btn {
  margin-top: 1.2em;
}

.p-contact .material-icons {
  font-size: 1.25em;
  vertical-align: -25%;
  width: 1.5em;
  text-align: left;
}

.p-contact .p-img-width-auto {
  max-width: 12.5em;
}

.p-contact .p-img-container .col-pos-center {
  margin-left: auto;
  margin-right: auto;
}

.p-contact .p-img-container .col-pos-right {
  margin-left: auto;
}

.p-card {
  display: flex;
  margin-bottom: 1.6em;
}

.p-card .card {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  width: 100%;
}

.p-card .card h1,
.p-card .card .h1,
.p-card .card h2,
.p-card .card .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-card .card h3,
.p-card .card .h3,
.p-card .card h4,
.p-card .card .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-card .card p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-card .card p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card.p-card-top-bottom .card {
  display: flex;
  flex-direction: column;
}

.p-card .p-txt-container {
  font-size: 0.875em;
  width: 100%;
  flex: 1 1 auto;
}

.p-card .p-txt-container h1,
.p-card .p-txt-container h2,
.p-card .p-txt-container h3,
.p-card .p-txt-container h4,
.p-card .p-txt-container p,
.p-card .p-txt-container ul,
.p-card .p-txt-container table {
  margin-bottom: 0.5rem;
}

.p-card .p-txt-container :last-child {
  margin-bottom: 0;
}

.p-card .p-img-container {
  width: 100%;
  flex: 0 0 auto;
}

.p-card .p-img-container .card-body {
  padding-bottom: 0;
}

.p-card .p-img-container .col-pos-center {
  margin-left: auto;
  margin-right: auto;
}

.p-card .p-img-container .col-pos-right {
  margin-left: auto;
}

.p-card .p-img-container .p-img[src$="svg"] {
  width: 100%;
}

.p-card-img-bleed .p-img-container .card-body {
  padding: 0;
}

.p-card-img-bleed .p-img-container .row {
  margin: 0;
}

.p-card-img-bleed .p-img-container .col-12 {
  padding: 0;
}

.p-card-img-bleed .p-img-container .p-img {
  border-radius: 0.125em 0.125em 0 0;
}

.p-card-link .card {
  cursor: pointer;
}

.p-card-txt-small .p-txt-container {
  font-size: 0.875em;
}

.p-card-txt-medium .p-txt-container {
  font-size: 1em;
}

.p-card-txt-large .p-txt-container {
  font-size: 1.375em;
}

.p-card:not(.p-card-hover-disabled):hover .card {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.p-card-shadow-none .card {
  box-shadow: none;
}

.p-card-shadow-none:not(.p-card-hover-disabled):hover .card {
  box-shadow: none;
}

.p-card-shadow-small .card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-card-shadow-small:not(.p-card-hover-disabled):hover .card {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.p-card-shadow-medium .card {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.p-card-shadow-medium:not(.p-card-hover-disabled):hover .card {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.p-card-shadow-large .card {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.p-card-shadow-large:not(.p-card-hover-disabled):hover .card {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.14), 0 12px 17px 2px rgba(0, 0, 0, 0.12), 0 5px 22px 4px rgba(0, 0, 0, 0.2);
}

.p-card-padding-small .card-body {
  padding: 5%;
}

.p-card-padding-medium .card-body {
  padding: 10%;
}

.p-card-padding-large .card-body {
  padding: 15%;
}

.p-card-bg-white .card {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.p-card-bg-white .card h1,
.p-card-bg-white .card .h1,
.p-card-bg-white .card h2,
.p-card-bg-white .card .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-card-bg-white .card h3,
.p-card-bg-white .card .h3,
.p-card-bg-white .card h4,
.p-card-bg-white .card .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-card-bg-white .card p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-card-bg-white .card p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card-bg-black .card {
  color: rgba(255, 255, 255, 0.75);
  background-color: #000;
}

.p-card-bg-black .card h1,
.p-card-bg-black .card .h1,
.p-card-bg-black .card h2,
.p-card-bg-black .card .h2 {
  color: white;
}

.p-card-bg-black .card h3,
.p-card-bg-black .card .h3,
.p-card-bg-black .card h4,
.p-card-bg-black .card .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-card-bg-black .card p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-card-bg-black .card p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-card-bg-black .card p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card-bg-light .card {
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.15);
  background: #f5f5f5;
}

.p-card-bg-light .card h1,
.p-card-bg-light .card .h1,
.p-card-bg-light .card h2,
.p-card-bg-light .card .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-card-bg-light .card h3,
.p-card-bg-light .card .h3,
.p-card-bg-light .card h4,
.p-card-bg-light .card .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-card-bg-light .card p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-card-bg-light .card p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card-bg-dark .card {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #424242;
}

.p-card-bg-dark .card h1,
.p-card-bg-dark .card .h1,
.p-card-bg-dark .card h2,
.p-card-bg-dark .card .h2 {
  color: white;
}

.p-card-bg-dark .card h3,
.p-card-bg-dark .card .h3,
.p-card-bg-dark .card h4,
.p-card-bg-dark .card .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-card-bg-dark .card p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-card-bg-dark .card p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-card-bg-dark .card p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card-bg-brand-primary .card {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #99BA44;
}

.p-card-bg-brand-primary .card h1,
.p-card-bg-brand-primary .card .h1,
.p-card-bg-brand-primary .card h2,
.p-card-bg-brand-primary .card .h2 {
  color: white;
}

.p-card-bg-brand-primary .card h3,
.p-card-bg-brand-primary .card .h3,
.p-card-bg-brand-primary .card h4,
.p-card-bg-brand-primary .card .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-card-bg-brand-primary .card p a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-card-bg-brand-primary .card p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-card-bg-brand-primary .card p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card-bg-brand-primary .card .p .p-txt-container .table tbody th,
.p-card-bg-brand-primary .card .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-card-bg-brand-primary .card hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-card-bg-brand-secondary .card {
  color: rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #eaf1d9;
}

.p-card-bg-brand-secondary .card h1,
.p-card-bg-brand-secondary .card .h1,
.p-card-bg-brand-secondary .card h2,
.p-card-bg-brand-secondary .card .h2 {
  color: black;
}

.p-card-bg-brand-secondary .card h3,
.p-card-bg-brand-secondary .card .h3 h4,
.p-card-bg-brand-secondary .card .h4 {
  color: rgba(0, 0, 0, 0.75);
}

.p-card-bg-brand-secondary .card p a:not(.btn) {
  color: black;
  text-decoration: underline;
}

.p-card-bg-brand-secondary .card p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-card-bg-brand-secondary .card p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card-bg-brand-secondary .card .p .p-txt-container .table tbody th,
.p-card-bg-brand-secondary .card .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-card-bg-brand-secondary .card hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-card-bg-brand-variation-one .card {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #99BA44;
}

.p-card-bg-brand-variation-one .card h1,
.p-card-bg-brand-variation-one .card .h1,
.p-card-bg-brand-variation-one .card h2,
.p-card-bg-brand-variation-one .card .h2 {
  color: white;
}

.p-card-bg-brand-variation-one .card h3,
.p-card-bg-brand-variation-one .card .h3,
.p-card-bg-brand-variation-one .card h4,
.p-card-bg-brand-variation-one .card .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-card-bg-brand-variation-one .card p a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-card-bg-brand-variation-one .card p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-card-bg-brand-variation-one .card p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card-bg-brand-variation-one .card .p .p-txt-container .table tbody th,
.p-card-bg-brand-variation-one .card .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-card-bg-brand-variation-one .card hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-card-bg-brand-variation-two .card {
  color: rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #e9e9e7;
}

.p-card-bg-brand-variation-two .card h1,
.p-card-bg-brand-variation-two .card .h1,
.p-card-bg-brand-variation-two .card h2,
.p-card-bg-brand-variation-two .card .h2 {
  color: black;
}

.p-card-bg-brand-variation-two .card h3,
.p-card-bg-brand-variation-two .card .h3,
.p-card-bg-brand-variation-two .card h4,
.p-card-bg-brand-variation-two .card .h4 {
  color: rgba(0, 0, 0, 0.75);
}

.p-card-bg-brand-variation-two .card p a:not(.btn) {
  color: black;
  text-decoration: underline;
}

.p-card-bg-brand-variation-two .card p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-card-bg-brand-variation-two .card p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card-bg-brand-variation-two .card .p .p-txt-container .table tbody th,
.p-card-bg-brand-variation-two .card .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-card-bg-brand-variation-two .card hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-card-txt-dark {
  color: rgba(0, 0, 0, 0.65);
}

.p-card-txt-dark h1,
.p-card-txt-dark .h1,
.p-card-txt-dark h2,
.p-card-txt-dark .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-card-txt-dark h3,
.p-card-txt-dark .h3,
.p-card-txt-dark h4,
.p-card-txt-dark .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-card-txt-dark p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-card-txt-dark p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card-txt-light {
  color: rgba(255, 255, 255, 0.75);
}

.p-card-txt-light h1,
.p-card-txt-light .h1,
.p-card-txt-light h2,
.p-card-txt-light .h2 {
  color: white;
}

.p-card-txt-light h3,
.p-card-txt-light .h3,
.p-card-txt-light h4,
.p-card-txt-light .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-card-txt-light p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-card-txt-light p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-card-txt-light p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-card-txt-bg-white .p-txt-container {
  background-color: #fff;
}

.p-card-txt-bg-black .p-txt-container {
  background-color: #000;
}

.p-card-txt-bg-light .p-txt-container {
  background: #f5f5f5;
}

.p-card-txt-bg-dark .p-txt-container {
  background: #424242;
}

.p-card-txt-bg-brand-primary .p-txt-container {
  background-color: #99BA44;
}

.p-card-txt-bg-brand-secondary .p-txt-container {
  background-color: #eaf1d9;
}

.p-card-txt-bg-brand-variation-one .p-txt-container {
  background-color: #99BA44;
}

.p-card-txt-bg-brand-variation-two .p-txt-container {
  background-color: #e9e9e7;
}

.p-card-border-thin .card {
  border-width: 1px;
}

.p-card-border-thick .card {
  border-width: 0.375em;
}

.p-card-border-white {
  border-color: #fff;
}

.p-card-border-black {
  border-color: #000;
}

.p-card-border-light {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-card-border-dark {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-card-border-brand-primary {
  border-color: #99BA44;
}

.p-card-border-brand-secondary {
  border-color: #eaf1d9;
}

.p-tile {
  padding: 0;
  font-size: 0.875em;
}

.p-section-columns > .container-fluid > .row > .col-12 > .row .p-tile.p {
  page-break-inside: avoid;
}

.p-section-columns > .container-fluid > .row > .col-12 > .row .p-tile.col-4 {
  float: left;
  width: 33.33333%;
}

.p-section-columns > .container-fluid > .row > .col-12 > .row .p-tile.col-6 {
  float: left;
  width: 50%;
}

@media (min-width: 576px) {
  .p-section-columns > .container-fluid > .row > .col-12 > .row .p-tile.col-12 {
    float: left;
    width: 100%;
  }
}

.p-tile .p-txt-container {
  font-size: 0.875em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  min-height: 100%;
  padding: 5%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .p-tile .p-txt-container {
    padding-bottom: 7.5%;
  }
}

@media (min-width: 768px) {
  .p-tile .p-txt-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    padding-right: 20%;
  }
}

@media (min-width: 992px) {
  .p-tile .p-txt-container {
    padding-right: 25%;
  }
}

@media (min-width: 1200px) {
  .p-tile .p-txt-container {
    padding-right: 40%;
  }
}

.p-tile h2 {
  margin-bottom: 0.5rem;
}

.p-tile.p-tile-link h2::after {
  content: "arrow_forward";
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  vertical-align: -10%;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  font-smoothing: antialiased;
  width: 0;
  transform: translateX(0.25em);
  transition: transform 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.p-tile.p-tile-link:hover h2::after {
  transform: translateX(0.75em);
}

.p-tile.p-tile-link:hover .p-img {
  transform: scale(1.25, 1.25);
}

.p-tile h4 {
  margin-bottom: 0.5rem;
  opacity: 1;
}

.p-tile a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.p-tile :last-child {
  margin-bottom: 0;
}

.p-tile-img .p-txt-container h2 {
  color: white;
}

.p-tile-img .p-txt-container h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-img .p-img-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

.p-tile-img .p-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: transform 0.375s;
  backface-visibility: hidden;
}

.p-tile-img .p-img:not(.p-tile-gradient-disabled)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(22deg, rgba(0, 0, 0, 0.95) 0%, transparent 60%);
}

.p-tile-img .p-img-left-top {
  background-position: 0 0;
}

.p-tile-img .p-img-left-middle {
  background-position: 0 50%;
}

.p-tile-img .p-img-left-bottom {
  background-position: 0 100%;
}

.p-tile-img .p-img-center-top {
  background-position: 50% 0;
}

.p-tile-img .p-img-center-middle {
  background-position: 50% 50%;
}

.p-tile-img .p-img-center-bottom {
  background-position: 50% 100%;
}

.p-tile-img .p-img-right-top {
  background-position: 100% 0;
}

.p-tile-img .p-img-right-middle {
  background-position: 100% 50%;
}

.p-tile-img .p-img-right-bottom {
  background-position: 100% 100%;
}

.p-tile-height-auto .p-txt-container {
  position: static;
}

.p-tile-height-xs {
  height: 25vh;
  min-height: 12.5em;
}

.p-tile-height-sm {
  height: 33.33333vh;
  min-height: 16.66666em;
}

.p-tile-height-md {
  height: 50vh;
  min-height: 25em;
}

.p-tile-height-lg {
  height: 66.66666vh;
  min-height: 33.33333em;
}

.p-tile-height-xl {
  height: 75vh;
  min-height: 37.5em;
}

.p-tile-height-fullscreen {
  height: 100vh;
}

@media (min-width: 576px) {
  .p-tile-height-fullscreen {
    min-height: 50em;
  }
}

.p-tile-bg-white {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.p-tile-bg-white h1,
.p-tile-bg-white .h1,
.p-tile-bg-white h2,
.p-tile-bg-white .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-tile-bg-white h3,
.p-tile-bg-white .h3,
.p-tile-bg-white h4,
.p-tile-bg-white .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-tile-bg-white p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-tile-bg-white p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-tile-bg-black {
  color: rgba(255, 255, 255, 0.75);
  background-color: #000;
}

.p-tile-bg-black h1,
.p-tile-bg-black .h1,
.p-tile-bg-black h2,
.p-tile-bg-black .h2 {
  color: white;
}

.p-tile-bg-black h3,
.p-tile-bg-black .h3,
.p-tile-bg-black h4,
.p-tile-bg-black .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-bg-black p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-tile-bg-black p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-tile-bg-black p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-tile-bg-light {
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.15);
  background: #f5f5f5;
}

.p-tile-bg-light h1,
.p-tile-bg-light .h1,
.p-tile-bg-light h2,
.p-tile-bg-light .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-tile-bg-light h3,
.p-tile-bg-light .h3,
.p-tile-bg-light h4,
.p-tile-bg-light .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-tile-bg-light p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-tile-bg-light p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-tile-bg-dark {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #424242;
}

.p-tile-bg-dark h1,
.p-tile-bg-dark .h1,
.p-tile-bg-dark h2,
.p-tile-bg-dark .h2 {
  color: white;
}

.p-tile-bg-dark h3,
.p-tile-bg-dark .h3,
.p-tile-bg-dark h4,
.p-tile-bg-dark .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-bg-dark p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-tile-bg-dark p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-tile-bg-dark p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-tile-bg-brand-primary {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #99BA44;
}

.p-tile-bg-brand-primary h1,
.p-tile-bg-brand-primary .h1,
.p-tile-bg-brand-primary h2,
.p-tile-bg-brand-primary .h2 {
  color: white;
}

.p-tile-bg-brand-primary h3,
.p-tile-bg-brand-primary .h3,
.p-tile-bg-brand-primary h4,
.p-tile-bg-brand-primary .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-bg-brand-primary p a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-tile-bg-brand-primary p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-tile-bg-brand-primary p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-tile-bg-brand-primary .p .p-txt-container .table tbody th,
.p-tile-bg-brand-primary .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-tile-bg-brand-primary hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-tile-bg-brand-secondary {
  color: rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #eaf1d9;
}

.p-tile-bg-brand-secondary h1,
.p-tile-bg-brand-secondary .h1,
.p-tile-bg-brand-secondary h2,
.p-tile-bg-brand-secondary .h2 {
  color: black;
}

.p-tile-bg-brand-secondary h3,
.p-tile-bg-brand-secondary .h3 h4,
.p-tile-bg-brand-secondary .h4 {
  color: rgba(0, 0, 0, 0.75);
}

.p-tile-bg-brand-secondary p a:not(.btn) {
  color: black;
  text-decoration: underline;
}

.p-tile-bg-brand-secondary p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-tile-bg-brand-secondary p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-tile-bg-brand-secondary .p .p-txt-container .table tbody th,
.p-tile-bg-brand-secondary .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-tile-bg-brand-secondary hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-tile-bg-brand-variation-one {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #99BA44;
}

.p-tile-bg-brand-variation-one h1,
.p-tile-bg-brand-variation-one .h1,
.p-tile-bg-brand-variation-one h2,
.p-tile-bg-brand-variation-one .h2 {
  color: white;
}

.p-tile-bg-brand-variation-one h3,
.p-tile-bg-brand-variation-one .h3,
.p-tile-bg-brand-variation-one h4,
.p-tile-bg-brand-variation-one .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-bg-brand-variation-one p a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-tile-bg-brand-variation-one p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-tile-bg-brand-variation-one p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-tile-bg-brand-variation-one .p .p-txt-container .table tbody th,
.p-tile-bg-brand-variation-one .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-tile-bg-brand-variation-one hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-tile-bg-brand-variation-two {
  color: rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.15);
  background: #e9e9e7;
}

.p-tile-bg-brand-variation-two h1,
.p-tile-bg-brand-variation-two .h1,
.p-tile-bg-brand-variation-two h2,
.p-tile-bg-brand-variation-two .h2 {
  color: black;
}

.p-tile-bg-brand-variation-two h3,
.p-tile-bg-brand-variation-two .h3,
.p-tile-bg-brand-variation-two h4,
.p-tile-bg-brand-variation-two .h4 {
  color: rgba(0, 0, 0, 0.75);
}

.p-tile-bg-brand-variation-two p a:not(.btn) {
  color: black;
  text-decoration: underline;
}

.p-tile-bg-brand-variation-two p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-tile-bg-brand-variation-two p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-tile-bg-brand-variation-two .p .p-txt-container .table tbody th,
.p-tile-bg-brand-variation-two .p .p-txt-container .table tbody td {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-tile-bg-brand-variation-two hr {
  border-color: rgba(0, 0, 0, 0.15);
}

.p-tile-txt-dark {
  color: rgba(0, 0, 0, 0.65);
}

.p-tile-txt-dark h1,
.p-tile-txt-dark .h1,
.p-tile-txt-dark h2,
.p-tile-txt-dark .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.p-tile-txt-dark h3,
.p-tile-txt-dark .h3,
.p-tile-txt-dark h4,
.p-tile-txt-dark .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.p-tile-txt-dark p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .p-tile-txt-dark p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.p-tile-txt-light {
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-txt-light h1,
.p-tile-txt-light .h1,
.p-tile-txt-light h2,
.p-tile-txt-light .h2 {
  color: white;
}

.p-tile-txt-light h3,
.p-tile-txt-light .h3,
.p-tile-txt-light h4,
.p-tile-txt-light .h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-txt-light p a:not(.btn) {
  color: #2196f3;
  text-decoration: underline;
}

.p-tile-txt-light p a:not(.btn):hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .p-tile-txt-light p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .p-tile-order-first {
    order: -1;
  }
  .p-tile-order-last {
    order: 1;
  }
}

.e-search-form-container {
  position: sticky;
  top: 0;
  z-index: 2;
}

.e-search-input-group {
  position: relative;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.0625em;
  background: #fff;
}

.e-search-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4em;
  margin: 0;
}

.e-search-label .material-icons {
  font-size: 1.5em;
  vertical-align: -26%;
}

.e-search-input {
  width: 100%;
}

.e-search-input-group .e-search-input, .e-search-input-group .e-search-input:focus, .e-search-input-group .e-search-input:hover {
  outline: none;
}

@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .e-search-input-group .e-search-input, .e-search-input-group .e-search-input:focus, .e-search-input-group .e-search-input:hover {
    font-size: 1.5em !important;
  }
}

.e-search-input:-webkit-autofill {
  transition: none;
  -webkit-box-shadow: 0 0 0 5em #fff inset;
  -webkit-text-fill-color: #212121;
}

.e-search-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4em;
  opacity: 0.85;
  cursor: pointer;
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-search-clear .material-icons {
  font-size: 1.5em;
}

.e-search-input-group.is-empty .e-search-clear {
  opacity: 0;
  pointer-events: none;
}

.e-search-submit {
  border: 0;
  box-shadow: none;
  font-size: 1em;
  padding: 0.5em 1em;
  margin: 0.25em 0.25em 0.25em 0;
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-search-submit .material-icons {
  font-size: 1.5em;
  vertical-align: -26%;
}

.e-search-input-group.is-empty .e-search-submit {
  opacity: 0.65;
  pointer-events: none;
}

.e-search-result-container {
  position: relative;
  display: none;
}

.e-search-result-container.is-active {
  display: block;
}

.e-search-result-pages.is-empty,
.e-search-result-products.is-empty {
  display: none;
}

.e-search-result-pages {
  line-height: 1.4;
}

.e-search-loading-overlay {
  min-height: 50vh;
  background: rgba(255, 255, 255, 0.85);
  transition: opacity 0.1875s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-search-loading-overlay .e-loading-spinner {
  top: 25vh;
}

.e-news.has-filter .e-news-item {
  opacity: 1;
  transform: none;
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.1875s cubic-bezier(0.33, 0, 0.2, 1);
  will-change: transform, opacity;
}

.e-news.is-filtering .e-news-item {
  opacity: 0;
  transform: translateY(50%) scale(0.375 0.375) rotate(0deg);
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.1875s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-news-filter .nav-link {
  padding-right: 2em;
  padding-left: 2em;
}

.e-news-filter .nav-link:not([data-filter="*"]) {
  padding-right: 1em;
}

.e-news-filter .nav-link .material-icons {
  width: 1em;
  transform: rotate(45deg);
  opacity: 0;
}

.e-news-filter .nav-link.active .material-icons {
  opacity: 1;
}

@media (min-width: 768px) {
  .e-dealersearch-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.e-dealersearch-header {
  flex: 0 1 auto;
}

.e-dealersearch-main {
  flex: 1 1 auto;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .e-dealersearch-main {
    max-height: 200vh;
  }
}

.e-dealersearch-main::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.e-dealersearch-main::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.e-dealersearch-main::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}

.e-dealersearch-input-placeholder {
  height: calc(2.3125em + 2px);
  padding: 0.375em 0.75em;
  border: 1px solid transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.1875s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-dealersearch-input-placeholder.is-hidden {
  opacity: 0;
}

.e-dealersearch-suggestions {
  min-width: 100%;
}

.e-dealersearch-suggestions .dropdown-item {
  cursor: pointer;
}

.e-dealersearch-suggestions-item-geolocation .material-icons {
  color: #2196f3;
}

.e-dealersearch-suggestions-item-geolocation.active .material-icons {
  color: inherit;
}

.e-dealersearch-list {
  opacity: 0;
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-dealersearch-list:not(.is-empty) {
  opacity: 1;
  background: #fff;
}

.e-dealersearch-list-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.e-dealersearch-list-item-address {
  cursor: pointer;
}

@media (max-width: 767px) {
  .e-dealersearch-googlemap-container {
    height: 0;
    overflow: hidden;
  }
  .e-dealersearch-googlemap-container.is-active {
    height: auto;
  }
}

.e-dealersearch-googlemap {
  height: 90vh;
}

.e-dealersearch-googlemap-infowindow {
  color: rgba(0, 0, 0, 0.65);
}

.e-dealersearch-googlemap-infowindow h1,
.e-dealersearch-googlemap-infowindow .h1,
.e-dealersearch-googlemap-infowindow h2,
.e-dealersearch-googlemap-infowindow .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.e-dealersearch-googlemap-infowindow h3,
.e-dealersearch-googlemap-infowindow .h3,
.e-dealersearch-googlemap-infowindow h4,
.e-dealersearch-googlemap-infowindow .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.e-dealersearch-googlemap-infowindow p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .e-dealersearch-googlemap-infowindow p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.e-scroll-reveal .e-section {
  opacity: 0;
  transform: translateY(10vh);
  will-change: transform, opacity;
}

.e-scroll-reveal .e-section.animate-in-on-load {
  opacity: 1;
  transform: none;
  transition: opacity 0.75s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-scroll-reveal .e-section.animate-in-on-scroll {
  opacity: 1;
  transform: none;
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 1.125s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-scroll-reveal .e-section.reset-animate {
  opacity: 1;
  transform: none;
  transition: none;
  will-change: initial;
}

.e-back-to-top {
  position: fixed;
  bottom: 1.6em;
  right: 1.6em;
  z-index: 1029;
  padding: 1em;
  border-radius: 0.125em;
  color: #212121;
  background: rgba(255, 255, 255, 0.35);
  user-select: none;
  transform: translateY(200%);
  transition: transform 0.5s;
  will-change: transform;
}

.e-back-to-top:hover, .e-back-to-top:focus, .e-back-to-top:active {
  color: #212121;
  background: rgba(255, 255, 255, 0.85);
}

.e-back-to-top.is-visible {
  transform: translateY(0);
}

.e-cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1031;
  font-size: 0.875em;
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
}

.e-cookie a {
  color: inherit;
  text-decoration: underline;
}

.e-cookie.is-accepted {
  display: none;
}

.e-cookie-accept-btn, .e-cookie-accept-btn:hover {
  color: currentColor;
  background-image: none;
  background-color: transparent;
  border-color: currentColor;
}

.e-animate-number {
  display: inline-block;
}

.e-cart {
  position: relative;
}

.e-cart .e-loading-overlay {
  position: fixed;
  max-height: 100vh;
}

@media (min-width: 768px) {
  .e-cart-sidebar {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.125em;
    margin-top: -1px;
    background: #fff;
    position: sticky;
    top: 1.6em;
    z-index: 1;
  }
}

.e-cart-footer {
  background: #fff;
  position: sticky;
  bottom: 0;
}

.e-cart-confirm-msg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1029;
  display: none;
  overflow: hidden;
  opacity: 0;
  background: #fff;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-cart-confirm-msg.is-active {
  opacity: 1;
  display: block;
}

.e-cart-confirm-msg-info {
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.15);
  background: #f5f5f5;
  height: 100%;
}

.e-cart-confirm-msg-info h1,
.e-cart-confirm-msg-info .h1,
.e-cart-confirm-msg-info h2,
.e-cart-confirm-msg-info .h2 {
  color: rgba(0, 0, 0, 0.85);
}

.e-cart-confirm-msg-info h3,
.e-cart-confirm-msg-info .h3,
.e-cart-confirm-msg-info h4,
.e-cart-confirm-msg-info .h4 {
  color: rgba(0, 0, 0, 0.65);
}

.e-cart-confirm-msg-info p a:not(.btn) {
  color: #2196f3;
}

@media (min-width: 768px) {
  .e-cart-confirm-msg-info p a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }
}

.e-cart-mini a {
  position: relative;
}

.e-cart-mini i {
  position: relative;
  z-index: 1;
}

.e-cart-quantity {
  color: #212121;
  font-weight: 700;
  background: #99BA44;
  height: 1.75em;
  width: 1.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 50%;
  transform: translate(110%, -110%);
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-cart-quantity:empty {
  opacity: 0;
}

.e-checkout-page .is-hidden {
  display: none;
}

.e-checkout-page .e-checkout {
  min-height: 100vh;
}

.e-checkout-page .e-checkout .e-checkout-step-loading-overlay {
  background: rgba(255, 255, 255, 0.85);
}

.e-checkout-page .e-checkout-step {
  padding: 1em;
  margin-top: -1px;
  margin-bottom: 0.8em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.125em;
  box-shadow: none;
}

.e-checkout-page .e-checkout-step.e-checkout-step-active .e-checkout-step-header {
  color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.e-checkout-page .e-checkout-step.e-checkout-step-active .e-checkout-step-header-badges-index {
  background: #99BA44;
}

.e-checkout-page .e-checkout-step.e-checkout-step-active .e-checkout-step-header {
  color: inherit;
}

.e-checkout-page .e-checkout-step.e-checkout-step-active .e-checkout-step-header-badges-index {
  background: #99BA44;
}

.e-checkout-page .e-checkout-step.e-checkout-step-completed .e-checkout-step-header-badges-completed {
  background: #4caf50;
}

.e-checkout-page .e-checkout-step.e-checkout-step-completed .e-checkout-step-summary {
  max-height: 100vh;
}

@media (max-width: 767px) {
  .e-checkout-page .e-checkout-step.e-checkout-step-completed {
    display: none;
  }
}

.e-checkout-page .e-checkout-step.e-checkout-step-has-changed .e-checkout-step-header-badges-warning {
  background: #f44336;
}

.e-checkout-page .e-checkout-step.e-checkout-approve-top-panel {
  border-color: #424242;
  background: #f5f5f5;
}

.e-checkout-page .e-checkout-step .e-checkout-step-card-block {
  padding: 1em;
}

@media (max-width: 991px) {
  .e-checkout-page .e-checkout-step .e-checkout-step-card-block {
    padding: 0 !important;
  }
}

@media (max-width: 991px) {
  .e-checkout-page .e-checkout-step .e-checkout-next-step {
    float: right;
    width: auto;
    font-size: 0.875em !important;
  }
}

.e-checkout-page .e-checkout-step .e-checkout-step-header {
  border-bottom-color: transparent;
}

.e-checkout-page .e-checkout-step .e-checkout-step-header-badges span {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-right: .5em;
  color: #fff;
  text-align: center;
  background: #9e9e9e;
  border-radius: 100%;
}

.e-checkout-page .e-checkout-step .e-checkout-step-header-badges span.e-checkout-step-header-badges-warning {
  display: none;
}

@media (max-width: 991px) {
  .e-checkout-page .e-checkout-step .e-checkout-step-header-badges {
    display: none;
  }
}

.e-checkout-page .e-checkout-step .e-checkout-step-header {
  padding: 1em;
  font-size: 1.25em;
  color: #9e9e9e;
  background: transparent;
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.e-checkout-page .e-checkout-step .e-checkout-step-list-group .list-group-item {
  display: flex;
  justify-content: space-between;
}

.e-checkout-page .e-checkout-step .e-checkout-step-list-group .list-group-item .e-checkout-list-icon {
  margin-left: auto;
  display: inline-block;
}

.e-checkout-page .e-checkout-step .e-checkout-step-list-group .list-group-item .e-checkout-list-icon img {
  position: relative;
  max-width: 100%;
  height: 100%;
  min-height: 25px;
  max-height: 100%;
}

.e-checkout-page .e-checkout-step .list-group:not(.list-group-with-form) .list-group-item {
  cursor: pointer;
  transition: background .25s ease;
}

.e-checkout-page .e-checkout-step .list-group:not(.list-group-with-form) .list-group-item:first-child {
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.e-checkout-page .e-checkout-step .list-group:not(.list-group-with-form) .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}

.e-checkout-page .e-checkout-step .list-group:not(.list-group-with-form) .list-group-item:hover {
  background: #f5f5f5;
}

.e-checkout-page .e-checkout-step .list-group:not(.list-group-with-form) .form-check {
  min-height: 1em;
  width: 100%;
  margin: 0;
}

.e-checkout-page .e-checkout-step .list-group:not(.list-group-with-form) .form-check .form-check-label {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.e-checkout-page .e-checkout-step .list-group:not(.list-group-with-form) .form-check-input {
  margin-top: 0;
}

.e-checkout-page .e-checkout-step .e-checkout-step-summary {
  font-size: 0.75em;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: hidden;
  color: #bdbdbd;
}

@media (max-width: 991px) {
  .e-checkout-page .e-checkout-step {
    box-shadow: none;
    display: none;
    padding: 0;
    border: 0;
  }
  .e-checkout-page .e-checkout-step.e-checkout-step-active {
    display: block;
  }
  .e-checkout-page .e-checkout-step.e-checkout-step-active .e-checkout-step-header {
    background: transparent;
    border: 0;
    padding: 0;
  }
}

.e-checkout-page .e-checkout-summery-icon img {
  height: 25px;
}

.e-checkout-page .e-checkout-progress ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.e-checkout-page .e-checkout-progress ul li {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 0.875em;
  color: #bdbdbd;
}

.e-checkout-page .e-checkout-progress ul li::before {
  display: flex;
  justify-content: center;
  content: attr(data-index);
  background: #f5f5f5;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: .2rem 0;
  text-align: center;
  border: 2px solid #f5f5f5;
}

.e-checkout-page .e-checkout-progress ul li.e-checkout-progress-completed {
  color: #4caf50;
  cursor: pointer;
}

.e-checkout-page .e-checkout-progress ul li.e-checkout-progress-completed::before {
  content: "done";
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  vertical-align: -10%;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  font-smoothing: antialiased;
  transition: transform 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  text-decoration: none;
  background: #4caf50;
  color: #fff;
  border-color: #4caf50;
}

.e-checkout-page .e-checkout-progress ul li.e-checkout-progress-active {
  color: #212121;
}

.e-checkout-page .e-checkout-progress ul li.e-checkout-progress-active::before {
  font-weight: 700;
  background: #f5f5f5;
  color: #212121;
  border-color: #4caf50;
}

.e-checkout-page .e-checkout-form .checkbox-toggle {
  float: right;
  padding: .5em;
  font-size: .7em;
  margin: .3em 0 -3em;
}

.e-checkout-page .e-checkout-form .checkbox-toggle input {
  margin-top: .35em;
}

.e-checkout-page .e-checkout-form .inline-form-button {
  align-self: flex-end;
}

.e-checkout-page .e-checkout-form .form-group.is-invalid label {
  color: inherit;
}

.e-checkout-page .e-checkout-form .form-check-input {
  margin-top: 0.35em;
}

.e-checkout-page .e-checkout-cart {
  background: #fff;
  position: sticky;
  top: 5em;
}

@media (max-width: 991px) {
  .e-checkout-page .collapsing {
    transition: none;
  }
}

.e-checkout-page .e-checkout-actions {
  display: flex;
  justify-content: space-between;
  margin: 0.8em 0;
}

.e-checkout-page .e-checkout-actions.e-checkout-actions-align-center {
  align-items: center;
}

.e-checkout-page .e-checkout-actions .e-checkout-next-step {
  margin-left: auto;
}

.e-checkout-page.e-checkout-page-approve .e-checkout .e-checkout-step .e-checkout-step-summary {
  color: #212121;
}

.e-checkout-page.e-checkout-page-approve .e-checkout-step .e-checkout-step-header {
  color: inherit;
}

.e-checkout-page.e-checkout-page-approve .e-checkout-step.e-checkout-step-completed .e-checkout-step-header-badges-completed {
  display: none;
}

.e-products .e-products-item {
  font-size: 0.875em;
  position: relative;
  padding: 2.4em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .e-products .e-products-item:hover {
    border: 0;
  }
  .e-products .e-products-item::before {
    content: "";
    display: none;
    position: absolute;
    top: -0.8em;
    bottom: -0.8em;
    left: -0.8em;
    right: -0.8em;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  .e-products .e-products-item:hover {
    z-index: 2;
  }
  .e-products .e-products-item:hover::before {
    display: block;
  }
  .e-products .e-products-item:hover .is-hidden-on-hover {
    display: none;
  }
  .e-products .e-products-item:hover .is-visible-on-hover {
    display: block;
  }
}

.e-products .e-products-item .is-hidden-on-hover {
  display: block;
}

.e-products .e-products-item .is-visible-on-hover {
  display: none;
}

.e-products .e-products-item-container {
  position: relative;
  z-index: 2;
}

.e-products .e-products-item-image-container {
  position: relative;
  margin-bottom: 1.6em;
  padding-top: 75%;
}

.e-products .e-products-item-image-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.e-products .e-products-item-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.e-products .e-products-item-text-container a {
  color: inherit;
  text-decoration: none;
}

.e-products .e-products-item-name {
  color: rgba(0, 0, 0, 0.85);
}

.e-productslist-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 0.5em;
}

.e-products-paging {
  align-self: flex-end;
  text-align: right;
}

.e-products-loading-overlay {
  background: rgba(255, 255, 255, 0.95);
  z-index: 1028;
}

.e-products-loading-overlay .e-loading-spinner {
  top: 20vh;
}

.e-productlist-filters-range-group .e-productlist-filters-clear-range {
  color: #f44336;
}

.e-productlist-filters-range-group.is-empty .e-productlist-filters-apply-range {
  opacity: 0.65;
}

.e-productlist-filters-range-group.is-empty .e-productlist-filters-clear-range {
  pointer-events: none;
  opacity: 0;
}

.e-productlist-filters-group:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.e-productlist-filters-group-header {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 700;
  display: flex;
  align-items: center;
}

.e-productlist-filters-group-header .e-productlist-filters-group-collapse-toggle {
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.e-productlist-filters-group-header .e-productlist-filters-group-collapse-toggle, .e-productlist-filters-group-header .e-productlist-filters-group-collapse-toggle:hover {
  text-decoration: none;
}

.e-productlist-filters-group-header .e-productlist-filters-group-collapse-toggle .material-icons {
  font-size: 1.5em;
  margin-left: -0.1875em;
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.e-productlist-filters-group-header .e-productlist-filters-group-collapse-toggle.collapsed .material-icons {
  transform: rotate(-90deg);
}

.e-productlist-filters-group-header .e-productlist-filters-group-name {
  flex-grow: 1;
}

.e-productlist-filters-group-options .e-nav-local {
  position: relative;
}

.e-productlist-filters-group-options .e-nav-local .e-nav-local-container {
  font-size: 1em;
}

.e-productlist-filters-group-options .e-nav-local .e-nav-local-container .nav > .nav-item {
  border: 0;
}

.e-productlist-filters-group-options .e-nav-local .e-nav-local-container .nav > .nav-item > .nav-link {
  color: #2196f3;
  font-weight: inherit;
  padding: 0.6em 0;
}

.e-productlist-filters-options-collapse-peek {
  overflow: hidden;
  position: relative;
  max-height: 100vh;
  padding-bottom: 2.4em;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.e-productlist-filters-options-collapse-peek .e-productlist-filters-options-collapse-peek-toggle {
  text-decoration: none;
  position: absolute;
  bottom: 0;
  left: -0.25em;
  right: 0;
  background: #fff;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.e-productlist-filters-options-collapse-peek .e-productlist-filters-options-collapse-peek-toggle::after {
  content: attr(data-open-text);
}

.e-productlist-filters-options-collapse-peek .e-productlist-filters-options-collapse-peek-toggle .material-icons {
  font-size: 1.5em;
  vertical-align: -26%;
  transform: rotate(180deg);
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.e-productlist-filters-options-collapse-peek.is-peeking {
  max-height: 33vh;
}

.e-productlist-filters-options-collapse-peek.is-peeking .e-productlist-filters-options-collapse-peek-toggle::before {
  content: "";
  height: 3.2em;
  position: absolute;
  top: -3.2em;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, #fff 0%, transparent 95%);
}

.e-productlist-filters-options-collapse-peek.is-peeking .e-productlist-filters-options-collapse-peek-toggle::after {
  content: attr(data-closed-text);
}

.e-productlist-filters-options-collapse-peek.is-peeking .e-productlist-filters-options-collapse-peek-toggle .material-icons {
  transform: rotate(0);
}

.e-productlist-filters-option.is-disabled label {
  cursor: not-allowed;
}

.e-productlist-filters-modal .modal-footer {
  position: sticky;
  bottom: -1px;
  background: #757575;
  display: flex;
  justify-content: space-between;
}

.e-productlist-filters-modal .modal-footer .btn + .btn {
  margin-left: auto;
}

.e-productlist-filters-modal .e-productlist-sort-label {
  display: block;
}

.e-productlist-filters-modal input[type="radio"],
.e-productlist-filters-modal input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.e-productlist-filters-modal input[type="radio"] + label,
.e-productlist-filters-modal input[type="checkbox"] + label {
  padding: 1.125em 3.75em;
  font-size: 0.875em;
  line-height: 1.25;
  border-radius: 0;
  color: #111;
  background: #eaf1d9 linear-gradient(180deg, #edf3df, #eaf1d9) repeat-x;
  border-color: #eaf1d9;
  box-shadow: 0;
  cursor: pointer;
  display: block;
}

.e-productlist-filters-modal input[type="radio"] + label:hover,
.e-productlist-filters-modal input[type="checkbox"] + label:hover {
  color: #111;
  background: #dbe7bd linear-gradient(180deg, #e0eac7, #dbe7bd) repeat-x;
  border-color: #d6e3b4;
}

.e-productlist-filters-modal input[type="radio"] + label:focus, .e-productlist-filters-modal input[type="radio"] + label.focus,
.e-productlist-filters-modal input[type="checkbox"] + label:focus,
.e-productlist-filters-modal input[type="checkbox"] + label.focus {
  box-shadow: 0, 0 0 0 0.125em rgba(234, 241, 217, 0.5);
}

.e-productlist-filters-modal input[type="radio"] + label.disabled, .e-productlist-filters-modal input[type="radio"] + label:disabled,
.e-productlist-filters-modal input[type="checkbox"] + label.disabled,
.e-productlist-filters-modal input[type="checkbox"] + label:disabled {
  background-color: #eaf1d9;
  border-color: #eaf1d9;
}

.e-productlist-filters-modal input[type="radio"] + label:not([disabled]):not(.disabled):active, .e-productlist-filters-modal input[type="radio"] + label:not([disabled]):not(.disabled).active,
.show > .e-productlist-filters-modal input[type="radio"] + label.dropdown-toggle,
.e-productlist-filters-modal input[type="checkbox"] + label:not([disabled]):not(.disabled):active,
.e-productlist-filters-modal input[type="checkbox"] + label:not([disabled]):not(.disabled).active,
.show >
.e-productlist-filters-modal input[type="checkbox"] + label.dropdown-toggle {
  color: #111;
  background-color: #d6e3b4;
  background-image: none;
  border-color: #d1e0aa;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.125em rgba(234, 241, 217, 0.5);
}

.e-productlist-filters-modal input[type="radio"]:checked + label,
.e-productlist-filters-modal input[type="checkbox"]:checked + label {
  background: #99BA44;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.e-productlist-filters-modal input[type="radio"]:disabled + label,
.e-productlist-filters-modal input[type="checkbox"]:disabled + label {
  pointer-events: none;
  opacity: 0.65;
  background: #f5f5f5;
}

.e-product .e-product-container {
  font-size: 0.75em;
  position: relative;
  z-index: 2;
  padding: 1.6em;
}

.e-product .e-product-text-container {
  font-size: 0.875em;
  margin-bottom: 1.6em;
}

.e-product .e-product-price {
  font-size: 1.25em;
}

.e-product .e-product-price-before {
  text-decoration: line-through;
}

.e-product .e-product-form {
  position: relative;
}

.e-product .e-product-form .e-loading-overlay {
  background: transparent;
}

.e-product .e-product-form .e-loading-spinner {
  left: 10%;
  background: currentColor;
  background: linear-gradient(60deg, currentColor 20%, rgba(255, 255, 255, 0) 60%);
}

.e-product .e-product-form .e-loading-spinner::before {
  background: currentColor;
}

.e-product .e-product-form .e-loading-spinner::after {
  background: #99BA44;
}

.e-product .e-product-image-container {
  position: relative;
  padding-top: 80%;
  margin-bottom: 1.6em;
}

.e-product .e-product-image-container .carousel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  height: auto;
}

@media (max-width: 767px) {
  .e-product .e-product-image-container .carousel {
    margin-bottom: 1.6em;
  }
}

.e-product .e-product-image-container .carousel-item .e-product-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: zoom-in;
}

@media (max-width: 767px) {
  .e-product .e-product-image-container .carousel-indicators {
    bottom: -1.6em;
    height: 1.6em;
    margin: 0;
    justify-content: flex-start;
  }
  .e-product .e-product-image-container .carousel-indicators li {
    font-size: 0.875em;
    flex: 0 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: rgba(0, 0, 0, 0.65);
    opacity: 0.65;
  }
  .e-product .e-product-image-container .carousel-indicators li.active {
    border-color: #2196f3;
    background: transparent;
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .e-product .e-product-image-container .carousel-indicators {
    font-size: 3em;
    position: static;
    margin: 0;
    flex-flow: column;
    justify-content: center;
    order: -1;
  }
  .e-product .e-product-image-container .carousel-indicators li {
    flex: 0 0 auto;
    position: relative;
    margin: 0 0.2em 0.2em 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 1px;
    background-color: transparent;
  }
  .e-product .e-product-image-container .carousel-indicators li.active {
    border-color: #2196f3;
  }
  .e-product .e-product-image-container .carousel-indicators li:last-child {
    margin-bottom: 0;
  }
  .e-product .e-product-image-container .carousel-indicators li span {
    position: absolute;
    top: 0.0625em;
    right: 0.0625em;
    bottom: 0.0625em;
    left: 0.0625em;
    background-color: transparent;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}

.e-product .e-product-image-container .e-product-image-modal-link {
  position: absolute;
  right: 0;
  bottom: -1.6em;
  z-index: 16;
}

@media (min-width: 768px) {
  .e-product .e-product-image-container .e-product-image-modal-link {
    display: none;
  }
}

.e-product .e-product-image-container .e-product-image-modal-link .material-icons {
  font-size: 1.375em;
  vertical-align: -26%;
  padding-right: 0.25em;
  color: rgba(0, 0, 0, 0.65);
}

.e-product .e-product-image-modal.modal-fullscreen {
  overflow-y: hidden;
}

.e-product .e-product-image-modal.modal-fullscreen .modal-content {
  min-height: 0;
}

.e-product .e-product-image-modal .modal-dialog,
.e-product .e-product-image-modal .modal-content,
.e-product .e-product-image-modal .modal-body,
.e-product .e-product-image-modal .carousel {
  height: 100%;
}

.e-product .e-product-image-modal .modal-body {
  padding: 0;
}

.e-product .e-product-image-modal .carousel-item {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.e-product .e-product-image-modal .carousel-control.left {
  left: 0.5em;
}

.e-product .e-product-image-modal .carousel-control.right {
  right: 0.5em;
}

.e-product .e-product-image-modal .carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0.65;
}

@media (min-width: 768px) {
  .e-product .e-product-image-modal .carousel-indicators li {
    background: #fff;
    opacity: 1;
    font-size: 3em;
    margin: 0.2em;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.e-product .e-product-image-modal .carousel-indicators li.active {
  border-color: #2196f3;
  background: #fff;
  opacity: 1;
}

.e-product .e-product-image-modal .carousel-indicators li span {
  display: none;
  position: absolute;
  top: 0.0625em;
  right: 0.0625em;
  bottom: 0.0625em;
  left: 0.0625em;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .e-product .e-product-image-modal .carousel-indicators li span {
    display: block;
  }
}

.e-product .e-product-section {
  padding: 1.6em 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.e-product .e-product-section-header {
  padding-bottom: 1.6em;
}

.e-product .e-product-section-header[data-toggle="collapse"] {
  cursor: pointer;
}

.btn {
  text-transform: uppercase;
  letter-spacing: 0;
}

.btn-primary {
  color: #fff;
}

.btn-secondary {
  background: transparent;
  border: 1px solid #99BA44;
  color: #99BA44;
}

.btn-secondary:hover {
  color: #5c6f29;
  border: 1px solid #99BA44;
  background: #f4f8ec;
}

.e-nav-cta .nav-item:last-child .btn-primary {
  background: transparent;
  border: 1px solid #99BA44;
  color: #99BA44;
}

.e-nav-cta .nav-item:last-child .btn-primary:hover {
  background: #f4f8ec;
}

.lead {
  font-family: inherit;
  font-size: 1.075em;
  font-weight: 700;
  font-style: italic;
}

.e-nav-global > .nav > .nav-item > .nav-link:before {
  display: none;
}

.e-nav-global > .nav > .nav-item > .nav-link:hover:before {
  display: none;
}

@media (min-width: 768px) {
  .p-section-bg-light.p-icontext:first-child {
    margin-left: -1em;
    margin-right: 1em;
  }
  .p-section-bg-light.p-icontext:nth-child(3) {
    margin-left: 1em;
    margin-right: -1em;
  }
}

.p-section-bg-brand-primary .btn-primary {
  background: #fff;
  color: #99BA44;
}

.p-section-bg-brand-primary .btn-primary:hover {
  background: #eaf1d9;
}

.p-section-bg-brand-primary .btn-primary:active {
  background: #fffffe;
}

.es-raise-section {
  margin-top: -6em;
}
