.p-carousel {
    height: auto;
    margin-bottom: $spacer;
    @include media-breakpoint-down(md) {
        .carousel-inner {
            box-sizing: content-box;
            padding-bottom: $carousel-caption-height;
            background: $carousel-caption-bg;
        }
        .p-carousel-item {
            &::before {
                background: none;
            }
        }
        .carousel-caption {
            box-sizing: border-box;
            height: $carousel-caption-height;
            bottom: auto;
            top: 100%;
        }
        .carousel-indicators {
            bottom: $carousel-caption-height - $spacer;
        }
        .carousel-control-prev,
        .carousel-control-next {
            padding-bottom: $carousel-caption-height / 2;
        }
    }
    &.p-carousel-fullscreen {
        .carousel-inner {
            box-sizing: inherit;
            padding-bottom: 0;
            background: none;
        }
        .p-carousel-item {
            &::before {
                background: linear-gradient(11deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 45%);
            }
        }
        .carousel-caption {
            height: auto;
            bottom: 0;
            top: auto;
        }
        .carousel-control-prev,
        .carousel-control-next {
            margin-top: 0;
        }
        .carousel-indicators {
            bottom: $spacer / 2;
        }
    }
}

.p-carousel-item {
    background-size: cover;
    background-position: 50% 50%;
    &:not(.p-carousel-item-gradient-disabled)::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(22deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 60%);
        @include media-breakpoint-down(md) {
            background: none;
        }
    }
    .p-carousel-small & {
        @extend .p-section-small;
    }
    .p-carousel-medium & {
        @extend .p-section-medium;
    }
    .p-carousel-large & {
        @extend .p-section-large;
    }
    .p-carousel-fullscreen & {
        @extend .p-section-fullscreen;
        .carousel-caption {
            bottom: $spacer;
            margin-bottom: 1vmin;
        }
    }
}

.p-carousel-item-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0);
    @include media-breakpoint-down(md) {
        bottom: $carousel-caption-height * -1;
    }
}

.p-carousel-item-img-left-top {
    background-position: 0 0;
}

.p-carousel-item-img-left-middle {
    background-position: 0 50%;
}

.p-carousel-item-img-left-bottom {
    background-position: 0 100%;
}

.p-carousel-item-img-center-top {
    background-position: 50% 0;
}

.p-carousel-item-img-center-middle {
    background-position: 50% 50%;
}

.p-carousel-item-img-center-bottom {
    background-position: 50% 100%;
}

.p-carousel-item-img-right-top {
    background-position: 100% 0;
}

.p-carousel-item-img-right-middle {
    background-position: 100% 50%;
}

.p-carousel-item-img-right-bottom {
    background-position: 100% 100%;
}
