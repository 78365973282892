@include media-breakpoint-down($nav-hamburger-breakpoint) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-cta {
        // font-family: $font-family-monospace;
        font-size: 1.125em;
        margin-bottom: 3em;
        .nav {}
        .nav-item {
            text-align: center;
            margin: 0 auto 1em;
            opacity: 0;
            transition: all $transition-fade-speed $transition-fade-easing;
            $item-entry-delay: 0.1s;
            @for $item from 1 through 10 {
                &:nth-child(#{$item}) {
                    transition-delay: $item-entry-delay * $item;
                }
            }
        }
    }
    //Props for item animation entry in active modal.
    .e-nav-cta {
        .show & {
            // First level only.
            > .nav {
                > .nav-item {
                    opacity: 1;
                }
            }
        }
    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-cta {
        margin-left: 1.25em;
        display: flex;
        align-items: center;
        .nav {
            display: flex;
        }
        .nav-item + .nav-item {
            margin-left: 1em;
        }
        .nav-link {}
    }
}
