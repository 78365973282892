.e-nav-local {
    @include media-breakpoint-down($nav-hamburger-breakpoint) {
        display: none !important;
    }
    .e-nav-local-container {
        font-size: $font-size-sm;
    }
    .nav-link:not(.btn) {
        color: inherit;
        .e-nav-pageicon {}
        .e-nav-pagename {}
        .e-nav-pageicon-dropdown {}
    }
}

.e-nav-local-horizontal {
    position: relative;
    z-index: 1;
    // padding-top: 0.25em;
    border-bottom: $border-width solid $border-color;
    &:first-child {
        // Prevent collapsing margins with parent container.
        display: inline-block;
        width: 100%;
    }
    &.is-sticky {
        position: sticky;
        top: -1px;
        background: $body-bg;
        &.is-stuck {
            border: 0;
            box-shadow: $shadow-md;
        }
    }
    &.is-absolute {
        color: rgba(255, 255, 255, 1);
        position: absolute;
        top: auto;
        width: 100%;
        background: none;
        border-bottom: $border-width solid rgba(255, 255, 255, $text-muted);
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: -50%;
            left: 0;
            opacity: 1;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0, rgba(0, 0, 0, 0) 100%);
            transition: opacity, bottom $transition-fade-speed $transition-fade-easing $transition-fade-speed;
        }
        &.is-sticky {
            background: none;
            &::after {
                content: "";
                background: rgba(0, 0, 0, 0.85);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0;
                transform: scaleX(0);
                transition: opacity, transform $transition-fade-speed $transition-slide-easing;
                will-change: opacity, transform;
            }
            &.is-stuck {
                // color: rgba(255, 255, 255, .85);
                border: 0;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                &::before {
                    bottom: 0;
                    opacity: 0;
                }
                &::after {
                    opacity: 1;
                    transform: scaleX(1);
                }
            }
        }
    }
    .e-nav-local-container {
        position: relative;
        z-index: 1;
    }
    .e-nav-local-heading {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 auto 0 0;
        h2 {
            color: inherit;
            font-size: 1.375em;
            font-weight: $font-weight-bold;
            margin: 0;
        }
    }
    // All levels
    .nav {}
    .nav-item {}
    .nav-link {
        &.is-active,
        &.is-inpath {
            font-weight: $font-weight-bold;
        }
    }
    // Level #1
    .e-nav-local-container > .nav {
        > .nav-item {
            display: flex;
            align-items: center;
            &:first-child {
                margin-left: ($spacer * -1);
            }
            &:last-child:not(.is-cta) {
                margin-right: ($spacer * -1);
            }
            > .nav-link {
                display: flex;
                align-items: center;
                line-height: $line-height-sm * 0.875;
                padding: ($spacer * .875) ($spacer * 1) ($spacer * .75);
                &.btn {
                    padding: ($spacer * .5) ($spacer * 1);
                }
            }
        }
    }
    .dropdown-toggle {
        //Dropdown arrow
        &::after {
            display: none;
        }
    }
    .dropdown-menu {
        font-size: 1em;
        .nav-link {
            padding: ($spacer * 0.5) ($spacer * 1.125);
        }
    }
}

.e-nav-local-vertical {
    position: absolute;
    top: auto;
    width: 100%;
    z-index: 1;
    pointer-events: none; // Prevent nav from overlaying content.
    &.is-sticky.is-stuck {
        position: fixed;
        top: $spacer;
    }
    &.is-sticky.is-stuck,
    &.is-absolute {
        .e-nav-local-container {
            margin: 0 ($spacer * -1);
            padding: ($spacer * 1.5) ($spacer * 1);
            background: rgba(255, 255, 255, .85);
        }
    }
    .e-nav-local-container {
        pointer-events: auto;
    }
    .e-nav-local-heading {
        padding: 0;
        margin: 0;
        h2 {
            // color: inherit;
            // font-size: $font-size-lg;
        }
    }
    // All levels
    .nav {}
    .nav-item {}
    .nav-link {
        color: inherit;
        padding: ($spacer * 0.3125) 0;
        line-height: $line-height-sm;
        &:hover {
            color: theme-color("primary");
        }
        &.is-active {
            font-weight: $font-weight-bold;
            color: theme-color("primary");
        }
    }
    // All sub levels
    .nav {
        .nav {
            margin: ($spacer * 0.25) 0 ($spacer * 1) ($spacer * 1);
            .nav-item {}
            .nav-link {
                transition: transform $transition-slide-speed $transition-slide-easing;
                &:hover:not(.is-active) {
                    transition: transform $transition-slide-speed / 2 $transition-slide-easing;
                    transform: translateX($spacer * 0.5);
                }
                &.is-active {
                    position: relative;
                    padding-left: ($spacer * 0.5);
                    &::before {
                        content: "";
                        position: absolute;
                        top: ($spacer * 0.125);
                        left: 0;
                        bottom: ($spacer * 0.125);
                        width: ($spacer * 0.1875);
                        background: theme-color("primary");
                    }
                }
            }
        }
    }
    // Level #1
    .e-nav-local-container > .nav {
        font-size: $font-size-sm;
        > .nav-item {
            border-top: $border-width solid $border-color;
            &:last-child {
                border-bottom: $border-width solid $border-color;
            }
            > .nav-link {
                font-weight: $font-weight-bold;
                padding: ($spacer * 0.675) 0;
            }
        }
    }
    // Level #2
    .e-nav-local-container > .nav {
        > .nav-item {
            > .nav {
                margin-top: 0;
            }
        }
    }
}
