.btn {
    text-transform: uppercase;
    letter-spacing: 0;
}

.btn-primary {
    color: $white;
}

.btn-secondary {
    background: transparent;
    border: 1px solid theme-color(primary);
    color: theme-color(primary);
    &:hover {
    	color: darken(theme-color(primary),20%);
    	border: 1px solid theme-color(primary);
        background: lighten(theme-color(primary), 45%);
    }
}

.e-nav-cta {
    .nav-item:last-child {
        .btn-primary {
            background: transparent;
            border: 1px solid theme-color(primary);
            color: theme-color(primary);
            &:hover {
                background: lighten(theme-color(primary), 45%);
            }
        }
    }
}