.e-cart {
    position: relative;
    .e-loading-overlay {
        position: fixed;
        max-height: 100vh;
    }
}

.e-cart-sidebar {
    @include media-breakpoint-up(md) {
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        margin-top: $border-width * -1;
        background: $body-bg;
        position: sticky;
        top: $spacer;
        z-index: 1;
    }
}

.e-cart-delivery-info {

}

.e-cart-footer {
    background: $body-bg;
    position: sticky;
    bottom: 0;
}

//TODO: Spilt into seperate file.
.e-cart-confirm-msg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed - 1;
    display: none;
    overflow: hidden;
    opacity: 0;
    background: $body-bg;
    box-shadow: $shadow-lg;
    transition: opacity $transition-fade-speed $transition-fade-easing;
    &.is-active {
        opacity: 1;
        display: block;
    }
}

.e-cart-confirm-msg-info {
    @include e-txt-dark;
    @include e-bg-light;
    height: 100%;
}


//TODO: Spilt into seperate file and use new BS badge class.
.e-cart-mini {
    a {
        position: relative;
    }
    i {
        position: relative;
        z-index: 1;
    }
}

.e-cart-quantity {
    color: $gray-900;
    font-weight: $font-weight-bold;
    background: theme-color("primary");
    height: 1.75em;
    width: 1.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 50%;
    transform: translate(110%, -110%);
    transition: opacity $transition-fade-speed $transition-fade-easing;
    &:empty {
        opacity: 0;
    }
}
