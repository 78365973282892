// @import "utilities/align";
// @import "utilities/background";
// @import "utilities/borders";
// @import "utilities/clearfix";
// @import "utilities/display";
// @import "utilities/embed";
.embed-responsive-21by9 {
    &::before {
        padding-top: percentage(5 / 16);
    }
}

// @import "utilities/flex";
// @import "utilities/float";
// @import "utilities/position";
// @import "utilities/screenreaders";
// @import "utilities/sizing";
// @import "utilities/spacing";
// @import "utilities/visibility";
// @import "utilities/text";
// Weight and italics
// Contextual colors
.text-black {
    color: $black !important;
}

.text-light {
    color: $section-dark-color !important;
}

.text-dark {
    color: $section-light-color !important;
}

.text-link {
    color: $link-color !important;
}

.text-auto {
    color: inherit !important;
}

.text-muted {
    opacity: $text-muted;
}

.text-monospace {
    font-family: $font-family-monospace;
}

.text-decoration-none {
    &,
    &:hover {
        text-decoration: none;
    }
}

.line-height-sm {
    line-height: $line-height-sm;
}
