.e-content-main {
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 3;
    @for $size from 1 through 5 {
        &.p-t-#{$size} {
            padding-top: ( $spacer * $size * 2) !important;
        }
        &.p-b-#{$size} {
            padding-bottom: ( $spacer * $size * 2) !important;
        }
    }
}
