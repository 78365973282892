@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes zoom {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: $transform-scale-zoom;
    }
}

@keyframes bounce {
  0% { transform:translateY(0%); }
  5% { transform:translateY(20%); }
  10% { transform:translateY(0%); }
  15% { transform:translateY(20%); }
  20% { transform:translateY(0%); }
}
