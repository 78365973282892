/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function e-strip-unit($number) {
    @if type-of($number)=='number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}

// Shadows
//
// Umbra, Penumbra and ambient shadows from https://material.io/guidelines/resources/shadows.html
@function e-shadow($size: "md", $color: $black) {
    @if $size == "xs" {
        @return 0 2px 1px -1px rgba($color, .14), 0 1px 1px 0 rgba($color, .2), 0 1px 3px 0 rgba($color, .12);//.mdl-shadow--1dp
    }
    @else if $size == "sm" {
        @return 0 2px 2px 0 rgba($color, .14), 0 3px 1px -2px rgba($color, .2), 0 1px 5px 0 rgba($color, .12);//.mdl-shadow--2dp
    }
    @else if $size == "md" {
        @return 0 4px 5px 0 rgba($color, .14), 0 1px 10px 0 rgba($color, .12), 0 2px 4px -1px rgba($color, .2);//.mdl-shadow--4dp
    }
    @else if $size == "lg" {
        @return 0 8px 10px 1px rgba($color, .14), 0 3px 14px 2px rgba($color, .12), 0 5px 5px -3px rgba($color, .2);//.mdl-shadow--8dp
    }
    @else if $size == "xl" {
        @return 0 7px 8px -4px rgba($color, .14), 0 12px 17px 2px rgba($color, .12), 0 5px 22px 4px rgba($color, .2);//.mdl-shadow--12dp
    }
}
