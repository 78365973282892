// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff
// Kill the scroll on the body
.modal-open {
    //http://stackoverflow.com/questions/32675849/screen-zooms-in-when-a-bootstrap-modal-is-opened-on-ios-9-safari
    // min-height: 100vh;
}

// Modal sizes
.modal-fullscreen {
    .modal-open & {
        overflow-y: scroll;
        padding-right: 0 !important;
    }
    .close {
        position: sticky;
        margin: $modal-inner-padding * -1;
    }
    .modal-dialog {
        max-width: 100%;
        margin: 0;
        transition: none;
    }
    .modal-content {
        min-height: 100vh;
        border: 0;
        border-radius: 0;
        box-shadow: none;
    }
}

// Scale up the modal
@include media-breakpoint-up(sm) {
    // Automatically set modal's width for larger viewports
    .modal-dialog {
        max-width: $modal-md;
        margin: $modal-dialog-margin-y-sm-up auto;
    }
    .modal-content {
        @include box-shadow($modal-content-box-shadow-sm-up);
    }
    .modal-sm {
        max-width: $modal-sm;
    }
}

@include media-breakpoint-up(lg) {
    .modal-lg {
        max-width: $modal-lg;
    }
}
