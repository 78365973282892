@mixin e-canvas-padding {
    padding-right: $canvas-padding-sm;
    padding-left: $canvas-padding-sm;
    @include media-breakpoint-up(xl) {
        padding-right: $canvas-padding-md;
        padding-left: $canvas-padding-md;
    }
    @media (min-width: $canvas-max-width) {
        padding-right: $canvas-padding-lg;
        padding-left: $canvas-padding-lg;
    }
}

@mixin material-icons($icon: "") {
    content: $icon;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1em;
    vertical-align: -10%;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    font-smoothing: antialiased;
}

//TODO: Lav e-section mixin
@mixin e-txt-dark {
    color: $section-light-color;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-light-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $section-light-color;
    }
    p {
        a:not(.btn) {
            color: $section-light-link-color;
            //text-decoration: underline;
            &:hover {
                //text-decoration: none;
            }
        }
        a[href^="tel:"] {
            @include media-breakpoint-up(md) {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}

@mixin e-txt-light {
    color: $section-dark-color;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-dark-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $section-dark-color;
    }
    p {
        a:not(.btn) {
            color: $section-dark-link-color;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        a[href^="tel:"] {
            @include media-breakpoint-up(md) {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}

@mixin e-bg-light {
    border-color: $section-light-border;
    background: $section-light-bg;
}

@mixin e-bg-dark {
    border-color: $section-dark-border;
    background: $section-dark-bg;
}

@mixin e-bg-light {
    border-color: $section-light-border;
    background: $section-light-bg;
}

@mixin e-bg-dark {
    border-color: $section-dark-border;
    background: $section-dark-bg;
}

@mixin e-section-primary {
    color: $section-primary-color;
    border-color: $section-primary-border;
    background: $section-primary-bg;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-primary-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $section-primary-color;
    }
    p {
        a:not(.btn) {
            color: $section-primary-link-color;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        a[href^="tel:"] {
            @include media-breakpoint-up(md) {
                color: inherit;
                text-decoration: none;
            }
        }
    }
    .p .p-txt-container .table tbody {
        th,
        td {
            border-color: $section-primary-border;
        }
    }
    hr {
        border-color: $section-primary-border;
    }
}

@mixin e-section-secondary {
    color: $section-secondary-color;
    border-color: $section-secondary-border;
    background: $section-secondary-bg;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-secondary-headings-color;
    }
    h3,
    .h3 h4,
    .h4 {
        color: $section-secondary-color;
    }
    p {
        a:not(.btn) {
            color: $section-secondary-link-color;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        a[href^="tel:"] {
            @include media-breakpoint-up(md) {
                color: inherit;
                text-decoration: none;
            }
        }
    }
    .p .p-txt-container .table tbody {
        th,
        td {
            border-color: $section-secondary-border;
        }
    }
    hr {
        border-color: $section-secondary-border;
    }
}

@mixin e-section-variation-one {
    color: $section-variation-one-color;
    border-color: $section-variation-one-border;
    background: $section-variation-one-bg;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-variation-one-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $section-variation-one-color;
    }
    p {
        a:not(.btn) {
            color: $section-variation-one-link-color;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        a[href^="tel:"] {
            @include media-breakpoint-up(md) {
                color: inherit;
                text-decoration: none;
            }
        }
    }
    .p .p-txt-container .table tbody {
        th,
        td {
            border-color: $section-variation-one-border;
        }
    }
    hr {
        border-color: $section-variation-one-border;
    }
}

@mixin e-section-variation-two {
    color: $section-variation-two-color;
    border-color: $section-variation-two-border;
    background: $section-variation-two-bg;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-variation-two-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $section-variation-two-color;
    }
    p {
        a:not(.btn) {
            color: $section-variation-two-link-color;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        a[href^="tel:"] {
            @include media-breakpoint-up(md) {
                color: inherit;
                text-decoration: none;
            }
        }
    }
    .p .p-txt-container .table tbody {
        th,
        td {
            border-color: $section-variation-two-border;
        }
    }
    hr {
        border-color: $section-variation-two-border;
    }
}

// Toggles
//
// Used in conjunction with global variables to enable certain theme features.
// Utilities
// @import "mixins/breakpoints";
// @import "mixins/hover";
// @import "mixins/image";
// @import "mixins/badge";
// @import "mixins/resize";
// @import "mixins/screen-reader";
// @import "mixins/size";
// @import "mixins/reset-text";
// @import "mixins/text-emphasis";
// @import "mixins/text-hide";
// @import "mixins/text-truncate";
// @import "mixins/visibility";
// // Components
// @import "mixins/alert";
// @import "mixins/buttons";
// @import "mixins/pagination";
@import "mixins/lists";
// @import "mixins/list-group";
// @import "mixins/nav-divider";
@import "mixins/forms";
// @import "mixins/table-row";
// // Skins
// @import "mixins/background-variant";
// @import "mixins/border-radius";
// @import "mixins/box-shadow";
// @import "mixins/gradients";
// @import "mixins/transition";
// // Layout
// @import "mixins/clearfix";
// // @import "mixins/navbar-align";
@import "mixins/grid-framework";
// @import "mixins/grid";
// @import "mixins/float";
