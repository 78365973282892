.p-mosaic {
    .p-img-container {
        height: $section-height-large;
        @include media-breakpoint-down(sm) {
            height: $section-height-large * 1.5;
        }
        max-height: 90vh;
        @media (min-width: $canvas-max-width + 1px) {
            height: ( $canvas-max-width / 100) * e-strip-unit( $section-height-large);
        }
    }
    .p-img-container {
        @include media-breakpoint-down(sm) {
            .p-img:not(.p-img-contain) {
                right: 10%;
            }
            &.order-md-last {
                .p-img:not(.p-img-contain) {
                    left: 10%;
                    right: 0;
                }
            }
        }
    }
    .p-img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }
    .p-img-left-top {
        background-position: 0 0;
    }
    .p-img-left-middle {
        background-position: 0 50%;
    }
    .p-img-left-bottom {
        background-position: 0 100%;
    }
    .p-img-center-top {
        background-position: 50% 0;
    }
    .p-img-center-middle {
        background-position: 50% 50%;
    }
    .p-img-center-bottom {
        background-position: 50% 100%;
    }
    .p-img-right-top {
        background-position: 100% 0;
    }
    .p-img-right-middle {
        background-position: 100% 50%;
    }
    .p-img-right-bottom {
        background-position: 100% 100%;
    }
    @for $size from 1 through 5 {
        .p-img-pos-t-#{$size} {
            top: 2.5 * $size * 1%;
        }
        .p-img-pos-r-#{$size} {
            right: 2.5 * $size * 1%;
        }
        .p-img-pos-b-#{$size} {
            bottom: 2.5 * $size * 1%;
        }
        .p-img-pos-l-#{$size} {
            left: 2.5 * $size * 1%;
        }
    }
    .p-txt-container {
        padding-top: $spacer * 1.5;
        padding-bottom: $spacer * 1.5;
        padding-right: 10%;
        padding-left: 10%;
        :last-child {
            margin-bottom: 0;
        }
    }
}

.p-mosaic-img-contain {
    .p-img {
        background-size: contain;
    }
    @include media-breakpoint-up(md) {
        &.p-mosaic-img-left {
            .p-txt-container {
                padding-left: 5%;
            }
        }
        &.p-mosaic-img-right {
            .p-txt-container {
                padding-right: 5%;
            }
        }
    }
}

.p-mosaic-bg-white {
    @include e-txt-dark;
    background-color: #fff;
}

.p-mosaic-bg-black {
    @include e-txt-light;
    background-color: #000;
}

.p-mosaic-bg-light {
    @include e-txt-dark;
    @include e-bg-light;
}

.p-mosaic-bg-dark {
    @include e-txt-light;
    @include e-bg-dark;
}

.p-mosaic-bg-brand-primary {
    @include e-section-primary;
}

.p-mosaic-bg-brand-secondary {
    @include e-section-secondary;
}

.p-mosaic-bg-brand-variation-one {
    @include e-section-variation-one;
}

.p-mosaic-bg-brand-variation-two {
    @include e-section-variation-two;
}

.p-mosaic-txt-dark {
    @include e-txt-dark;
}

.p-mosaic-txt-light {
    @include e-txt-light;
}
